.e-dialog .e-icon-dlg-close::before {
  content: '\e745';
  position: relative;
}

.e-dialog .e-icon-dlg-close {
  opacity: 0.5;
}

.e-dialog .e-icon-dlg-close:active {
  opacity: 0.75;
}

.e-dialog .e-icon-dlg-close:hover {
  opacity: 0.75;
}

.e-dialog .e-south-east::before,
.e-dialog .e-south-west::before,
.e-dialog .e-north-east::before,
.e-dialog .e-north-west::before {
  content: '\e7cf';
}

/*! dialog layout */
.e-dialog {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  -ms-flex-direction: column;
      flex-direction: column;
  width: 100%;
}

.e-dialog.e-popup {
  width: 100%;
}

.e-dialog.e-dlg-resizable {
  padding-bottom: 15px;
  -ms-touch-action: none;
      touch-action: none;
}

.e-dialog .e-dlg-header-content {
  border-radius: 6px 6px 0 0;
  line-height: 1.2;
}

.e-dialog .e-dlg-header-content + .e-dlg-content {
  padding-top: 14px;
}

.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 14px;
  width: 12px;
}

.e-dialog .e-dlg-header {
  display: block;
  font-size: 16px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 80%;
}

.e-dialog .e-dlg-header.e-hide {
  display: none;
}

.e-dialog .e-dlg-modal {
  position: fixed;
}

.e-dialog .e-scroll-disabled {
  overflow: hidden !important;
}

.e-dialog .e-dlg-content {
  display: block;
  -ms-flex: 1 1 auto;
      flex: 1 1 auto;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.5;
  overflow: auto;
  overflow-x: hidden;
}

.e-dialog .e-dlg-content.e-hide {
  display: none;
}

.e-dialog .e-footer-content {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: 1px solid #e9ecef;
  bottom: 0;
  display: block;
  right: 0;
  width: 100%;
}

.e-dialog .e-footer-content.e-hide {
  display: none;
}

.e-dialog .e-footer-content {
  text-align: right;
}

.e-dialog .e-resize-handle {
  height: 13px;
  position: absolute;
  width: 13px;
}

.e-dialog .e-resize-handle.e-south-east {
  bottom: 2px;
  cursor: nwse-resize;
  right: 2px;
}

.e-dialog .e-resize-handle.e-south-west {
  bottom: 2px;
  cursor: nesw-resize;
  left: 2px;
  transform: rotate(90deg);
}

.e-dialog .e-resize-handle.e-north-east {
  cursor: nesw-resize;
  right: 2px;
  top: 2px;
  transform: rotate(-90deg);
}

.e-dialog .e-resize-handle.e-north-west {
  cursor: nwse-resize;
  left: 2px;
  top: 2px;
  transform: rotate(180deg);
}

.e-dialog .e-south,
.e-dialog .e-north,
.e-dialog .e-east,
.e-dialog .e-west {
  background-color: transparent;
  background-repeat: repeat;
  overflow: visible;
  position: absolute;
}

.e-dialog .e-east,
.e-dialog .e-west {
  cursor: ew-resize;
}

.e-dialog .e-south,
.e-dialog .e-north {
  cursor: ns-resize;
}

.e-dialog.e-blazor-hidden {
  left: 0;
  position: absolute;
}

.e-bigger.e-dialog .e-dlg-header-content,
*.e-bigger .e-dialog .e-dlg-header-content {
  padding: 16px;
}

.e-bigger.e-dialog .e-dlg-header,
*.e-bigger .e-dialog .e-dlg-header {
  font-size: 20px;
}

.e-bigger.e-dialog .e-dlg-content,
*.e-bigger .e-dialog .e-dlg-content {
  font-size: 16px;
  padding: 16px;
}

.e-bigger.e-dialog .e-footer-content,
*.e-bigger .e-dialog .e-footer-content {
  padding: 16px;
}

.e-bigger.e-dialog .e-footer-content .e-btn,
*.e-bigger .e-dialog .e-footer-content .e-btn {
  margin-left: 10px;
}

.e-bigger.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn,
*.e-bigger .e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  bottom: 5px;
  height: 25px;
  left: 0;
  width: 26px;
}

.e-bigger.e-dialog .e-btn .e-btn-icon.e-icon-dlg-close,
*.e-bigger .e-dialog .e-btn .e-btn-icon.e-icon-dlg-close {
  font-size: 17px;
  width: 12px;
}

.e-bigger.e-rtl .e-footer-content .e-btn,
.e-bigger .e-rtl .e-footer-content .e-btn {
  margin-left: 0;
  margin-right: 10px;
}

.e-bigger .e-dlg-header-content + .e-dlg-content {
  padding-top: 16px;
}

.e-dialog .e-dlg-header-content {
  border-bottom: 1px solid #e9ecef;
  padding: 14px;
}

.e-dialog .e-dlg-content {
  padding: 14px;
}

.e-dialog .e-footer-content {
  padding: 14px;
}

.e-dialog .e-footer-content .e-btn {
  margin-left: 8px;
}

.e-rtl .e-footer-content .e-btn {
  margin-right: 8px;
}

.e-dialog.e-draggable > .e-dlg-header-content {
  cursor: move;
}

.e-dialog {
  max-height: 98%;
  max-width: 100%;
  min-width: 240px;
  position: absolute;
}

.e-rtl .e-footer-content .e-btn {
  margin-left: 0;
}

.e-rtl .e-footer-content {
  text-align: left;
}

.e-rtl .e-footer-content {
  text-align: left;
}

.e-dialog.e-rtl .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  float: left;
  left: 0;
  right: 0;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  bottom: 6px;
  float: right;
  height: 20px;
  left: 0;
  position: relative;
  width: 22px;
}

.e-rtl.e-dialog .e-resize-handle.e-south-west {
  bottom: 2px;
  cursor: sw-resize;
  direction: ltr;
  left: 2px;
  text-align: initial;
  transform: rotate(90deg);
}

.e-dlg-target.e-scroll-disabled {
  overflow: hidden !important;
}

.e-dlg-overlay {
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: fixed;
  top: 0;
  transition: opacity .15s linear;
  width: 100%;
}

.e-dlg-overlay.e-fade {
  opacity: 0;
}

.e-dlg-overflow-hidden {
  overflow: auto;
}

.e-dlg-fullscreen {
  height: 100% !important;
  left: 0 !important;
  top: 0 !important;
  width: 100% !important;
}

.e-popup.e-popup-open.e-dialog {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-dlg-container {
  -ms-flex-align: start;
      align-items: flex-start;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
}

.e-dlg-center-center {
  -webkit-align-items: center;
  -webkit-justify-content: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: center;
      justify-content: center;
}

.e-dlg-left-center {
  -webkit-align-items: center;
  -webkit-justify-content: flex-start;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.e-dlg-right-center {
  -webkit-align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-dlg-left-top {
  -webkit-align-items: flex-start;
  -webkit-justify-content: flex-start;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.e-dlg-right-top {
  -webkit-align-items: flex-start;
  -webkit-justify-content: flex-end;
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-dlg-center-top {
  -webkit-align-items: center;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
}

.e-dlg-left-bottom {
  -webkit-align-items: flex-end;
  -webkit-justify-content: flex-start;
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

.e-dlg-right-bottom {
  -webkit-align-items: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-align: end;
      align-items: flex-end;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-dlg-center-bottom {
  -webkit-align-items: center;
  -webkit-justify-content: flex-end;
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:hover,
.e-dialog .e-btn.e-dlg-closeicon-btn:focus,
.e-dialog .e-btn.e-dlg-closeicon-btn:active {
  background-color: transparent;
  border-color: transparent;
  box-shadow: 0 0 0 transparent;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:active {
  background-color: transparent;
}

.e-content-placeholder.e-dialog.e-placeholder-dialog {
  background-size: 400px 210px;
  min-height: 210px;
}

.e-bigger .e-content-placeholder.e-dialog.e-placeholder-dialog,
.e-bigger.e-content-placeholder.e-dialog.e-placeholder-dialog {
  background-size: 400px 220px;
  min-height: 220px;
}

@media (min-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px auto;
    width: 600px;
  }
}

@media (max-width: 768px) {
  .e-alert-dialog.e-dialog.e-popup,
  .e-confirm-dialog.e-dialog.e-popup {
    margin: 30px auto;
    width: auto;
  }
}

.e-dlg-ref-element {
  display: none;
}

.e-dialog .e-footer-content {
  box-sizing: border-box;
}

.e-dialog {
  background-color: #fff;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
}

.e-dlg-overlay {
  background-color: #000;
}

.e-footer-content {
  background-color: #fff;
}

.e-dlg-header,
.e-dlg-header * {
  color: #212529;
  font-size: 16px;
  font-weight: 500;
}

.e-dlg-content {
  color: #212529;
}

.e-device .e-dlg-content,
.e-device .e-dlg-content * {
  font-size: 12px;
}

.e-dlg-header-content {
  background-color: #fff;
}

.e-dlg-content {
  background-color: #fff;
}

.e-icon-dlg-close {
  color: #000;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:hover span {
  color: #000;
}

.e-dialog .e-btn.e-dlg-closeicon-btn:active span,
.e-dialog .e-btn.e-dlg-closeicon-btn:focus span {
  border-radius: 50%;
  color: #000;
  opacity: 1;
}

.e-icon-dlg-close:active {
  border-radius: 50%;
  color: #000;
  opacity: 1;
}

.e-icon-dlg-close:hover {
  color: rgba(0, 0, 0, 0.75);
}

.e-dlg-header-content .e-dlg-closeicon-btn:hover {
  background-color: rgba(0, 0, 0, 0.75);
}

.e-dlg-header-content .e-dlg-closeicon-btn:active {
  background-color: rgba(0, 0, 0, 0.5);
}

.e-south-east {
  color: #000;
}

.e-rtl .e-south-east {
  color: transparent;
}

.e-rtl .e-south-west {
  color: #000;
}

.e-south-west,
.e-north-east,
.e-north-west {
  color: transparent;
}

.e-dialog .e-dlg-header-content .e-btn.e-dlg-closeicon-btn.e-ripple {
  overflow: visible;
}

/*! popup layout */
.e-popup {
  height: auto;
  position: absolute;
  width: auto;
  z-index: 1000;
}

.e-popup.e-popup-open {
  display: block;
}

.e-popup.e-popup-close {
  display: none;
}

.e-tooltip-close::before {
  content: '\e761';
  font-size: 16px;
}

.e-arrow-tip-inner.e-tip-right::before {
  content: '\e70b';
}

.e-arrow-tip-inner.e-tip-top::before {
  content: '\e721';
}

.e-arrow-tip-inner.e-tip-bottom::before {
  content: '\e744';
}

.e-arrow-tip-inner.e-tip-left::before {
  content: '\e71f';
}

/*! tooltip popup container layout */
.e-tooltip-popup-container {
  position: relative;
}

/*! tooltip layout */
.e-tooltip-wrap {
  max-width: 350px;
  min-width: 30px;
  padding: 0;
  position: absolute;
  visibility: visible;
  /*! tooltip arrow */
  /*! tooltip sticky mode close icon */
  /*! tooltip content area */
}

.e-tooltip-wrap .e-arrow-tip {
  overflow: hidden;
  position: absolute;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-bottom {
  height: 8px;
  left: 50%;
  top: 8px;
  width: 16px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-top {
  height: 8px;
  left: 50%;
  top: -9px;
  width: 16px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-left {
  height: 16px;
  left: -9px;
  top: 48%;
  width: 8px;
}

.e-tooltip-wrap .e-arrow-tip.e-tip-right {
  height: 16px;
  left: 100%;
  top: 50%;
  width: 8px;
}

.e-tooltip-wrap .e-tooltip-close {
  cursor: pointer;
  float: right;
  position: absolute;
  right: -9px;
  top: -9px;
  z-index: inherit;
}

.e-tooltip-wrap .e-tip-content {
  background-color: inherit;
  height: 100%;
  line-height: 17px;
  overflow-wrap: break-word;
  overflow-x: hidden;
  padding: 4px 8px;
  position: relative;
  white-space: normal;
  width: 100%;
  word-break: break-word;
  z-index: 1;
  border-radius: inherit;
}

/*! Bigger Style */
.e-bigger .e-tooltip-wrap .e-tip-content,
.e-tooltip-wrap.e-bigger .e-tip-content {
  line-height: 20px;
  padding: 4px 8px;
  line-height: 1.5;
}

/*! Tooltip theme */
.e-tooltip-wrap {
  border-radius: 4px;
  filter: none;
  opacity: 1;
  /*! tooltip arrow */
  /*! tooltip sticky mode close icon */
  /*! tooltip content area */
}

.e-tooltip-wrap.e-popup {
  background-color: rgba(0, 0, 0, 0.9);
  border: 1px solid #000;
}

.e-tooltip-wrap .e-arrow-tip-outer {
  height: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 0;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-bottom {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #000;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {
  border-bottom: 8px solid #000;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-left {
  border-bottom: 8px solid transparent;
  border-right: 8px solid #000;
  border-top: 8px solid transparent;
}

.e-tooltip-wrap .e-arrow-tip-outer.e-tip-right {
  border-bottom: 8px solid transparent;
  border-left: 8px solid #000;
  border-top: 8px solid transparent;
}

.e-tooltip-wrap .e-arrow-tip-inner {
  height: 0;
  position: absolute;
  width: 0;
  z-index: 10;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-right, .e-tooltip-wrap .e-arrow-tip-inner.e-tip-left, .e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom, .e-tooltip-wrap .e-arrow-tip-inner.e-tip-top {
  color: rgba(0, 0, 0, 0.9);
  font-family: 'e-icons';
  font-size: 16px;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  text-transform: none;
}

.e-tooltip-wrap .e-tooltip-close {
  background-color: #fff;
  border-color: #fff;
  border-radius: 8px;
  color: rgba(0, 0, 0, 0.9);
  border: 1px solid #fff;
}

.e-tooltip-wrap .e-tooltip-close:hover {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.85);
}

.e-tooltip-wrap .e-tip-content {
  color: #fff;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 12px;
  line-height: 1.5;
}

/*! bigger style */
.e-bigger .e-tooltip-wrap .e-tip-content,
.e-tooltip-wrap.e-bigger .e-tip-content {
  font-size: 14px;
}

@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.e-spinner-pane {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 100%;
  -ms-flex-pack: center;
      justify-content: center;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  vertical-align: middle;
  width: 100%;
  z-index: 1000;
}

.e-spinner-pane::after {
  content: "Bootstrap4";
  display: none;
}

.e-spinner-pane.e-spin-left .e-spinner-inner {
  -webkit-transform: translateX(0%) translateY(-50%);
  left: 0;
  padding-left: 10px;
  transform: translateX(0%) translateY(-50%);
}

.e-spinner-pane.e-spin-right .e-spinner-inner {
  -webkit-transform: translateX(-100%) translateY(-50%);
  left: 100%;
  padding-right: 10px;
  transform: translateX(-100%) translateY(-50%);
}

.e-spinner-pane.e-spin-center .e-spinner-inner {
  -webkit-transform: translateX(-50%) translateY(-50%);
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.e-spinner-pane.e-spin-hide {
  display: none;
}

.e-spinner-pane.e-spin-show {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-spinner-pane .e-spinner-inner {
  -webkit-transform: translateX(-50%) translateY(-50%);
  left: 50%;
  margin: 0;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1000;
}

.e-spinner-pane .e-spinner-inner .e-spin-label {
  font-family: "Helvetica Neue", "Segoe UI";
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
  animation: material-spinner-rotate 1568.63ms linear infinite;
  display: block;
  margin: 0 auto;
}

.e-spinner-pane .e-spinner-inner .e-spin-material .e-path-circle {
  fill: none;
  stroke-linecap: square;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 {
  animation: material-spinner-rotate .75s linear infinite;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
  fill: none;
  stroke-linecap: square;
  stroke-width: 4;
}

.e-spinner-pane .e-spinner-inner .e-spin-fluent,
.e-spinner-pane .e-spinner-inner .e-spin-fabric {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
  display: block;
  margin: 0 auto;
  overflow: visible;
}

.e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-arc,
.e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-circle,
.e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-arc,
.e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-circle {
  fill: none;
  stroke-width: 1.5;
}

.e-spinner-pane .e-spinner-inner .e-spin-tailwind {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(0.21, 0.21, 0.21, 0.21);
  display: block;
  margin: 0 auto;
  overflow: visible;
}

.e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-arc,
.e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-circle {
  fill: none;
  stroke-width: 4.5;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 {
  animation: material-spinner-rotate .75s linear infinite;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 .e-path-circle {
  fill: none;
  stroke-linecap: square;
  stroke-width: 4;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap {
  display: block;
  margin: 0 auto;
}

.e-spinner-pane .e-spinner-inner .e-spin-high-contrast {
  animation: fabric-spinner-rotate 1.3s infinite cubic-bezier(0.53, 0.21, 0.29, 0.67);
  display: block;
  margin: 0 auto;
  overflow: visible;
}

.e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-arc,
.e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-circle {
  fill: none;
  stroke-width: 1.5;
}

.e-spinner-pane {
  background-color: transparent;
}

.e-spinner-pane.e-spin-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.e-spinner-pane.e-spin-overlay .e-spinner-inner .e-spin-label {
  color: #000;
}

.e-spinner-pane.e-spin-overlay .e-spinner-inner .e-spin-bootstrap {
  fill: #007bff;
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-label {
  color: #000;
}

.e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-circle,
.e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-circle {
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-fluent .e-path-arc,
.e-spinner-pane .e-spinner-inner .e-spin-fabric .e-path-arc {
  stroke: #adb5bd;
}

.e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-circle {
  stroke: #adb5bd;
}

.e-spinner-pane .e-spinner-inner .e-spin-tailwind .e-path-arc {
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap5 {
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-circle {
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-high-contrast .e-path-arc {
  stroke: #adb5bd;
}

.e-spinner-pane .e-spinner-inner .e-spin-material {
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 {
  stroke: #007bff;
}

.e-spinner-pane .e-spinner-inner .e-spin-bootstrap {
  fill: #007bff;
  stroke: #007bff;
}
