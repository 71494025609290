@charset "UTF-8";
.e-badge {
  background: #fafafa;
  border-color: transparent;
  border-radius: 0.335em;
  box-shadow: 0 0 0 2px transparent;
  box-sizing: border-box;
  color: rgba(0, 0, 0, 0.87);
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 0.75em;
  font-weight: 500;
  line-height: 1;
  overflow: hidden;
  padding: 0.25em 0.42em 0.25em 0.42em;
  text-align: center;
  text-decoration: none;
  text-indent: 0;
  vertical-align: middle;
}

.e-badge:hover {
  text-decoration: none;
}

.e-badge.e-badge-pill {
  border-radius: 5em;
}

.e-badge.e-badge-notification {
  border-radius: 1em;
  font-size: 12px;
  height: 18px;
  left: 100%;
  line-height: 18px;
  min-width: 24px;
  padding: 0 8px 0 8px;
  position: absolute;
  top: -10px;
  width: auto;
}

.e-badge.e-badge-notification.e-badge-ghost {
  line-height: 16px;
}

.e-badge.e-badge-circle {
  border-radius: 50%;
  height: 1.834em;
  line-height: 1.834em;
  min-width: 0;
  padding: 0;
  width: 1.834em;
}

.e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1.8em;
}

.e-badge.e-badge-overlap {
  position: absolute;
  top: -10px;
  transform: translateX(-50%);
}

.e-badge.e-badge-dot {
  border-radius: 100%;
  box-shadow: 0 0 0 1px #fff;
  height: 6px;
  left: 100%;
  line-height: 1;
  margin: 0;
  min-width: 0;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: -3px;
  width: 6px;
}

.e-badge.e-badge-bottom.e-badge-dot {
  bottom: 3px;
  position: absolute;
  top: auto;
}

.e-badge.e-badge-bottom.e-badge-notification {
  bottom: -3px;
  position: absolute;
  top: auto;
}

button .e-badge {
  line-height: .9;
  position: relative;
  top: -2px;
}

button .e-badge.e-badge-circle {
  height: 2em;
  line-height: 2em;
  width: 2em;
}

button .e-badge.e-badge-circle.e-badge-ghost {
  line-height: 1.9em;
}

.e-badge.e-badge-primary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-primary[href]:not(.e-badge-ghost) {
  background-color: #007bff;
  color: #fff;
}

.e-badge.e-badge-secondary:not(.e-badge-ghost):not([href]), .e-badge.e-badge-secondary[href]:not(.e-badge-ghost) {
  background-color: #6c757d;
  color: #fff;
}

.e-badge.e-badge-success:not(.e-badge-ghost):not([href]), .e-badge.e-badge-success[href]:not(.e-badge-ghost) {
  background-color: #28a745;
  color: #fff;
}

.e-badge.e-badge-danger:not(.e-badge-ghost):not([href]), .e-badge.e-badge-danger[href]:not(.e-badge-ghost) {
  background-color: #dc3545;
  color: #fff;
}

.e-badge.e-badge-warning:not(.e-badge-ghost):not([href]), .e-badge.e-badge-warning[href]:not(.e-badge-ghost) {
  background-color: #ffc107;
  color: #212529;
}

.e-badge.e-badge-info:not(.e-badge-ghost):not([href]), .e-badge.e-badge-info[href]:not(.e-badge-ghost) {
  background-color: #17a2b8;
  color: #fff;
}

.e-badge.e-badge-light:not(.e-badge-ghost):not([href]), .e-badge.e-badge-light[href]:not(.e-badge-ghost) {
  background-color: #f8f9fa;
  color: #212529;
}

.e-badge.e-badge-dark:not(.e-badge-ghost):not([href]), .e-badge.e-badge-dark[href]:not(.e-badge-ghost) {
  background-color: #343a40;
  color: #fff;
}

.e-badge.e-badge-primary[href]:not(.e-badge-ghost):hover {
  background-color: #0062cc;
}

.e-badge.e-badge-secondary[href]:not(.e-badge-ghost):hover {
  background-color: #545b62;
}

.e-badge.e-badge-success[href]:not(.e-badge-ghost):hover {
  background-color: #1e7e34;
}

.e-badge.e-badge-danger[href]:not(.e-badge-ghost):hover {
  background-color: #bd2130;
}

.e-badge.e-badge-warning[href]:not(.e-badge-ghost):hover {
  background-color: #d39e00;
}

.e-badge.e-badge-info[href]:not(.e-badge-ghost):hover {
  background-color: #117a8b;
}

.e-badge.e-badge-light[href]:not(.e-badge-ghost):hover {
  background-color: #dae0e5;
}

.e-badge.e-badge-dark[href]:not(.e-badge-ghost):hover {
  background-color: #1d2124;
}

.e-badge.e-badge-primary[href].e-badge-ghost:hover {
  border-color: #0056b3;
  color: #0056b3;
}

.e-badge.e-badge-secondary[href].e-badge-ghost:hover {
  border-color: #494f54;
  color: #494f54;
}

.e-badge.e-badge-success[href].e-badge-ghost:hover {
  border-color: #19692c;
  color: #19692c;
}

.e-badge.e-badge-danger[href].e-badge-ghost:hover {
  border-color: #a71d2a;
  color: #a71d2a;
}

.e-badge.e-badge-warning[href].e-badge-ghost:hover {
  border-color: #ba8b00;
  color: #ba8b00;
}

.e-badge.e-badge-info[href].e-badge-ghost:hover {
  border-color: #0f6674;
  color: #0f6674;
}

.e-badge.e-badge-light[href].e-badge-ghost:hover {
  border-color: #cbd3da;
  color: #cbd3da;
}

.e-badge.e-badge-dark[href].e-badge-ghost:hover {
  border-color: #121416;
  color: #121416;
}

.e-badge.e-badge-ghost.e-badge-primary {
  background-color: transparent;
  border: 1px solid #007bff;
  color: #007bff;
}

.e-badge.e-badge-ghost.e-badge-secondary {
  background-color: transparent;
  border: 1px solid #6c757d;
  color: #6c757d;
}

.e-badge.e-badge-ghost.e-badge-success {
  background-color: transparent;
  border: 1px solid #28a745;
  color: #28a745;
}

.e-badge.e-badge-ghost.e-badge-danger {
  background-color: transparent;
  border: 1px solid #dc3545;
  color: #dc3545;
}

.e-badge.e-badge-ghost.e-badge-warning {
  background-color: transparent;
  border: 1px solid #ffc107;
  color: #ffc107;
}

.e-badge.e-badge-ghost.e-badge-info {
  background-color: transparent;
  border: 1px solid #17a2b8;
  color: #17a2b8;
}

.e-badge.e-badge-ghost.e-badge-light {
  background-color: transparent;
  border: 1px solid #f8f9fa;
  color: #f8f9fa;
}

.e-badge.e-badge-ghost.e-badge-dark {
  background-color: transparent;
  border: 1px solid #343a40;
  color: #343a40;
}

.e-toast .e-toast-close-icon::before {
  content: "";
}

.e-toast .e-toast-success-icon::before {
  content: "";
}

.e-toast .e-toast-error-icon::before {
  content: "";
}

.e-toast .e-toast-info-icon::before {
  content: "";
}

.e-toast .e-toast-warning-icon::before {
  content: "";
}

/*! toast layout */
.e-bigger .e-toast-container.e-toast-top-left,
.e-toast-container.e-bigger.e-toast-top-left {
  left: 10px;
  top: 10px;
}

.e-bigger .e-toast-container.e-toast-bottom-left,
.e-toast-container.e-bigger.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}

.e-bigger .e-toast-container.e-toast-top-right,
.e-toast-container.e-bigger.e-toast-top-right {
  right: 10px;
  top: 10px;
}

.e-bigger .e-toast-container.e-toast-bottom-right,
.e-toast-container.e-bigger.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}

.e-bigger .e-toast-container.e-toast-bottom-center,
.e-toast-container.e-bigger.e-toast-bottom-center {
  bottom: 10px;
}

.e-bigger .e-toast-container.e-toast-bottom-center .e-toast,
.e-toast-container.e-bigger.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
}

.e-bigger .e-toast-container.e-toast-top-center,
.e-toast-container.e-bigger.e-toast-top-center {
  top: 10px;
}

.e-bigger .e-toast-container.e-toast-top-center .e-toast,
.e-toast-container.e-bigger.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
}

.e-bigger .e-toast-container.e-toast-full-width,
.e-toast-container.e-bigger.e-toast-full-width {
  left: 0;
  right: 0;
}

.e-bigger .e-toast-container.e-toast-full-width .e-toast,
.e-toast-container.e-bigger.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
}

.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions {
  text-align: left;
}

.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}

.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}

.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-icon,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-icon {
  margin-left: 10px;
  margin-right: initial;
}

.e-bigger .e-toast-container.e-rtl .e-toast .e-toast-progress,
.e-toast-container.e-bigger.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}

.e-bigger .e-toast-container .e-toast,
.e-toast-container.e-bigger .e-toast {
  border-radius: 4px;
  font-size: 14px;
  margin: 0 0 10px;
  min-height: 44px;
}

.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content {
  padding: 10px 0 5px 0;
}

.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:first-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
}

.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content:last-child,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
}

.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding: 0;
}

.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions {
  padding: 5px 0 0 0;
  text-align: right;
}

.e-bigger .e-toast-container .e-toast .e-toast-message .e-toast-actions > *,
.e-toast-container.e-bigger .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}

.e-bigger .e-toast-container .e-toast .e-toast-close-icon,
.e-toast-container.e-bigger .e-toast .e-toast-close-icon {
  font-size: 12px;
  height: 24px;
  width: 24px;
}

.e-bigger .e-toast-container .e-toast .e-toast-icon,
.e-toast-container.e-bigger .e-toast .e-toast-icon {
  font-size: 20px;
  height: 24px;
  margin-right: 10px;
  width: 24px;
}

.e-bigger .e-toast-container .e-toast .e-toast-progress,
.e-toast-container.e-bigger .e-toast .e-toast-progress {
  height: 4px;
}

.e-toast-container {
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-direction: column;
      flex-direction: column;
  position: relative;
}

.e-toast-container.e-toast-top-left {
  left: 10px;
  top: 10px;
}

.e-toast-container.e-toast-bottom-left {
  bottom: 10px;
  left: 10px;
}

.e-toast-container.e-toast-top-right {
  right: 10px;
  top: 10px;
}

.e-toast-container.e-toast-bottom-right {
  bottom: 10px;
  right: 10px;
}

.e-toast-container.e-toast-bottom-center {
  bottom: 10px;
  pointer-events: none;
  right: 0;
  width: 100%;
}

.e-toast-container.e-toast-bottom-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}

.e-toast-container.e-toast-top-center {
  pointer-events: none;
  right: 0;
  top: 10px;
  width: 100%;
}

.e-toast-container.e-toast-top-center .e-toast {
  margin: 0 auto 10px;
  pointer-events: auto;
}

.e-toast-container.e-toast-full-width {
  left: 0;
  right: 0;
  width: 100%;
}

.e-toast-container.e-toast-full-width .e-toast {
  margin: 0 auto 10px;
  width: 96%;
}

.e-toast-container.e-rtl .e-toast .e-toast-actions {
  text-align: left;
}

.e-toast-container.e-rtl .e-toast .e-toast-actions > * {
  margin-left: initial;
  margin-right: 10px;
}

.e-toast-container.e-rtl .e-toast .e-toast-close-icon {
  margin-left: initial;
  margin-right: auto;
}

.e-toast-container.e-rtl .e-toast .e-toast-icon {
  margin-left: 8px;
  margin-right: initial;
}

.e-toast-container.e-rtl .e-toast .e-toast-progress {
  left: auto;
  right: 0;
}

.e-toast-container .e-toast {
  border-radius: 4px;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  margin: 0 0 10px;
  overflow: hidden;
  padding: 12px;
  position: relative;
}

.e-toast-container .e-toast .e-toast-icon,
.e-toast-container .e-toast .e-toast-message {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toast-container .e-toast > * {
  word-break: break-word;
  word-wrap: break-word;
}

.e-toast-container .e-toast .e-toast-message {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  -ms-flex-direction: column;
      flex-direction: column;
  overflow: hidden;
  width: inherit;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title,
.e-toast-container .e-toast .e-toast-message .e-toast-content {
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title:first-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:first-child {
  padding: 0;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title:last-child,
.e-toast-container .e-toast .e-toast-message .e-toast-content:last-child {
  padding-bottom: 0;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title > *,
.e-toast-container .e-toast .e-toast-message .e-toast-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-toast-container .e-toast .e-toast-message .e-toast-title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: .5px;
}

.e-toast-container .e-toast .e-toast-message .e-toast-content {
  padding: 10px 0 5px 0;
  word-break: break-word;
  word-wrap: break-word;
}

.e-toast-container .e-toast .e-toast-message .e-toast-content + .e-toast-actions {
  padding-top: 0;
}

.e-toast-container .e-toast .e-toast-message .e-toast-actions {
  margin: 1px;
  padding: 5px 0 0 0;
  text-align: right;
}

.e-toast-container .e-toast .e-toast-message .e-toast-actions > * {
  margin-left: 10px;
}

.e-toast-container .e-toast .e-toast-close-icon {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  font-size: 10px;
  height: 24px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-left: auto;
  width: 24px;
}

.e-toast-container .e-toast .e-toast-icon {
  -ms-flex-align: center;
      align-items: center;
  font-size: 16px;
  height: 24px;
  -ms-flex-pack: center;
      justify-content: center;
  margin-right: 8px;
  width: 24px;
}

.e-toast-container .e-toast .e-toast-progress {
  bottom: 0;
  height: 4px;
  left: 0;
  position: absolute;
}

.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-bigger .e-content-placeholder.e-toast.e-placeholder-toast,
.e-bigger.e-content-placeholder.e-toast.e-placeholder-toast {
  background-size: 400px 100px;
  min-height: 100px;
}

.e-blazor-toast-hidden {
  visibility: hidden;
}

.e-toast-container .e-toast .e-toast-close-icon.blazor-toast-close-icon {
  background-color: transparent;
  border-color: transparent;
  border-radius: 50%;
  bottom: 6px;
  height: 20px;
  position: relative;
  width: 22px;
}

.e-toast-container .e-toast {
  background-color: rgba(255, 255, 255, 0.85);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.1);
  color: #212529;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.e-toast-container .e-toast .e-toast-close-icon {
  color: rgba(0, 0, 0, 0.5);
}

.e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:focus, .e-toast-container .e-toast .e-toast-close-icon.e-blazor-toast-close-icon:active {
  background-color: transparent;
  border: 0;
  box-shadow: none;
  outline: 0;
}

.e-toast-container .e-toast.e-toast-success {
  background-color: #d4edda;
  color: #155724;
  border-color: #c3e6cb;
}

.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-title {
  color: #155724;
}

.e-toast-container .e-toast.e-toast-success .e-toast-message .e-toast-content {
  color: #155724;
}

.e-toast-container .e-toast.e-toast-success .e-toast-icon {
  color: #155724;
}

.e-toast-container .e-toast.e-toast-success .e-toast-close-icon {
  color: #155724;
}

.e-toast-container .e-toast.e-toast-success:hover {
  background-color: #c2e5cb;
  box-shadow: 0 4px 12px;
  color: #155724;
  border-color: #b1debb;
}

.e-toast-container .e-toast.e-toast-info {
  background-color: #cce5ff;
  color: #004085;
  border-color: #b8daff;
}

.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-title {
  color: #004085;
}

.e-toast-container .e-toast.e-toast-info .e-toast-message .e-toast-content {
  color: #004085;
}

.e-toast-container .e-toast.e-toast-info .e-toast-icon {
  color: #004085;
}

.e-toast-container .e-toast.e-toast-info .e-toast-close-icon {
  color: #004085;
}

.e-toast-container .e-toast.e-toast-info:hover {
  background-color: #b3d8ff;
  box-shadow: 0 4px 12px;
  color: #004085;
  border-color: #9ecdff;
}

.e-toast-container .e-toast.e-toast-warning {
  background-color: #fff3cd;
  color: #856404;
  border-color: #ffeeba;
}

.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-title {
  color: #856404;
}

.e-toast-container .e-toast.e-toast-warning .e-toast-message .e-toast-content {
  color: #856404;
}

.e-toast-container .e-toast.e-toast-warning .e-toast-icon {
  color: #856404;
}

.e-toast-container .e-toast.e-toast-warning .e-toast-close-icon {
  color: #856404;
}

.e-toast-container .e-toast.e-toast-warning:hover {
  background-color: #ffedb3;
  box-shadow: 0 4px 12px;
  color: #856404;
  border-color: #ffe8a0;
}

.e-toast-container .e-toast.e-toast-danger {
  background-color: #f8d7da;
  color: #721c24;
}

.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-title {
  color: #721c24;
}

.e-toast-container .e-toast.e-toast-danger .e-toast-message .e-toast-content {
  color: #721c24;
}

.e-toast-container .e-toast.e-toast-danger .e-toast-icon {
  color: #721c24;
}

.e-toast-container .e-toast.e-toast-danger .e-toast-close-icon {
  color: #721c24;
}

.e-toast-container .e-toast.e-toast-danger:hover {
  background-color: #f4c1c6;
  box-shadow: 0 4px 12px;
  color: #721c24;
  border-color: #f1b0b7;
}

.e-toast-container .e-toast:hover {
  background-color: #fff;
  box-shadow: 0 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.e-toast-container .e-toast .e-toast-icon {
  color: rgba(0, 0, 0, 0.5);
}

.e-toast-container .e-toast .e-toast-message .e-toast-title {
  color: #6c757d;
}

.e-toast-container .e-toast .e-toast-message .e-toast-content {
  color: #212529;
}

.e-toast-container .e-toast .e-toast-progress {
  background-color: #007bff;
}
