/*! calendar bootstrap theme variables */
.e-input-group.e-ddl .e-control.e-autocomplete ~ .e-ddl-icon {
  font-size: 8px;
}

.e-bigger .e-input-group.e-ddl .e-control.e-autocomplete ~ .e-ddl-icon {
  font-size: 10px;
}

.e-input-group.e-ddl .e-control.e-combobox ~ .e-ddl-icon {
  font-size: 8px;
}

.e-bigger .e-input-group.e-ddl .e-control.e-combobox ~ .e-ddl-icon {
  font-size: 10px;
}

.e-popup.e-ddl {
  border-radius: 4px;
  box-shadow: none;
  margin-top: 3px;
}

.e-popup.e-ddl .e-input-group {
  width: auto;
}

.e-popup.e-ddl .e-input-group input {
  line-height: 15px;
}

.e-popup.e-ddl .e-dropdownbase {
  min-height: 26px;
}

.e-popup.e-ddl .e-filter-parent .e-input-group {
  display: -ms-flexbox;
  display: flex;
  width: auto;
}

.e-popup.e-ddl .e-filter-parent .e-input-group .e-back-icon {
  border: 0;
}

.e-bigger .e-popup.e-ddl-device-filter {
  margin-top: 0;
}

.e-bigger .e-popup.e-ddl .e-list-item {
  font-size: 16px;
  line-height: 32px;
  padding-left: 0;
  text-indent: 24px;
}

.e-bigger .e-popup.e-ddl .e-list-group-item,
.e-bigger .e-popup.e-ddl .e-fixed-head {
  font-size: 14px;
  line-height: 32px;
  padding-left: 0;
  text-indent: 24px;
}

.e-bigger .e-popup.e-ddl .e-dd-group .e-list-item {
  padding-left: 4px;
}

.e-bigger .e-popup.e-ddl .e-input-group {
  padding: 4px 0;
}

.e-bigger .e-popup.e-ddl .e-input-group input,
.e-bigger .e-popup.e-ddl .e-input-group input.e-input {
  font-size: 16px;
  height: 36px;
}

.e-bigger .e-popup.e-ddl .e-dropdownbase {
  min-height: 40px;
}

.e-input-group.e-control-wrapper.e-ddl .e-input[readonly],
.e-float-input.e-control-wrapper.e-ddl input[readonly] {
  background: transparent;
}

.e-input-group.e-control-wrapper.e-ddl.e-readonly .e-input[readonly],
.e-float-input.e-control-wrapper.e-ddl.e-readonly input[readonly] {
  background: #e9ecef;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:active,
.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon:hover,
.e-control.e-dropdownlist .e-input-group:not(.e-disabled) .e-ddl-icon:active,
.e-control.e-dropdownlist .e-input-group:not(.e-disabled) .e-ddl-icon:hover,
.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-clear-icon:active,
.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-clear-icon:hover,
.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-back-icon:active,
.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-back-icon:hover {
  background: transparent;
  color: #495057;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-ddl-icon,
.e-input-group.e-disabled.e-ddl .e-control.e-dropdownlist ~ .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-disabled.e-ddl .e-input-group-icon,
.e-control.e-dropdownlist .e-input-group.e-ddl .e-input-group-icon {
  border: 0;
}

.e-input-group:not(.e-disabled) .e-control.e-dropdownlist ~ .e-input-group-icon:active,
.e-control.e-dropdownlist .e-input-group:not(.e-disabled) .e-input-group-icon:active,
.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-back-icon:active,
.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-clear-icon:active {
  box-shadow: none;
}

.e-ddl.e-popup .e-input-group:not(.e-disabled) .e-clear-icon {
  background: transparent;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}

.e-ddl.e-popup .e-filter-parent .e-input-group,
.e-ddl.e-popup .e-filter-parent {
  background: rgba(0, 0, 0, 0.03);
}

.e-input-group .e-control.e-dropdownlist ~ .e-ddl-icon {
  font-size: 8px;
}

.e-bigger .e-input-group .e-control.e-dropdownlist ~ .e-ddl-icon {
  font-size: 10px;
}

.e-bigger.e-small .e-ddl.e-popup .e-list-item,
.e-bigger.e-small .e-ddl.e-popup .e-list-group-item,
.e-bigger.e-small .e-ddl.e-popup .e-fixed-head {
  font-size: 14px;
  line-height: 34px;
  padding-left: 0;
  text-indent: 16px;
}

.e-bigger.e-small .e-ddl.e-popup .e-dd-group .e-list-item {
  padding-left: 4px;
}

.e-bigger.e-small .e-ddl.e-popup .e-input-group {
  padding: 4px 0;
}

.e-bigger.e-small .e-ddl.e-popup .e-input-group input,
.e-bigger.e-small .e-ddl.e-popup .e-input-group input.e-input {
  height: 30px;
}

.e-bigger.e-small .e-popup.e-ddl .e-dropdownbase {
  min-height: 34px;
}

.e-multi-select-wrapper .e-chips-collection .e-chips .e-chips-close.e-icon::before {
  line-height: 30px;
  top: 0;
}

.e-multiselect .e-input-group-icon.e-ddl-icon {
  border-radius: 0 4px 4px 0;
  border-right-width: 0;
}

.e-multiselect.e-rtl .e-input-group-icon.e-ddl-icon {
  border-left-width: 0;
  border-radius: 4px 0 0 4px;
  border-right-width: 1px;
}

.e-bigger .e-multi-select-wrapper .e-chips > .e-chipcontent {
  font-size: 14px;
}

.e-bigger .e-multi-select-wrapper .e-chips-close {
  height: 30px;
  width: 30px;
}

.e-popup.e-multi-select-list-wrapper .e-list-item.e-active.e-item-focus.e-hover {
  box-shadow: none;
}

.e-bigger .e-ddl.e-popup .e-filter-parent .e-clear-icon {
  display: -ms-flexbox;
  display: flex;
}

.e-input-group.e-multiselect .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
  stroke-width: 1px;
}

.e-small .e-multi-select-wrapper .e-chips {
  margin: 1px 4px 1px 0;
}

.e-small.e-bigger .e-multi-select-wrapper .e-chips {
  margin: 2px 4px 2px 0;
}

.e-multiselect.e-float-input input[readonly],
.e-multiselect.e-float-input.e-control-wrapper input[readonly] {
  background: transparent;
}

.e-small .e-multi-select-wrapper .e-close-hooker::before {
  top: 7px;
}

.e-bigger .e-multiselect .e-multi-select-wrapper .e-chips-close.e-close-hooker {
  height: 36px;
  margin-top: -3em;
  right: 0;
}

.e-bigger .e-multiselect .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger.e-multiselect .e-multi-select-wrapper.e-down-icon .e-chips-close.e-close-hooker,
.e-bigger .e-multiselect.e-input-group.e-control-container .e-multi-select-wrapper.e-down-icon .e-clear-icon {
  right: 34px;
}

.e-bigger .e-multiselect.e-input-group.e-control-container .e-multi-select-wrapper .e-clear-icon {
  height: 36px;
  margin-top: -3.5em;
}

@keyframes material-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fabric-spinner-rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.e-richtexteditor {
  border-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:focus .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn {
  border-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn:active,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-control.e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active {
  border: 1px solid #4e555b;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn:hover,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-btn:hover {
  border: 1px solid #545b62;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-btn:focus {
  border: 1px solid #6c757d;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-dropdown-btn {
  border: 1px solid transparent;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-dropdown-btn.e-active {
  border-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-dropdown-btn.e-active .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn.e-icon-btn {
  padding: 0 2px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover {
  border: 1px solid transparent;
  padding: 0 2px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-inline-dropdown.e-control.e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-inline-dropdown.e-control.e-tbar-btn.e-btn {
  border: 1px solid transparent;
  padding-bottom: 1px;
  padding-top: 1px;
}

.e-richtexteditor .e-rte-content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.e-richtexteditor .e-rte-table-popup.e-popup.e-popup-open {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.e-richtexteditor .e-rte-table-popup.e-popup.e-popup-open .e-insert-table-btn .e-btn-icon.e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
  font-size: 14px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item.e-toolbar-popup .e-tbar-btn {
  padding: 0 5px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn.e-rte-dropdown-items.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-items.e-rte-dropdown-btn:hover {
  padding-left: 5px;
  padding-right: 5px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn.e-rte-dropdown-items {
  border: 1px solid transparent;
  padding-left: 5px;
  padding-right: 5px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn.e-icon-btn {
  padding: 0 5px;
}

.e-bigger .e-richtexteditor .e-rte-content .e-content,
.e-bigger .e-richtexteditor .e-source-content .e-content {
  font-size: 16px;
}

.e-rte-quick-popup.e-popup {
  border-radius: 4px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-rte-color-content {
  line-height: 26px;
  min-height: 26px;
  min-width: 26px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-btn-icon.e-caret {
  font-size: 8px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-rte-color-content {
  line-height: 32px;
  min-height: 26px;
  min-width: 32px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-btn-icon.e-caret {
  font-size: 10px;
}

.e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown {
  padding-left: 1px;
  padding-right: 1px;
}

.e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown.e-active:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown.e-active:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown.e-active:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown.e-active:hover {
  padding-left: 1px;
  padding-right: 1px;
}

.e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown {
  padding-bottom: 0;
  padding-top: 0;
}

.e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover, .e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active, .e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover {
  padding-bottom: 0;
  padding-top: 0;
}

.e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-active {
  border-radius: 4px;
}

/*! Bootstrap4 theme definitions and variables */
/*! Value element styles */
/*! Tooltip styles */
/*! Tip Content styles */
/*! Title styles */
/*! Wrapper styles */
/*! Component group styles */
/*! Button styles */
/*! inplaceeditor icons */
.e-inplaceeditor .e-save-icon::before,
.e-inplaceeditor-tip .e-save-icon::before {
  content: '\e718';
}

.e-inplaceeditor .e-cancel-icon::before,
.e-inplaceeditor-tip .e-cancel-icon::before {
  content: '\e745';
}

.e-inplaceeditor .e-editable-overlay-icon::before,
.e-inplaceeditor-tip .e-editable-overlay-icon::before {
  content: '\e78f';
}

/*! inplaceeditor layout */
.e-bigger .e-inplaceeditor .e-editable-value-wrapper,
.e-inplaceeditor.e-bigger .e-editable-value-wrapper {
  padding: 8px 12px;
}

.e-bigger .e-inplaceeditor .e-editable-value-wrapper .e-editable-value,
.e-inplaceeditor.e-bigger .e-editable-value-wrapper .e-editable-value {
  font-size: 16px;
  margin: 0 32px 0 0;
}

.e-bigger .e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon,
.e-inplaceeditor.e-bigger .e-editable-value-wrapper .e-editable-overlay-icon {
  right: 12px;
  width: 20px;
}

.e-bigger .e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon::before,
.e-inplaceeditor.e-bigger .e-editable-value-wrapper .e-editable-overlay-icon::before {
  font-size: 16px;
}

.e-bigger .e-inplaceeditor .e-editable-value-wrapper .e-spinner-pane .e-spinner-inner,
.e-inplaceeditor.e-bigger .e-editable-value-wrapper .e-spinner-pane .e-spinner-inner {
  right: 12px;
}

.e-bigger .e-inplaceeditor .e-editable-value-container,
.e-inplaceeditor.e-bigger .e-editable-value-container {
  padding: 8px 12px;
}

.e-bigger .e-inplaceeditor .e-editable-value-container .e-editable-value,
.e-inplaceeditor.e-bigger .e-editable-value-container .e-editable-value {
  font-size: 16px;
  margin: 0 32px 0 0;
}

.e-bigger .e-inplaceeditor .e-editable-value-container .e-editable-overlay-icon,
.e-inplaceeditor.e-bigger .e-editable-value-container .e-editable-overlay-icon {
  right: 12px;
  width: 20px;
}

.e-bigger .e-inplaceeditor .e-editable-value-container .e-editable-overlay-icon::before,
.e-inplaceeditor.e-bigger .e-editable-value-container .e-editable-overlay-icon::before {
  font-size: 16px;
}

.e-bigger .e-inplaceeditor .e-editable-value-container .e-spinner-pane .e-spinner-inner,
.e-inplaceeditor.e-bigger .e-editable-value-container .e-spinner-pane .e-spinner-inner {
  right: 12px;
}

.e-bigger .e-inplaceeditor .e-editable-action-buttons .e-btn-save,
.e-inplaceeditor.e-bigger .e-editable-action-buttons .e-btn-save {
  margin: 0 4px 0 0;
}

.e-bigger .e-inplaceeditor .e-editable-action-buttons .e-btn-cancel,
.e-inplaceeditor.e-bigger .e-editable-action-buttons .e-btn-cancel {
  margin: 0 0 0 4px;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-editable-value,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-wrapper .e-editable-value {
  margin: 0 0 0 32px;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-editable-overlay-icon,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-wrapper .e-editable-overlay-icon {
  left: 12px;
  right: auto;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-spinner-pane,
.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-spinner-pane .e-spinner-inner,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-wrapper .e-spinner-pane,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-wrapper .e-spinner-pane .e-spinner-inner {
  left: 12px;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-container .e-editable-value,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-container .e-editable-value {
  margin: 0 0 0 32px;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-container .e-editable-overlay-icon,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-container .e-editable-overlay-icon {
  left: 12px;
  right: auto;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-container .e-spinner-pane,
.e-bigger .e-inplaceeditor.e-rtl .e-editable-value-container .e-spinner-pane .e-spinner-inner,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-container .e-spinner-pane,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-value-container .e-spinner-pane .e-spinner-inner {
  left: 12px;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-action-buttons .e-btn-save,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-action-buttons .e-btn-save {
  margin: 0 0 0 4px;
}

.e-bigger .e-inplaceeditor.e-rtl .e-editable-action-buttons .e-btn-cancel,
.e-inplaceeditor.e-bigger.e-rtl .e-editable-action-buttons .e-btn-cancel {
  margin: 0 4px 0 0;
}

.e-inplaceeditor {
  display: inline-block;
  position: relative;
  width: auto;
}

.e-inplaceeditor .e-input-group.e-numeric.e-control-wrapper .e-clear-icon.e-clear-icon-hide {
  display: block;
  visibility: hidden;
}

.e-inplaceeditor .e-input-group.e-numeric.e-control-container .e-clear-icon.e-clear-icon-hide {
  display: block;
  visibility: hidden;
}

.e-inplaceeditor .e-editable-value-wrapper {
  display: inline-block;
  padding: 6px 10px;
  position: relative;
  width: auto;
  word-break: break-word;
}

.e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  margin: 0 30px 0 0;
  line-height: 24px;
}

.e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon {
  bottom: 0;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: inherit;
  position: absolute;
  right: 10px;
  top: 0;
  visibility: hidden;
  width: 18px;
}

.e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon::before {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}

.e-inplaceeditor .e-editable-value-wrapper.e-hide {
  display: none;
}

.e-inplaceeditor .e-editable-value-wrapper.e-editable-open {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-inplaceeditor .e-editable-value-wrapper.e-loading {
  pointer-events: none;
}

.e-inplaceeditor .e-editable-value-wrapper .e-spinner-pane .e-spinner-inner {
  left: unset;
  right: 10px;
}

.e-inplaceeditor .e-editable-value-wrapper:hover .e-editable-overlay-icon {
  visibility: visible;
}

.e-inplaceeditor .e-editable-value-wrapper:hover.e-editable-open .e-editable-overlay-icon {
  visibility: hidden;
}

.e-inplaceeditor .e-editable-value-container {
  display: inline-block;
  padding: 6px 10px;
  position: relative;
  width: auto;
  word-break: break-word;
}

.e-inplaceeditor .e-editable-value-container .e-editable-value {
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  margin: 0 30px 0 0;
  line-height: 24px;
}

.e-inplaceeditor .e-editable-value-container .e-editable-overlay-icon {
  bottom: 0;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: inherit;
  position: absolute;
  right: 10px;
  top: 0;
  visibility: hidden;
  width: 18px;
}

.e-inplaceeditor .e-editable-value-container .e-editable-overlay-icon::before {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}

.e-inplaceeditor .e-editable-value-container.e-hide {
  display: none;
}

.e-inplaceeditor .e-editable-value-container.e-editable-open {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-inplaceeditor .e-editable-value-container.e-loading {
  pointer-events: none;
}

.e-inplaceeditor .e-editable-value-container .e-spinner-pane .e-spinner-inner {
  left: unset;
  right: 10px;
}

.e-inplaceeditor .e-editable-value-container:hover .e-editable-overlay-icon {
  visibility: visible;
}

.e-inplaceeditor .e-editable-value-container:hover.e-editable-open .e-editable-overlay-icon {
  visibility: hidden;
}

.e-inplaceeditor .e-editable-action-buttons {
  margin-top: 4px;
  position: absolute;
  right: 0;
  z-index: 100;
}

.e-inplaceeditor.e-overlay {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-editable-value {
  margin: 0 0 0 30px;
}

.e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-editable-overlay-icon {
  left: 10px;
  right: auto;
}

.e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-spinner-pane {
  right: unset;
}

.e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-spinner-pane,
.e-inplaceeditor.e-rtl .e-editable-value-wrapper .e-spinner-pane .e-spinner-inner {
  left: 10px;
}

.e-inplaceeditor.e-rtl .e-editable-value-container .e-editable-value {
  margin: 0 0 0 30px;
}

.e-inplaceeditor.e-rtl .e-editable-value-container .e-editable-overlay-icon {
  left: 10px;
  right: auto;
}

.e-inplaceeditor.e-rtl .e-editable-value-container .e-spinner-pane {
  right: unset;
}

.e-inplaceeditor.e-rtl .e-editable-value-container .e-spinner-pane,
.e-inplaceeditor.e-rtl .e-editable-value-container .e-spinner-pane .e-spinner-inner {
  left: 10px;
}

.e-inplaceeditor.e-rtl .e-editable-action-buttons {
  left: 0;
  right: auto;
}

.e-bigger .e-inplaceeditor,
.e-inplaceeditor.e-bigger,
.e-bigger .e-inplaceeditor-tip,
.e-inplaceeditor-tip.e-bigger {
  width: auto;
}

.e-bigger .e-inplaceeditor .e-editable-action-buttons .e-btn-save .e-btn-icon.e-icons,
.e-bigger .e-inplaceeditor .e-editable-action-buttons .e-btn-cancel .e-btn-icon.e-icons,
.e-inplaceeditor.e-bigger .e-editable-action-buttons .e-btn-save .e-btn-icon.e-icons,
.e-inplaceeditor.e-bigger .e-editable-action-buttons .e-btn-cancel .e-btn-icon.e-icons,
.e-bigger .e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save .e-btn-icon.e-icons,
.e-bigger .e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel .e-btn-icon.e-icons,
.e-inplaceeditor-tip.e-bigger .e-editable-action-buttons .e-btn-save .e-btn-icon.e-icons,
.e-inplaceeditor-tip.e-bigger .e-editable-action-buttons .e-btn-cancel .e-btn-icon.e-icons {
  font-size: 16px;
}

.e-inplaceeditor .e-editable-elements:not(.e-richtexteditor) + .e-editable-loading .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle,
.e-inplaceeditor-tip .e-editable-elements:not(.e-richtexteditor) + .e-editable-loading .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
  stroke-width: inherit;
}

.e-inplaceeditor .e-editable-elements:not(.e-richtexteditor) + .e-editable-loading .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle,
.e-inplaceeditor-tip .e-editable-elements:not(.e-richtexteditor) + .e-editable-loading .e-spinner-pane .e-spinner-inner .e-spin-bootstrap4 .e-path-circle {
  stroke-width: inherit;
}

.e-inplaceeditor .e-editable-loading,
.e-inplaceeditor-tip .e-editable-loading {
  display: none;
  min-height: 100%;
  position: absolute;
  top: 0;
}

.e-inplaceeditor .e-editable-loading.e-show,
.e-inplaceeditor-tip .e-editable-loading.e-show {
  display: block;
}

.e-inplaceeditor .e-editable-loading.e-show:not(.e-rte-spin-wrap) .e-spinner-inner,
.e-inplaceeditor-tip .e-editable-loading.e-show:not(.e-rte-spin-wrap) .e-spinner-inner {
  left: auto;
  right: 5px;
  transform: translateX(-30%) translateY(-50%);
}

.e-inplaceeditor .e-editable-form,
.e-inplaceeditor-tip .e-editable-form {
  width: 100%;
}

.e-inplaceeditor .e-editable-form.e-loading .e-input-group-icon,
.e-inplaceeditor-tip .e-editable-form.e-loading .e-input-group-icon {
  visibility: hidden;
}

.e-inplaceeditor .e-component-group,
.e-inplaceeditor-tip .e-component-group {
  margin-bottom: 4px;
}

.e-inplaceeditor .e-component-group .e-editable-component,
.e-inplaceeditor-tip .e-component-group .e-editable-component {
  min-width: 150px;
  position: relative;
}

.e-inplaceeditor .e-control-overlay,
.e-inplaceeditor-tip .e-control-overlay {
  visibility: hidden;
}

.e-inplaceeditor .e-control-overlay.e-richtexteditor,
.e-inplaceeditor-tip .e-control-overlay.e-richtexteditor {
  opacity: .5;
  pointer-events: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  visibility: visible;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save {
  margin: 0 3px 0 0;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save .e-btn-icon.e-icons {
  font-size: 14px;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel {
  margin: 0 0 0 3px;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel .e-btn-icon.e-icons {
  font-size: 14px;
}

.e-inplaceeditor .e-editable-action-buttons.e-hide,
.e-inplaceeditor-tip .e-editable-action-buttons.e-hide {
  visibility: hidden;
}

.e-inplaceeditor.e-rtl .e-editable-action-buttons .e-btn-save,
.e-inplaceeditor-tip.e-rtl .e-editable-action-buttons .e-btn-save {
  margin: 0 0 0 3px;
}

.e-inplaceeditor.e-rtl .e-editable-action-buttons .e-btn-cancel,
.e-inplaceeditor-tip.e-rtl .e-editable-action-buttons .e-btn-cancel {
  margin: 0 3px 0 0;
}

.e-inplaceeditor.e-rtl .e-editable-loading.e-show:not(.e-rte-spin-wrap) .e-spinner-inner,
.e-inplaceeditor-tip.e-rtl .e-editable-loading.e-show:not(.e-rte-spin-wrap) .e-spinner-inner {
  left: 5px;
  right: auto;
  transform: translateX(30%) translateY(-50%);
}

.e-bigger .e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-wrapper,
.e-inplaceeditor-tip.e-bigger.e-tooltip-wrap .e-tip-content .e-editable-wrapper {
  padding: 16px;
}

.e-bigger .e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-container,
.e-inplaceeditor-tip.e-bigger.e-tooltip-wrap .e-tip-content .e-editable-container {
  padding: 16px;
}

.e-bigger .e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-title + .e-editable-wrapper,
.e-inplaceeditor-tip.e-bigger.e-tooltip-wrap .e-tip-content .e-editable-title + .e-editable-wrapper {
  padding: 16px;
}

.e-bigger .e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-title + .e-editable-container,
.e-inplaceeditor-tip.e-bigger.e-tooltip-wrap .e-tip-content .e-editable-title + .e-editable-container {
  padding: 16px;
}

.e-bigger .e-inplaceeditor-tip .e-editable-title,
.e-inplaceeditor-tip.e-bigger .e-editable-title {
  font-size: 16px;
  min-height: 36px;
  padding: 0 0 0 16px;
}

.e-bigger .e-inplaceeditor-tip.e-rtl.e-tooltip-wrap .e-tip-content .e-editable-title,
.e-inplaceeditor-tip.e-bigger.e-rtl.e-tooltip-wrap .e-tip-content .e-editable-title {
  padding: 0 16px 0 0;
}

.e-inplaceeditor-tip {
  opacity: 1;
  width: auto;
}

.e-inplaceeditor-tip.e-tooltip-wrap {
  max-width: 100%;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-input-group.e-numeric.e-control-wrapper .e-clear-icon.e-clear-icon-hide,
.e-inplaceeditor-tip.e-tooltip-wrap .e-input-group.e-numeric.e-control-container .e-clear-icon.e-clear-icon-hide {
  display: block;
  visibility: hidden;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content {
  padding: 0;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-title {
  -ms-flex-line-pack: center;
      align-content: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: 700;
  height: 31px;
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 0 0 14px;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-wrapper {
  display: -ms-flexbox;
  display: flex;
  padding: 14px;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-wrapper .e-editable-action-buttons {
  float: right;
  margin-top: 4px;
  position: relative;
  right: auto;
  top: auto;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-container {
  display: -ms-flexbox;
  display: flex;
  padding: 14px;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-container .e-editable-action-buttons {
  float: right;
  margin-top: 4px;
  position: relative;
  right: auto;
  top: auto;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-title + .e-editable-wrapper {
  padding: 14px;
}

.e-inplaceeditor-tip.e-tooltip-wrap .e-tip-content .e-editable-title + .e-editable-container {
  padding: 14px;
}

.e-inplaceeditor-tip.e-hide {
  visibility: hidden;
}

.e-inplaceeditor-tip.e-rtl.e-tooltip-wrap .e-tip-content .e-editable-title {
  padding: 0 14px 0 0;
}

.e-inplaceeditor-tip.e-rtl.e-tooltip-wrap .e-tip-content .e-editable-wrapper .e-editable-action-buttons {
  float: left;
}

.e-inplaceeditor-tip.e-rtl.e-tooltip-wrap .e-tip-content .e-editable-container .e-editable-action-buttons {
  float: left;
}

.e-content-placeholder.e-inplaceeditor.e-placeholder-inplaceeditor {
  background-size: 150px 60px;
  min-height: 60px;
}

.e-bigger .e-content-placeholder.e-inplaceeditor.e-placeholder-inplaceeditor,
.e-bigger.e-content-placeholder.e-inplaceeditor.e-placeholder-inplaceeditor {
  background-size: 150px 70px;
  min-height: 70px;
}

/*! inplaceeditor theme */
.e-inplaceeditor .e-editable-value-wrapper .e-editable-value {
  border-bottom: 1px dashed #495057;
  color: #495057;
}

.e-inplaceeditor .e-editable-value-wrapper .e-editable-overlay-icon {
  color: #495057;
}

.e-inplaceeditor .e-editable-value-wrapper.e-loading .e-editable-value {
  border-bottom: 0;
}

.e-inplaceeditor .e-editable-value-wrapper:hover {
  background: #f2f4f6;
  border-radius: 4px;
}

.e-inplaceeditor .e-editable-value-wrapper:hover .e-editable-value {
  border-bottom-color: transparent;
}

.e-inplaceeditor .e-editable-value-container .e-editable-value {
  border-bottom: 1px dashed #495057;
  color: #495057;
}

.e-inplaceeditor .e-editable-value-container .e-editable-overlay-icon {
  color: #495057;
}

.e-inplaceeditor .e-editable-value-container.e-loading .e-editable-value {
  border-bottom: 0;
}

.e-inplaceeditor .e-editable-value-container:hover {
  background: #f2f4f6;
  border-radius: 4px;
}

.e-inplaceeditor .e-editable-value-container:hover .e-editable-value {
  border-bottom-color: transparent;
}

.e-inplaceeditor[data-underline='false'] .e-editable-value-wrapper .e-editable-value {
  border-bottom: 0;
}

.e-inplaceeditor[data-underline='false'] .e-editable-value-container .e-editable-value {
  border-bottom: 0;
}

.e-inplaceeditor.e-disable {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-inplaceeditor.e-disable .e-editable-value-wrapper .e-editable-value {
  border-bottom-color: transparent;
}

.e-inplaceeditor.e-disable .e-editable-value-container .e-editable-value {
  border-bottom-color: transparent;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn {
  background-color: #fff;
  border: 1px solid #ced4da;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.25);
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn .e-btn-icon.e-icons,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn .e-btn-icon.e-icons {
  color: #495057;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn:hover,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn:hover,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn:hover,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn:hover {
  background-color: #5a6268;
  border: 1px solid #545b62;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn:focus,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn:focus,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn:focus,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn:focus {
  background-color: #6c757d;
  border: 1px solid #6c757d;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn:active,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn:active,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn:active,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn:active {
  background-color: #545b62;
  border: 1px solid #4e555b;
}

.e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn:hover .e-btn-icon.e-icons, .e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn:focus .e-btn-icon.e-icons, .e-inplaceeditor .e-editable-action-buttons .e-btn-save.e-icon-btn:active .e-btn-icon.e-icons,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn:hover .e-btn-icon.e-icons,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn:focus .e-btn-icon.e-icons,
.e-inplaceeditor .e-editable-action-buttons .e-btn-cancel.e-icon-btn:active .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn:hover .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn:focus .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-save.e-icon-btn:active .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn:hover .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn:focus .e-btn-icon.e-icons,
.e-inplaceeditor-tip .e-editable-action-buttons .e-btn-cancel.e-icon-btn:active .e-btn-icon.e-icons {
  color: #fff;
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup {
  background: #fff;
  box-shadow: none;
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup .e-tip-content {
  border-radius: 4px;
  color: #495057;
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup .e-arrow-tip-inner {
  color: #fff;
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-top {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup .e-arrow-tip-outer.e-tip-bottom {
  border-top-color: rgba(0, 0, 0, 0.2);
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup.e-popup {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.e-inplaceeditor-tip.e-tooltip-wrap.e-popup .e-editable-title {
  background: #f2f4f6;
  border-bottom: 0;
  color: #212529;
}

.e-inplaceeditor-tip.e-editable-tip-title.e-tooltip-wrap .e-arrow-tip-outer.e-tip-top {
  border-bottom-color: rgba(0, 0, 0, 0.2);
}

.e-inplaceeditor-tip.e-editable-tip-title.e-tooltip-wrap .e-arrow-tip-inner.e-tip-top {
  color: #f2f4f6;
}
