/*! component's theme wise override definitions and variables */
/*! hscroll icons */
.e-hscroll.e-rtl.e-scroll-device .e-nav-right-arrow::before {
  content: '\e70d';
}

.e-hscroll.e-rtl.e-scroll-device .e-nav-left-arrow::before {
  content: '\e76a';
}

.e-hscroll.e-rtl .e-nav-left-arrow::before {
  content: '\e71f';
}

.e-hscroll.e-rtl .e-nav-right-arrow::before {
  content: '\e70b';
}

.e-hscroll.e-scroll-device .e-nav-right-arrow::before {
  content: '\e76a';
}

.e-hscroll.e-scroll-device .e-nav-left-arrow::before {
  content: '\e70d';
}

.e-hscroll .e-nav-left-arrow::before {
  content: '\e71f';
  line-height: normal;
}

.e-hscroll .e-nav-right-arrow::before {
  content: '\e70b';
  line-height: normal;
}

/*! h-scroll layout */
.e-bigger .e-hscroll,
.e-hscroll.e-bigger {
  min-height: 50px;
}

.e-bigger .e-hscroll:not(.e-scroll-device),
.e-hscroll.e-bigger:not(.e-scroll-device) {
  padding: 0 38px;
}

.e-bigger .e-hscroll.e-scroll-device,
.e-hscroll.e-bigger.e-scroll-device {
  padding-right: 38px;
}

.e-bigger .e-hscroll.e-rtl.e-scroll-device,
.e-hscroll.e-bigger.e-rtl.e-scroll-device {
  padding-left: 38px;
  padding-right: initial;
}

.e-bigger .e-hscroll .e-nav-arrow.e-icons,
.e-hscroll.e-bigger .e-nav-arrow.e-icons {
  font-size: 14px;
}

.e-bigger .e-hscroll.e-rtl .e-scroll-overlay.e-scroll-right-overlay,
.e-hscroll.e-bigger.e-rtl .e-scroll-overlay.e-scroll-right-overlay {
  left: 38px;
}

.e-bigger .e-hscroll .e-scroll-overlay.e-scroll-right-overlay,
.e-hscroll.e-bigger .e-scroll-overlay.e-scroll-right-overlay {
  right: 38px;
}

.e-bigger .e-hscroll .e-scroll-nav,
.e-hscroll.e-bigger .e-scroll-nav {
  min-height: 50px;
  width: 38px;
}

.e-hscroll {
  display: block;
  position: relative;
  width: inherit;
}

.e-hscroll.e-rtl.e-scroll-device {
  padding-left: 50px;
  padding-right: initial;
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(-6px);
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-right-overlay {
  left: 52px;
  right: auto;
  transform: skewX(-16deg) translateX(-6px);
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-left-overlay {
  left: auto;
  right: 0;
}

.e-hscroll:not(.e-scroll-device) {
  padding: 0 32px;
}

.e-hscroll.e-scroll-device {
  padding-right: 50px;
}

.e-hscroll.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(6px);
  width: 52px;
}

.e-hscroll.e-scroll-device .e-scroll-nav .e-nav-arrow {
  font-size: 14px;
  transform: skewX(16deg);
}

.e-hscroll.e-scroll-device .e-scroll-overlay {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}

.e-hscroll.e-scroll-device .e-scroll-overlay.e-scroll-right-overlay {
  left: auto;
  right: 52px;
  transform: skewX(-16deg) translateX(6px);
}

.e-hscroll.e-scroll-device .e-scroll-overlay.e-scroll-left-overlay {
  left: 0;
  right: auto;
}

.e-hscroll.e-overlay .e-hscroll-content > * {
  pointer-events: none;
}

.e-hscroll > * {
  height: inherit;
}

.e-hscroll .e-hscroll-content {
  display: inline-block;
  height: inherit;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
}

.e-hscroll .e-hscroll-content > * {
  pointer-events: auto;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-right-nav {
  left: 0;
  right: auto;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-left-nav {
  left: auto;
  right: 0;
}

.e-hscroll .e-scroll-nav {
  -ms-flex-align: center;
      align-items: center;
  bottom: 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 38px;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 32px;
}

.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  left: 0;
}

.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  right: 0;
}

.e-hscroll .e-scroll-nav.e-ie-align {
  display: table;
}

.e-hscroll .e-nav-arrow {
  position: relative;
}

.e-hscroll .e-nav-arrow.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.e-hscroll .e-hscroll-bar .e-hscroll-content .e-overlay {
  pointer-events: none;
}

/*! h-scroll theme */
.e-hscroll .e-scroll-nav .e-icons {
  color: #333;
}

.e-hscroll.e-rtl.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  border-color: #ccc;
  box-shadow: 4px 0 8px 0 rgba(108, 117, 125, 0.06);
}

.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  background-color: #f8f8f8;
  border-color: #ccc;
  border-width: 1px;
  box-shadow: -4px 0 8px 0 rgba(108, 117, 125, 0.06);
}

.e-hscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
  color: #317ab9;
}

.e-hscroll .e-scroll-overlay {
  background-color: transparent;
  background-repeat: repeat-x;
}

.e-hscroll .e-scroll-overlay.e-scroll-left-overlay {
  background-image: linear-gradient(-270deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.e-hscroll .e-scroll-overlay.e-scroll-right-overlay {
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, white 100%);
}

.e-hscroll.e-rtl .e-scroll-nav {
  background: #f8f8f8;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-left-nav {
  border-left: 1px solid #ccc;
  border-right: 0;
}

.e-hscroll.e-rtl .e-scroll-nav.e-scroll-right-nav {
  border-left: 0;
  border-right: 1px solid #ccc;
}

.e-hscroll.e-rtl .e-scroll-nav:hover {
  background: #5a6268;
  border: 1px #545b62;
  color: #fff;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  background: #5a6268;
  border: 1px #545b62;
  color: #fff;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover .e-icons {
  color: inherit;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: #5a6268;
  border: 1px #6c757d;
  color: #fff;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-icons {
  color: inherit;
}

.e-hscroll:not(.e-scroll-device) .e-scroll-nav:active {
  background: #6c757d;
  border: 3px solid #adb5bd;
  box-shadow: none;
  color: #fff;
}

.e-hscroll .e-scroll-nav {
  background: #f8f8f8;
}

.e-hscroll .e-scroll-nav.e-scroll-left-nav {
  border-right: 1px solid #ccc;
}

.e-hscroll .e-scroll-nav.e-scroll-right-nav {
  border-left: 1px solid #ccc;
}

.e-hscroll .e-scroll-nav::after {
  content: '';
}

.e-hscroll .e-scroll-nav:active::after {
  content: '';
}

/*! component's theme wise override definitions and variables */
/*! vscroll icons */
.e-vscroll.e-scroll-device .e-nav-up-arrow::before {
  content: '\e734';
}

.e-vscroll.e-scroll-device .e-nav-down-arrow::before {
  content: '\e705';
}

.e-vscroll .e-nav-up-arrow::before {
  content: '\e734';
  line-height: normal;
}

.e-vscroll .e-nav-down-arrow::before {
  content: '\e705';
  line-height: normal;
}

/*! v-scroll layout */
.e-bigger .e-vscroll:not(.e-scroll-device),
.e-vscroll.e-bigger:not(.e-scroll-device) {
  padding: 50px 0;
}

.e-bigger .e-vscroll .e-icons,
.e-vscroll.e-bigger .e-icons {
  font-size: 18px;
}

.e-bigger .e-vscroll.e-rtl .e-scroll-overlay.e-scroll-down-overlay,
.e-vscroll.e-bigger.e-rtl .e-scroll-overlay.e-scroll-down-overlay {
  left: 50px;
}

.e-bigger .e-vscroll .e-scroll-overlay.e-scroll-down-overlay,
.e-vscroll.e-bigger .e-scroll-overlay.e-scroll-down-overlay {
  right: 50px;
}

.e-bigger .e-vscroll .e-scroll-nav,
.e-vscroll.e-bigger .e-scroll-nav {
  height: 50px;
}

.e-vscroll {
  display: block;
  position: relative;
  width: inherit;
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(-6px);
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-down-overlay {
  left: 52px;
  right: auto;
  transform: skewX(-16deg) translateX(-6px);
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-overlay.e-scroll-up-overlay {
  left: auto;
  right: 0;
}

.e-vscroll:not(.e-scroll-device) {
  padding: 0 40px;
}

.e-vscroll.e-scroll-device .e-scroll-nav {
  transform: skewX(-16deg) translateX(6px);
  width: 52px;
  z-index: 1001;
}

.e-vscroll.e-scroll-device .e-scroll-nav .e-nav-arrow {
  font-size: 14px;
  transform: skewX(16deg);
}

.e-vscroll.e-scroll-device .e-scroll-overlay {
  opacity: 0.5;
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 100;
}

.e-vscroll.e-scroll-device .e-scroll-overlay.e-scroll-down-overlay {
  left: auto;
  right: 52px;
  transform: skewX(-16deg) translateX(6px);
}

.e-vscroll.e-scroll-device .e-scroll-overlay.e-scroll-up-overlay {
  left: 0;
  right: auto;
}

.e-vscroll > * {
  height: inherit;
}

.e-vscroll .e-vscroll-content {
  display: inline-block;
  height: auto;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
  width: 100%;
}

.e-vscroll .e-vscroll-content > * {
  pointer-events: auto;
}

.e-vscroll.e-rtl .e-scroll-nav.e-scroll-up-nav {
  left: auto;
  right: 0;
}

.e-vscroll.e-rtl .e-scroll-nav.e-scroll-down-nav {
  left: 0;
  right: auto;
}

.e-vscroll .e-scroll-nav {
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 40px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.e-vscroll .e-scroll-nav.e-scroll-up-nav {
  top: 0;
}

.e-vscroll .e-scroll-nav.e-scroll-down-nav {
  bottom: 0;
}

.e-vscroll .e-scroll-nav.e-ie-align {
  display: table;
}

.e-vscroll .e-nav-arrow {
  position: relative;
}

.e-vscroll .e-nav-arrow.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

/*! v-scroll theme */
.e-vscroll .e-icons {
  color: #333;
}

.e-vscroll.e-rtl.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  border-color: #ccc;
  box-shadow: 4px 0 8px 0 rgba(108, 117, 125, 0.06);
}

.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav {
  background-color: #f8f8f8;
  border-color: #ccc;
  border-width: 1px;
  box-shadow: -4px 0 8px 0 rgba(108, 117, 125, 0.06);
}

.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-right-nav .e-nav-arrow {
  color: #317ab9;
}

.e-vscroll .e-scroll-overlay {
  background-color: transparent;
  background-repeat: repeat-x;
}

.e-vscroll .e-scroll-overlay.e-scroll-up-overlay {
  background-image: linear-gradient(-270deg, white 0%, rgba(255, 255, 255, 0) 100%);
}

.e-vscroll .e-scroll-overlay.e-scroll-down-overlay {
  background-image: linear-gradient(-270deg, rgba(255, 255, 255, 0) 0%, white 100%);
}

.e-vscroll.e-rtl .e-scroll-nav {
  background: #f8f8f8;
}

.e-vscroll.e-rtl .e-scroll-nav:hover {
  background: #e6e6e6;
  border: 0;
  border-color: transparent;
  color: #fff;
}

.e-vscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  background: #e6e6e6;
  border: 0;
  color: #fff;
}

.e-vscroll:not(.e-scroll-device) .e-scroll-nav:focus {
  background: #e6e6e6;
  border: 0;
  border-color: transparent;
  color: #fff;
}

.e-vscroll:not(.e-scroll-device) .e-scroll-nav:active {
  background: #e6e6e6;
  border: 0;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  color: #fff;
}

.e-vscroll .e-scroll-nav {
  background: #f8f8f8;
}

.e-vscroll .e-scroll-nav.e-scroll-up-nav {
  border-bottom: 1px solid #ccc;
}

.e-vscroll .e-scroll-nav.e-scroll-down-nav {
  border-top: 1px solid #ccc;
}

.e-vscroll .e-scroll-nav::after {
  content: '';
}

.e-vscroll .e-scroll-nav:active::after {
  content: '';
}

/*! component's theme wise override definitions and variables */
/*! component's theme wise override definitions and variables */
/*! toolbar icons */
.e-toolbar .e-popup-down-icon::before {
  content: '\e744';
  line-height: normal;
}

.e-toolbar .e-popup-up-icon::before {
  content: '\e721';
  line-height: normal;
}

/*! toolbar layout */
.e-bigger .e-toolbar,
.e-toolbar.e-bigger {
  height: 54px;
  min-height: 54px;
}

.e-bigger .e-toolbar .e-tbar-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-tbar-btn .e-tbar-btn-text {
  font-size: 16px;
}

.e-bigger .e-toolbar .e-tbar-btn .e-icons,
.e-toolbar.e-bigger .e-tbar-btn .e-icons {
  font-size: 16px;
}

.e-bigger .e-toolbar .e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 16px;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-tbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-tbar-extended {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-tbar-extended .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-tbar-extended .e-toolbar-extended {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.e-bigger .e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child {
  margin: 0;
  margin-left: 12px;
}

.e-bigger .e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-left: 16px;
  margin-right: initial;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-left: 16px;
  margin-right: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 12px;
}

.e-bigger .e-toolbar.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 16px;
}

.e-bigger .e-toolbar .e-hor-nav,
.e-toolbar.e-bigger .e-hor-nav {
  min-height: 54px;
  min-width: 38px;
}

.e-bigger .e-toolbar .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 32px;
}

.e-bigger .e-toolbar .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon {
  line-height: 32px;
  min-height: 32px;
  min-width: 24px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  line-height: inherit;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  min-width: 48px;
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  min-width: 24px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn {
  min-height: 25px;
  padding: 0 20px 0 20px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin-left: 0;
  padding: 0 6px 0 4px;
}

.e-bigger .e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0 4px 0 6px;
}

.e-bigger .e-toolbar .e-toolbar-items,
.e-toolbar.e-bigger .e-toolbar-items {
  min-height: 54px;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow {
  margin-left: 15px;
  margin-right: 15px;
  white-space: normal;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator,
.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-hidden,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-hidden {
  display: none;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-bigger .e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-right: 16px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 16px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 16px;
}

.e-bigger .e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 16px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item {
  min-height: 54px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-width: 0;
  padding: 8px 2.5px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item.e-separator,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item.e-separator {
  height: calc(100% - 12px);
  margin: 1px 4px;
  min-height: 36px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: calc(100% - 6px);
  margin: 0;
  min-height: 38px;
  min-width: 38px;
  padding: 1.5px 5.5px;
  line-height: 34px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover, .e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:active,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control:active {
  padding: 0 4.5px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0 4px 0 6px;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon {
  padding: 0 12px 0 0;
}

.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-bigger .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 0 6px 0 4px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended {
  min-height: 54px;
  padding-bottom: 0;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 0;
  margin-left: -1px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-close,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended.e-popup-close {
  display: none;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-open,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended.e-popup-open {
  display: inline;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended {
  width: inherit;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended {
  box-shadow: none;
  display: inline;
  white-space: normal;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  min-width: 0;
  padding: 8px 2.5px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator {
  display: none;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 54px;
  vertical-align: middle;
  width: auto;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 0 6px 0 4px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: calc(100% - 6px);
  margin: 0;
  min-height: 38px;
  min-width: 38px;
  padding: 1.5px 5.5px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover, .e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:active,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:hover,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:focus,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control:active {
  padding: 0 5.5px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 32px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  height: calc(100% - 12px);
  margin: 1px 4px;
  min-height: 36px;
}

.e-bigger .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon,
.e-toolbar.e-bigger.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon {
  line-height: 32px;
  min-height: 32px;
  min-width: 24px;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-rtl .e-hor-nav,
.e-toolbar.e-bigger.e-extended-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
}

.e-bigger .e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended,
.e-toolbar.e-bigger.e-extended-toolbar.e-rtl .e-toolbar-extended {
  padding-right: 16px;
  margin-left: 0;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator),
.e-toolbar.e-bigger.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-height: 38px;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  height: auto;
  margin: 6px;
  min-height: auto;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  margin: 2px auto;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-bigger .e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-toolbar.e-bigger.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-bigger .e-toolbar.e-vertical .e-hor-nav,
.e-toolbar.e-bigger.e-vertical .e-hor-nav {
  min-height: 40px;
  min-width: 50px;
}

.e-toolbar {
  border-radius: 4px;
  display: block;
  height: 38px;
  min-height: 38px;
  position: relative;
  -ms-touch-action: none;
      touch-action: none;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  white-space: nowrap;
}

.e-toolbar .e-blazor-toolbar-items {
  position: absolute;
  top: -9999px;
  visibility: hidden;
}

.e-toolbar.e-extended-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
}

.e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended {
  padding-right: 12px;
  margin-left: 0;
}

.e-toolbar.e-extended-toolbar.e-rtl .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icon-left {
  padding-left: 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar.e-extended-toolbar.e-extended-toolbar.e-tbar-extended {
  border-bottom: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.e-toolbar.e-extended-toolbar .e-hor-nav.e-ie-align {
  display: table;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  border-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  min-height: 38px;
  padding-bottom: 0;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 0;
  margin-left: -1px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-toolbar-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-close {
  display: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-popup-open {
  display: inline;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended {
  width: inherit;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  box-shadow: none;
  display: inline;
  white-space: normal;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  height: 100%;
  min-width: 30px;
  padding: 3px 4px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator.e-extended-separator {
  display: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 0;
  vertical-align: middle;
  width: auto;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control .e-tbar-btn-text {
  padding: 0 6px 0 4px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn-text {
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-size: 14px;
  line-height: inherit;
  vertical-align: middle;
  width: auto;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn,
.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: calc(100% - 10px);
  margin: 0;
  min-height: 32px;
  min-width: 32px;
  padding: 0 3px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 26px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover, .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active {
  padding: 0 1.5px;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  margin: 5.5px 6px;
  min-height: 26px;
  min-width: 1px;
  vertical-align: middle;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-btn.e-tbar-btn .e-icons.e-btn-icon:not(.e-toolbar-pop) {
  line-height: 26px;
  min-height: 26px;
  min-width: 26px;
  padding: 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-separator:last-of-type {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item:not(.e-separator) {
  height: auto;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 14px;
  vertical-align: middle;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item .e-tbar-btn {
  cursor: pointer;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding: 0 3px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-tbar-btn:first-child {
  display: inline-block;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item > * {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-overflow: ellipsis;
}

.e-toolbar.e-control[class*='e-toolbar'] {
  box-sizing: content-box;
}

.e-toolbar.e-toolpop .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar .e-tbar-btn-text,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn-text {
  display: inline-block;
  padding: 0 6px 0 4px;
}

.e-toolbar.e-hidden,
.e-toolbar .e-toolbar-items .e-toolbar-item.e-hidden {
  display: none;
}

.e-toolbar.e-corner {
  border-radius: 0;
}

.e-toolbar .e-toolbar-pop {
  border-radius: 4px;
  overflow: hidden;
  padding: 5px 0;
  position: absolute;
}

.e-toolbar .e-toolbar-pop.e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  width: auto;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item.e-toolbar-popup.e-hidden {
  display: none;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  min-height: 26px;
  padding: 0 15px 0 15px;
  border: 0;
  border-radius: 0;
  margin: 0;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  padding: 0 4px 0 5px;
  width: auto;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn.e-btn .e-tbar-btn-text {
  padding: 0 5px 0 4px;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon {
  min-width: 26px;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  min-width: 34px;
  padding: 0;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item.e-tbtn-align .e-btn.e-control {
  text-align: center;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item.e-tbtn-align .e-btn.e-control .e-icons.e-btn-icon {
  min-width: 100%;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: auto;
  -ms-flex-pack: center;
      justify-content: center;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item > * {
  height: 100%;
  min-width: 100%;
  text-overflow: ellipsis;
}

.e-toolbar .e-toolbar-pop .e-toolbar-text .e-tbar-btn-text {
  display: none;
}

.e-toolbar .e-toolbar-pop .e-toolpopup {
  text-align: center;
}

.e-toolbar .e-toolbar-popup {
  text-align: center;
}

.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:last-child {
  margin: 0;
  margin-left: 12px;
}

.e-toolbar.e-toolpop.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-left: 12px;
  margin-right: initial;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-toolbar.e-rtl .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-toolbar.e-rtl .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: auto;
  right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  left: 0;
  right: auto;
}

.e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar.e-rtl .e-toolbar-items .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 12px;
}

.e-toolbar.e-rtl .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 12px;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:last-child {
  margin-left: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 12px;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-center .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:last-child {
  margin-left: 12px;
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:first-child {
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-item:last-child {
  margin-left: 12px;
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-item:last-child:last-child,
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-item:last-child:first-child,
.e-toolbar.e-rtl .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:first-child {
  margin-right: 12px;
}

.e-toolbar.e-rtl .e-hor-nav {
  left: 0;
  right: auto;
  border-radius: 4px 0 0 4px;
}

.e-toolbar .e-hor-nav {
  -ms-flex-align: center;
      align-items: center;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  min-height: 38px;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  width: 38px;
}

.e-toolbar .e-hor-nav.e-ie-align {
  display: table;
}

.e-toolbar .e-popup-down-icon.e-icons,
.e-toolbar .e-popup-up-icon.e-icons {
  color: #495057;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
  width: 100%;
}

.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn {
  line-height: 26px;
}

.e-toolbar .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  line-height: 26px;
  min-height: 26px;
}

.e-toolbar .e-toolbar-items {
  border-radius: 4px 0 0 4px;
  display: inline-block;
  height: 100%;
  min-height: 38px;
  vertical-align: middle;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow {
  margin-bottom: 1px;
  margin-left: 15px;
  margin-right: 15px;
  white-space: normal;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-multirow-separator,
.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator.e-hidden {
  display: none;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow .e-toolbar-item.e-separator {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-multirow-pos .e-toolbar-right {
  display: inline;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items.e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar .e-toolbar-items.e-tbar-pos {
  display: block;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  display: table;
  height: 100%;
  top: 0;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right,
.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  position: absolute;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  right: 0;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: 0;
  line-height: 35px;
}

.e-toolbar .e-toolbar-items.e-tbar-pos .e-toolbar-center {
  margin: 0 auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-left,
.e-toolbar .e-toolbar-items .e-toolbar-center,
.e-toolbar .e-toolbar-items .e-toolbar-right {
  display: inline-block;
}

.e-toolbar .e-toolbar-items .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 12px;
}

.e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 12px;
}

.e-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-right .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-left .e-toolbar-item:first-child {
  margin-left: 12px;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-center .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-right .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar .e-toolbar-items:first-child .e-hscroll-bar:first-child .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-toolbar .e-toolbar-items:first-child > .e-toolbar-item:last-child,
.e-toolbar .e-toolbar-items:first-child > .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content > .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-center .e-toolbar-item {
  margin: 0;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item:last-child {
  margin-right: 12px;
}

.e-toolbar .e-toolbar-items .e-hscroll-bar .e-hscroll-content .e-toolbar-right .e-toolbar-item {
  margin: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  cursor: pointer;
  display: -ms-inline-flexbox;
  display: inline-flex;
  min-height: 0;
  vertical-align: middle;
  width: auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  height: inherit;
  min-width: 30px;
  padding: 3px 4px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  margin: 5.5px 6px;
  min-height: 27px;
  min-width: 1px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item input[type='checkbox'] {
  height: auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-popup-text .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  height: calc(100% - 10px);
  margin: 0;
  min-height: 32px;
  min-width: 32px;
  padding: 0 3px;
  line-height: 27px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:focus, .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:active, .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn:hover {
  padding: 0 2px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-btn-icon {
  padding: 0 4px 0 6px;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-tbtn-txt .e-icons.e-icon-right.e-btn-icon {
  padding: 0 12px 0 0;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn .e-icons.e-btn-icon {
  margin: 0;
  min-width: 26px;
  width: auto;
}

.e-toolbar .e-toolbar-items .e-toolbar-item > * {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-overflow: ellipsis;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  height: calc(100% - 15px);
  vertical-align: middle;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator + .e-separator {
  display: none;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator:last-of-type, .e-toolbar .e-toolbar-items .e-toolbar-item.e-separator:first-of-type {
  display: none;
}

.e-toolbar .e-tbar-btn > :first-child {
  display: inline-block;
}

.e-toolbar .e-tbar-btn {
  border: none;
  cursor: pointer;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-size: 14px;
  font-weight: 400;
  overflow: hidden;
  padding: 0 3px;
  text-align: center;
  text-decoration: none;
  text-transform: none;
}

.e-toolbar .e-tbar-btn .e-icons.e-btn-icon {
  font-size: 14px;
  vertical-align: middle;
}

.e-toolbar .e-tbar-btn div {
  vertical-align: middle;
}

.e-toolbar .e-tbar-btn .e-tbar-btn-text {
  display: inline-block;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "sans-serif";
  font-size: 14px;
  line-height: inherit;
  vertical-align: middle;
  width: auto;
}

.e-toolbar.e-vertical {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
}

.e-toolbar.e-vertical.e-rtl.e-tbar-pos .e-toolbar-left {
  bottom: 0;
  top: auto;
}

.e-toolbar.e-vertical.e-rtl.e-tbar-pos .e-toolbar-right {
  bottom: auto;
  top: 0;
}

.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-left,
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-center,
.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  height: auto;
}

.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-left {
  left: auto;
  right: auto;
  top: 0;
}

.e-toolbar.e-vertical .e-toolbar-items.e-tbar-pos .e-toolbar-right {
  bottom: 0;
  left: auto;
  right: auto;
}

.e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
}

.e-toolbar.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin-right: 0;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item {
  display: -ms-flexbox;
  display: flex;
  height: auto;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item:not(.e-separator) {
  min-width: 33px;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  height: auto;
  margin: 6px 5.5px;
  min-height: auto;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn {
  margin: 0;
}

.e-toolbar.e-vertical .e-hor-nav {
  bottom: 0;
  height: auto;
  left: 0;
  min-height: 40px;
  min-width: 50px;
  right: auto;
  top: auto;
  width: auto;
}

/*! toolbar theme */
.e-toolbar {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  box-shadow: none;
}

.e-toolbar.e-vertical .e-hor-nav {
  border: solid #dee2e6;
  border-width: 1px 0 0 0;
}

.e-toolbar.e-vertical.e-rtl .e-hor-nav {
  border: solid #dee2e6;
  border-width: 0 0 1px 0;
}

.e-toolbar.e-vertical .e-toolbar-items .e-toolbar-item.e-separator {
  border-width: 0 0 1px 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-overlay {
  background: #f8f9fa;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-separator {
  border: solid #ced4da;
  border-width: 0 1px 0 0;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
  color: #495057;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn {
  background: #f8f9fa;
  box-shadow: none;
  color: #495057;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-toolbar-item .e-tbar-btn-text {
  color: #495057;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:focus {
  background: #6c757d;
  border-color: #6c757d;
  border-radius: 4px;
  color: #fff;
  border: 1px solid #6c757d;
  box-shadow: 0 0 0 3px rgba(130, 138, 145, 0.5);
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover {
  background: #6c757d;
  border-color: #545b62;
  border-radius: 4px;
  color: #fff;
}

.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active {
  background: #6c757d;
  border-color: #545b62;
  border-radius: 4px;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  color: #fff;
}

.e-toolbar .e-tbar-btn.e-btn .e-icons {
  color: #495057;
}

.e-toolbar .e-toolbar-pop {
  background: #f8f9fa;
  border: 1px solid #dee2e6;
  box-shadow: none;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:hover,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:active,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:focus {
  background: #007bff;
  box-shadow: none;
  color: #fff;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:hover .e-icons,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:active .e-icons,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:active .e-tbar-btn-text,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:focus .e-icons,
.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn:focus .e-tbar-btn-text {
  color: inherit;
}

.e-toolbar .e-toolbar-pop .e-toolbar-item .e-tbar-btn {
  background: #fff;
}

.e-toolbar.e-extended-toolbar .e-toolbar-pop {
  background: #f8f9fa;
}

.e-toolbar.e-toolpop .e-hor-nav.e-nav-active,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) {
  background: #6c757d;
  border: 3px solid #adb5bd;
  box-shadow: none;
}

.e-toolbar.e-toolpop .e-hor-nav.e-nav-active .e-icons,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) .e-icons {
  color: #fff;
}

.e-toolbar.e-toolpop .e-hor-nav.e-nav-active .e-icons:active,
.e-toolbar.e-toolpop .e-hor-nav.e-nav-active:not(.e-expended-nav) .e-icons:active {
  color: inherit;
}

.e-toolbar .e-tbar-btn {
  background: #f8f9fa;
  box-shadow: none;
  color: #495057;
}

.e-toolbar .e-tbar-btn:focus {
  background: #6c757d;
  box-shadow: 0 0 0 3px rgba(130, 138, 145, 0.5);
  border: 1px solid #6c757d;
  margin: 0;
  border-color: #6c757d;
  border-radius: 4px;
  color: #fff;
}

.e-toolbar .e-tbar-btn:hover {
  background: #6c757d;
  border: 1px solid #545b62;
  border-color: #545b62;
  border-radius: 4px;
  color: #fff;
}

.e-toolbar .e-tbar-btn:hover .e-icons,
.e-toolbar .e-tbar-btn:hover .e-tbar-btn-text {
  color: inherit;
}

.e-toolbar .e-toolbar-items {
  background: #f8f9fa;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay {
  background: #f8f9fa;
  opacity: .65;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay .e-tbar-btn-text {
  color: #495057;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-overlay .e-icons {
  color: #495057;
}

.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-icons,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-tbar-btn-text, .e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:focus .e-icons,
.e-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:focus .e-tbar-btn-text {
  color: inherit;
}

.e-toolbar .e-toolbar-items .e-toolbar-item.e-separator {
  border: solid #ced4da;
  border-width: 0 1px 0 0;
}

.e-toolbar.e-rtl .e-hor-nav {
  background: #f8f9fa;
  border: solid #dee2e6;
  border-left: 0;
  border-width: 0 1px 0 0;
}

.e-toolbar.e-rtl .e-hor-nav:not(.e-nav-active):hover {
  background: #6c757d;
  color: #fff;
}

.e-toolbar .e-hor-nav {
  background: #f8f9fa;
  border: solid #dee2e6;
  border-width: 0 0 0 1px;
}

.e-toolbar .e-hor-nav::after {
  content: '';
}

.e-toolbar .e-hor-nav.e-nav-active:active, .e-toolbar .e-hor-nav.e-nav-active:focus, .e-toolbar .e-hor-nav.e-nav-active:hover {
  border-bottom-right-radius: 0;
}

.e-toolbar .e-hor-nav:active {
  border: 3px solid #adb5bd;
  box-shadow: none;
  color: #fff;
  background: #6c757d;
}

.e-toolbar .e-hor-nav:active .e-icons {
  color: inherit;
}

.e-toolbar .e-hor-nav:active::after {
  content: '';
}

.e-toolbar .e-hor-nav:hover {
  background: #6c757d;
  border-left: 0;
  background: #5a6268;
  color: #fff;
}

.e-toolbar .e-hor-nav:hover .e-icons {
  color: inherit;
}

.e-toolbar .e-hor-nav:focus {
  background: #6c757d;
  border-left: 0;
  border-color: transparent;
  color: #fff;
}

.e-toolbar .e-hor-nav:focus .e-icons {
  color: inherit;
}

.e-toolbar .e-tbar-btn:active {
  background: #6c757d;
  border-color: #545b62;
  border-radius: 4px;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  color: #fff;
  background: #545b62;
  border: 1px solid #4e555b;
}

.e-toolbar .e-tbar-btn:active .e-icons,
.e-toolbar .e-tbar-btn:active .e-tbar-btn-text {
  color: inherit;
}

/*! accordion icons */
.e-accordion .e-tgl-collapse-icon::before {
  content: '\e705';
}

.e-accordion .e-tgl-collapse-icon.e-expand-icon {
  transform: rotate(-180deg);
}

/*! accordion layout */
.e-bigger .e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content,
.e-accordion.e-bigger .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 16px;
  line-height: 24px;
  vertical-align: middle;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel {
  font-size: 14px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-acrdn-content .e-accordion,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-acrdn-content .e-accordion {
  border: 0;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 30px 0 45px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header {
  padding: 0 30px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-header .e-acrdn-header-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-header .e-acrdn-header-content {
  font-size: 16px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header {
  line-height: 48px;
  min-height: 50px;
  padding: 0 20px 0 20px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-toggle-icon,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-toggle-icon {
  height: 50px;
  min-height: 50px;
  min-width: 24px;
  right: 15px;
  font-size: 14px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon {
  display: inline-block;
  padding: 0 12px 0 0;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon .e-acrdn-icons.e-icons,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-header .e-acrdn-header-icon .e-acrdn-icons.e-icons {
  font-size: 16px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content {
  padding: 20px;
  font-size: 16px;
  line-height: 24px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 15px 15px 15px 45px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 15px 15px 15px 60px;
}

.e-bigger .e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0 30px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-content {
  padding: 15px 45px 15px 15px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel.e-nested > .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 15px 60px 15px 15px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 45px 0 30px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item.e-select .e-acrdn-panel.e-nested .e-accordion .e-acrdn-item.e-select .e-acrdn-header {
  padding: 0 30px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header {
  padding: 0 15px 0 30px;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  left: 15px;
  right: auto;
}

.e-bigger .e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon,
.e-accordion.e-bigger.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  padding: 0 0 0 12px;
}

.e-accordion {
  display: block;
  position: relative;
}

.e-accordion .e-acrdn-item > .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item.e-selected {
  padding-top: 0;
}

.e-accordion .e-acrdn-item.e-selected > .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item.e-overlay {
  height: auto;
}

.e-accordion .e-acrdn-item {
  overflow: hidden;
  padding-top: 0;
  position: relative;
}

.e-accordion .e-acrdn-item.e-hide {
  display: none;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-size: 14px;
}

.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  cursor: pointer;
}

.e-accordion .e-acrdn-item.e-select > .e-acrdn-header:hover .e-acrdn-header-content, .e-accordion .e-acrdn-item.e-select > .e-acrdn-header:focus .e-acrdn-header-content {
  text-decoration: underline;
}

.e-accordion .e-acrdn-item .e-acrdn-header {
  line-height: 39px;
  min-height: 40px;
  overflow: hidden;
  padding: 0 16px 0 16px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-accordion .e-acrdn-item .e-acrdn-header > * {
  display: inline-block;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  line-height: 1.5;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  display: table;
  font-size: 12px;
  height: 40px;
  min-height: 40px;
  min-width: 24px;
  position: absolute;
  right: 15px;
  top: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-icon .e-tgl-collapse-icon.e-icons {
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-toggle-animation {
  transition: .5s ease 0s;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  display: inline-block;
  padding: 0 8px 0 0;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon .e-acrdn-icons.e-icons {
  font-size: 14px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 14px;
  overflow-y: hidden;
  text-decoration: none;
  width: 100%;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-acrdn-panel.e-nested > .e-acrdn-content {
  padding: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion {
  border: 0;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 30px 0 45px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-item.e-select.e-selected .e-acrdn-header > .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-nested .e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  font-weight: normal;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 15px 15px 15px 45px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 15px 15px 15px 60px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content {
  line-height: 1.5;
  overflow: hidden;
  padding: 16px;
  text-overflow: ellipsis;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content > * {
  overflow: hidden;
  text-overflow: ellipsis;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0 30px;
}

.e-accordion .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header-content {
  font-size: 14px;
}

.e-accordion .e-acrdn-item .e-content-hide {
  display: none;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel.e-nested > .e-acrdn-content .e-accordion .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-header {
  padding: 0 45px 0 30px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel .e-acrdn-content .e-acrdn-header {
  padding: 0 30px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 15px 45px 15px 15px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-panel .e-acrdn-panel .e-acrdn-panel .e-acrdn-content {
  padding: 15px 60px 15px 15px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header {
  padding: 0 15px 0 30px;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-toggle-icon {
  left: 15px;
  right: auto;
}

.e-accordion.e-rtl .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  padding: 0 0 0 8px;
}

/*! accordion theme */
.e-accordion {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px;
}

.e-accordion .e-active {
  background: #fff;
}

.e-accordion .e-acrdn-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 4px 4px 0 0;
}

.e-accordion .e-acrdn-item.e-select:last-child {
  border-bottom: 0;
  border-radius: 0 0 4px 4px;
}

.e-accordion .e-acrdn-item.e-overlay.e-select.e-expand-state .e-acrdn-header .e-icons,
.e-accordion .e-acrdn-item.e-overlay.e-select.e-expand-state .e-acrdn-header .e-acrdn-header-content {
  color: #6c757d;
}

.e-accordion .e-acrdn-item.e-overlay {
  background: #f8f9fa;
  opacity: inherit;
}

.e-accordion .e-acrdn-item.e-overlay .e-acrdn-header .e-icons,
.e-accordion .e-acrdn-item.e-overlay .e-acrdn-header .e-acrdn-header-content {
  color: #6c757d;
}

.e-accordion .e-acrdn-item.e-selected.e-select.e-expand-state > .e-acrdn-header:focus {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-content .e-acrdn-item:last-child {
  border-bottom: 1px none rgba(0, 0, 0, 0.13);
}

.e-accordion .e-acrdn-item .e-acrdn-panel.e-nested .e-acrdn-header .e-acrdn-header-content {
  color: #007bff;
}

.e-accordion .e-acrdn-item.e-select .e-acrdn-panel .e-acrdn-content {
  color: #212529;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.13);
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-content {
  color: #007bff;
}

.e-accordion .e-acrdn-item .e-acrdn-header .e-acrdn-header-icon {
  color: #007bff;
}

.e-accordion .e-acrdn-item.e-expand-state.e-select:not(.e-selected) > .e-acrdn-header:focus {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item .e-acrdn-header:hover {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item .e-acrdn-header:active {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item .e-acrdn-header:focus {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover .e-icons,
.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover .e-acrdn-header-content {
  color: #0056b3;
}

.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover:focus .e-icons,
.e-accordion .e-acrdn-item.e-select.e-acrdn-item > .e-acrdn-header:hover:focus .e-acrdn-header-content {
  color: #007bff;
}

.e-accordion .e-acrdn-item.e-select.e-selected > .e-acrdn-header {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header {
  background: #f8f9fa;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover .e-icons,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover .e-icons,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover .e-acrdn-header-content {
  color: #0056b3;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover:focus .e-icons,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header:hover:focus .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover:focus .e-icons,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header:hover:focus .e-acrdn-header-content {
  color: #007bff;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header > .e-toggle-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header > .e-toggle-icon {
  color: #007bff;
}

.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header .e-acrdn-header-content,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-icon,
.e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header .e-acrdn-header-content {
  color: #007bff;
}

.e-accordion .e-acrdn-item.e-selected.e-select {
  border-bottom: 1px none #000;
  border-top: 1px none #000;
  border-bottom: 1px solid rgba(0, 0, 0, 0.13);
}

.e-accordion .e-acrdn-item.e-selected.e-select.e-select:last-child {
  border-bottom: 1px none rgba(0, 0, 0, 0.13);
}

.e-accordion .e-acrdn-item.e-selected + .e-selected {
  border-top: 1px none #000;
}

.e-accordion .e-acrdn-item.e-selected .e-selected:last-child {
  border-bottom: 1px none #000;
}

.e-accordion .e-acrdn-item.e-selected.e-select > .e-acrdn-header {
  border-radius: 4px;
}

.e-accordion .e-acrdn-item.e-selected > .e-acrdn-panel .e-acrdn-content {
  color: #212529;
}

.e-accordion .e-acrdn-item.e-selected > .e-acrdn-panel .e-acrdn-header-content {
  color: #007bff;
}

.e-accordion .e-acrdn-item .e-toggle-icon {
  color: #007bff;
}

.e-accordion .e-acrdn-item .e-acrdn-panel {
  font-size: 14px;
}

/*! contextmenu layout */
.e-contextmenu-wrapper ul .e-menu-item .e-previous::before,
.e-contextmenu-container ul .e-menu-item .e-previous::before {
  content: '\e728';
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret::before,
.e-contextmenu-container ul .e-menu-item .e-caret::before {
  content: '\e70b';
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret::before,
.e-rtl.e-contextmenu-container .e-menu-item .e-caret::before {
  content: '\e71f';
}

/*! contextmenu layout */
.e-contextmenu-wrapper ul,
.e-contextmenu-container ul {
  font-weight: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 14px;
  padding: 0;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  display: none;
  min-width: 120px;
  position: absolute;
}

.e-contextmenu-wrapper ul.e-ul,
.e-contextmenu-wrapper ul.e-ul *,
.e-contextmenu-container ul.e-ul,
.e-contextmenu-container ul.e-ul * {
  box-sizing: border-box;
}

.e-contextmenu-wrapper ul.e-ul:focus,
.e-contextmenu-wrapper ul.e-ul *:focus,
.e-contextmenu-container ul.e-ul:focus,
.e-contextmenu-container ul.e-ul *:focus {
  outline: none;
}

.e-contextmenu-wrapper ul.e-contextmenu,
.e-contextmenu-container ul.e-contextmenu {
  box-shadow: none;
}

.e-contextmenu-wrapper ul.e-ul,
.e-contextmenu-container ul.e-ul {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
}

.e-contextmenu-wrapper ul .e-menu-item,
.e-contextmenu-container ul .e-menu-item {
  cursor: pointer;
  position: relative;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-hide,
.e-contextmenu-container ul .e-menu-item.e-menu-hide {
  display: none;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-header,
.e-contextmenu-container ul .e-menu-item.e-menu-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-url,
.e-contextmenu-container ul .e-menu-item .e-menu-url {
  text-decoration: none;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  display: inline-block;
  vertical-align: middle;
}

.e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-contextmenu-container ul .e-menu-item.e-separator {
  cursor: auto;
  line-height: normal;
  pointer-events: none;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-url,
.e-contextmenu-container ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  font-size: 14px;
  line-height: 26px;
  margin-right: 8px;
  width: 1em;
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-contextmenu-container ul .e-menu-item .e-caret {
  line-height: 26px;
  margin-left: 16px;
  margin-right: 0;
  position: absolute;
  right: 14px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-contextmenu-container ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-contextmenu-container ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
  margin: 6px 0;
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-contextmenu-container ul .e-menu-item .e-caret {
  font-size: 9px;
}

.e-contextmenu-wrapper ul .e-menu-item .e-previous,
.e-contextmenu-container ul .e-menu-item .e-previous {
  margin-right: 12px;
}

.e-contextmenu-wrapper ul .e-menu-item.e-disabled,
.e-contextmenu-container ul .e-menu-item.e-disabled {
  cursor: auto;
  pointer-events: none;
}

.e-contextmenu-wrapper.e-sfcontextmenu,
.e-contextmenu-container.e-sfcontextmenu {
  position: absolute;
}

.e-contextmenu-wrapper.e-sfcontextmenu .e-menu-vscroll,
.e-contextmenu-container.e-sfcontextmenu .e-menu-vscroll {
  position: absolute;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul,
.e-contextmenu-container.e-sfcontextmenu ul {
  white-space: nowrap;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent,
.e-contextmenu-container.e-sfcontextmenu ul.e-transparent {
  background: transparent;
  border: 0;
  box-shadow: none;
  height: 1px;
  left: 0;
  min-width: 0;
  padding: 0;
  top: 0;
  width: 0;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent .e-menu-item,
.e-contextmenu-container.e-sfcontextmenu ul.e-transparent .e-menu-item {
  height: 0;
  padding: 0;
}

.e-contextmenu-wrapper.e-sfcontextmenu ul.e-transparent .e-menu-item.e-separator,
.e-contextmenu-container.e-sfcontextmenu ul.e-transparent .e-menu-item.e-separator {
  border: 0;
  margin: 0;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-menu-icon,
.e-rtl.e-contextmenu-container .e-menu-item .e-menu-icon {
  margin-right: 0;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret,
.e-rtl.e-contextmenu-container .e-menu-item .e-caret {
  margin-left: 0;
  margin-right: 16px;
  right: auto;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-menu-icon,
.e-rtl.e-contextmenu-container .e-menu-item .e-menu-icon {
  margin-left: 8px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item .e-caret,
.e-rtl.e-contextmenu-container .e-menu-item .e-caret {
  left: 14px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item.e-menu-caret-icon,
.e-rtl.e-contextmenu-container .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 20px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item.e-blankicon,
.e-rtl.e-contextmenu-container .e-menu-item.e-blankicon {
  padding-left: 14px;
}

.e-rtl.e-contextmenu-wrapper .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-rtl.e-contextmenu-container .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-bigger .e-contextmenu-wrapper ul,
.e-bigger.e-contextmenu-wrapper ul,
.e-bigger .e-contextmenu-container ul,
.e-bigger.e-contextmenu-container ul {
  font-size: 16px;
  padding: 8px 0;
  white-space: nowrap;
  box-shadow: none;
  max-width: 280px;
  min-width: 112px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item,
.e-bigger .e-contextmenu-container ul .e-menu-item,
.e-bigger.e-contextmenu-container ul .e-menu-item {
  height: 32px;
  line-height: 32px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger .e-contextmenu-container ul .e-menu-item .e-menu-icon,
.e-bigger.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  font-size: 16px;
  line-height: 32px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-contextmenu-container ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-container ul .e-menu-item .e-caret {
  line-height: 32px;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item.e-separator,
.e-bigger .e-contextmenu-container ul .e-menu-item.e-separator,
.e-bigger.e-contextmenu-container ul .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-bigger .e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-contextmenu-container ul .e-menu-item .e-caret,
.e-bigger.e-contextmenu-container ul .e-menu-item .e-caret {
  font-size: 12px;
}

.e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon,
.e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon,
.e-bigger .e-contextmenu-container.e-rtl ul .e-blankicon,
.e-bigger.e-contextmenu-container.e-rtl ul .e-blankicon {
  padding-left: 14px;
}

.e-bigger .e-contextmenu-wrapper.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger.e-contextmenu-wrapper.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger .e-contextmenu-container.e-rtl ul .e-blankicon.e-menu-caret-icon,
.e-bigger.e-contextmenu-container.e-rtl ul .e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

/*! contextmenu theme */
.e-contextmenu-wrapper ul,
.e-contextmenu-container ul {
  background-color: #fff;
  color: #212529;
}

.e-contextmenu-wrapper ul .e-menu-item.e-menu-header,
.e-contextmenu-container ul .e-menu-item.e-menu-header {
  border-bottom-color: #e9ecef;
}

.e-contextmenu-wrapper ul .e-menu-item .e-caret,
.e-contextmenu-container ul .e-menu-item .e-caret {
  color: #212529;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-icon,
.e-contextmenu-container ul .e-menu-item .e-menu-icon {
  color: #495057;
}

.e-contextmenu-wrapper ul .e-menu-item .e-menu-url,
.e-contextmenu-container ul .e-menu-item .e-menu-url {
  color: #212529;
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused,
.e-contextmenu-container ul .e-menu-item.e-focused {
  background-color: #f2f4f6;
  color: #212529;
  outline: 0 solid #e9ecef;
  outline-offset: 0;
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused .e-caret,
.e-contextmenu-container ul .e-menu-item.e-focused .e-caret {
  color: #212529;
}

.e-contextmenu-wrapper ul .e-menu-item.e-focused .e-menu-icon,
.e-contextmenu-container ul .e-menu-item.e-focused .e-menu-icon {
  color: #495057;
}

.e-contextmenu-wrapper ul .e-menu-item.e-selected,
.e-contextmenu-container ul .e-menu-item.e-selected {
  background-color: #007bff;
  color: #fff;
  outline: 0 solid #007bff;
  outline-offset: 0;
}

.e-contextmenu-wrapper ul .e-menu-item.e-selected .e-caret,
.e-contextmenu-container ul .e-menu-item.e-selected .e-caret {
  color: #fff;
}

.e-contextmenu-wrapper ul .e-menu-item.e-selected .e-menu-icon,
.e-contextmenu-container ul .e-menu-item.e-selected .e-menu-icon {
  color: #fff;
}

.e-contextmenu-wrapper ul .e-disabled,
.e-contextmenu-container ul .e-disabled {
  color: #6c757d;
  opacity: 1;
}

.e-contextmenu-wrapper ul .e-disabled .e-menu-icon,
.e-contextmenu-container ul .e-disabled .e-menu-icon {
  color: #6c757d;
}

.e-contextmenu-wrapper ul .e-disabled .e-caret,
.e-contextmenu-container ul .e-disabled .e-caret {
  color: #6c757d;
}

.e-contextmenu-wrapper ul .e-disabled .e-menu-url,
.e-contextmenu-container ul .e-disabled .e-menu-url {
  color: #6c757d;
}

.e-contextmenu-wrapper ul .e-separator,
.e-contextmenu-container ul .e-separator {
  border-bottom-color: #e9ecef;
}

/*! Horizontal Tab */
/*! Bootstrap specific themes definition's */
/*! Vertical Tab */
/*! tab icons */
.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-rtl .e-nav-left-arrow::before {
  content: '\e70b';
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-rtl .e-nav-right-arrow::before {
  content: '\e71f';
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before {
  content: '\e71f';
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  content: '\e70b';
}

.e-tab .e-tab-header .e-close-icon::before {
  content: '\e745';
  position: relative;
}

.e-tab .e-tab-header .e-popup-up-icon::before {
  content: '\e721';
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-popup-up-icon::before {
    content: '\e781';
  }
}

.e-tab .e-tab-header .e-popup-down-icon::before {
  content: '\e744';
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-popup-down-icon::before {
    content: '\e781';
  }
}

@media screen and (max-width: 480px) {
  .e-tab.e-vertical-icon .e-tab-header .e-popup-up-icon::before {
    content: '\e721';
  }
  .e-tab.e-vertical-icon .e-tab-header .e-popup-down-icon::before {
    content: '\e744';
  }
}

.e-tab.e-vertical-tab .e-tab-header .e-scroll-nav .e-nav-up-arrow::before {
  content: '\e734';
}

.e-tab.e-vertical-tab .e-tab-header .e-scroll-nav .e-nav-down-arrow::before {
  content: '\e705';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-up-icon::before {
  content: 'More';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-up-icon::after {
  content: '\e721';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-down-icon::before {
  content: 'More';
}

.e-tab.e-vertical-tab .e-tab-header .e-popup-down-icon::after {
  content: '\e744';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-up-icon::before {
  content: '';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-up-icon::after {
  content: '\e721';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-down-icon::before {
  content: '';
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header .e-popup-down-icon::after {
  content: '\e744';
}

.e-tab-clone-element .e-close-icon::before {
  content: '\e745';
  position: relative;
}

/*! tab layout */
.e-bigger .e-tab .e-tab-header,
.e-tab.e-bigger .e-tab-header {
  height: 42px;
  min-height: 42px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items,
.e-tab.e-bigger .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: auto;
  height: 42px;
  min-height: 42px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items .e-indicator + .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-items .e-indicator + .e-toolbar-item:last-child.e-active {
  margin: 1px 2px 0 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 2px 0 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child,
  .e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0 2px 0 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav,
.e-tab.e-bigger .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 50px;
  border-radius: 4px;
  width: 38px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 42px;
  margin: 0 2px 0 0;
  min-height: 42px;
  min-width: auto;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator) {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator).e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 42px;
  padding: 0 16px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 15px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-text {
  font-size: 16px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active {
  margin: 0 2px 0 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft:not(.e-icon) .e-tab-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft:not(.e-icon) .e-tab-icon::before {
  position: relative;
  top: -.5px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
  margin-top: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap {
  padding: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
  cursor: pointer;
  margin: 0 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
    margin: 0 0 0 8px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-close-icon::before {
  font-size: 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-close-icon,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-close-icon::before {
    font-size: 12px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-tab-icon::before {
  font-size: 16px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
  padding: 0 20px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom {
  height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
  right: 20px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
  margin: 10px 0 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0 0 10px;
}

.e-bigger .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 22px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item {
  height: 40px;
  min-height: 40px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
  height: 40px;
  padding: 0 24px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
    padding: 0 24px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap {
  height: 40px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'],
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
  padding-right: 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'],
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
    padding-right: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before {
  top: 0;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  right: 24px;
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  min-height: 50px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
    min-height: 50px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 50px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 50px;
  }
}

.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 0;
  padding-right: 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-bigger .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-bigger .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav {
  height: 42px;
  min-height: 42px;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-scroll-nav,
  .e-tab.e-bigger .e-tab-header .e-scroll-nav {
    padding: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0;
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-scroll-left-nav {
  padding: 0;
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 38px;
  line-height: 38px;
  width: 38px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
  .e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-arrow {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}

.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-bigger .e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-bigger .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 12px;
  line-height: 38px;
  top: 0;
  vertical-align: initial;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav,
.e-tab.e-bigger .e-tab-header .e-hor-nav {
  height: 42px;
  min-height: 42px;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav,
  .e-tab.e-bigger .e-tab-header .e-hor-nav {
    padding: 0;
  }
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon {
  font-size: 12px;
  height: 38px;
  line-height: 38px;
  width: 38px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon::before {
  font-size: 12px;
  line-height: 38px;
  top: 0;
  vertical-align: initial;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 16px;
  }
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  line-height: 38px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
  top: 0;
  line-height: 38px;
  top: .5px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  line-height: 38px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
  top: 0;
  line-height: 38px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-bigger .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab.e-bigger .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover {
  line-height: 38px;
  top: -2px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}

.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 15px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0 2px 0 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0 2px 0 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active,
  .e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-text {
  padding-top: 1px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-close-icon::before {
  top: .5px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-text-wrap {
  height: initial;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-close-icon::before,
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-close-icon::before {
  top: 1.5px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child {
  margin: 0 2px 0 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child,
  .e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-tab-text,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-tab-text {
  padding-top: .5px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-close-icon::before,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active:last-child .e-close-icon::before {
  top: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop, .e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom {
  padding-bottom: 0;
  padding-top: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ileft.e-active .e-text-wrap {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
  padding: 0;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-itop .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active.e-ibottom .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap,
.e-bigger .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap,
.e-tab.e-bigger .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap {
  height: initial;
}

.e-bigger .e-tab .e-tab-header.e-vertical,
.e-tab.e-bigger .e-tab-header.e-vertical {
  max-width: 150px;
}

.e-bigger .e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before,
.e-tab.e-bigger .e-tab-header.e-vertical[style*='overflow: hidden']::before {
  bottom: 23px;
  top: 23px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before,
  .e-tab.e-bigger .e-tab-header.e-vertical[style*='overflow: hidden']::before {
    bottom: 0;
    top: 0;
  }
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device),
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
  padding: 24px 0;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap {
  padding: 0 16px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  right: -20px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-scroll-nav,
.e-tab.e-bigger .e-tab-header.e-vertical .e-scroll-nav {
  height: 24px;
  min-height: 24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow,
.e-tab.e-bigger .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  font-size: 12px;
  height: 24px;
  line-height: 24px;
  width: 24px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 16px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  line-height: 36px;
}

.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-bigger .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-bigger .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  font-size: 16px;
  line-height: 36px;
  margin: 0 0 0 8px;
}

.e-bigger .e-tab .e-tab-header.e-vertical.e-toolpop .e-toolbar-items,
.e-tab.e-bigger .e-tab-header.e-vertical.e-toolpop .e-toolbar-items {
  height: auto;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header,
.e-tab.e-bigger.e-vertical-icon .e-tab-header {
  height: 72px;
  min-height: 72px;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header .e-toolbar-items,
.e-tab.e-bigger.e-vertical-icon .e-tab-header .e-toolbar-items {
  height: 72px;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header .e-scroll-nav,
.e-tab.e-bigger.e-vertical-icon .e-tab-header .e-scroll-nav {
  height: 72px;
}

.e-bigger .e-tab.e-vertical-icon .e-tab-header .e-hor-nav,
.e-tab.e-bigger.e-vertical-icon .e-tab-header .e-hor-nav {
  height: 72px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-bigger .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-down-icon::before,
  .e-tab.e-bigger.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-bigger.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 12px;
  }
}

.e-bigger .e-tab.e-vertical-icon.e-rtl .e-toolbar-item.e-active,
.e-tab.e-bigger.e-vertical-icon.e-rtl .e-toolbar-item.e-active {
  margin: 0 0 0 2px;
}

.e-bigger .e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items,
.e-tab.e-bigger.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-bigger .e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-scroll-nav,
.e-tab.e-bigger.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-scroll-nav {
  height: 24px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 42px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 72px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus {
  height: 40px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 40px;
}

.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-bigger .e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 42px;
}

.e-bigger .e-tab.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-focused .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
  padding: 0;
}

.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}

.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 15px;
}

.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  line-height: 15px;
}

.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-bigger .e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-bigger.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  line-height: 13px;
}

.e-bigger .e-tab.e-safari .e-tab-header .e-close-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-close-icon::before {
  top: 0;
}

.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before {
  top: 0;
}

.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-bigger .e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-bigger.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  top: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-active,
.e-tab.e-bigger.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-active {
  margin: 0 0 0 2px;
}

.e-bigger .e-tab.e-fill .e-tab-header,
.e-tab.e-bigger.e-fill .e-tab-header {
  height: 42px;
  min-height: 42px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header,
  .e-tab.e-bigger.e-fill .e-tab-header {
    height: 42px;
    min-height: 42px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: 42px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items {
    min-height: 42px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 42px;
  margin: 0 2px 0 0;
  min-height: 42px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator),
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 42px;
  padding: 0 20px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 42px;
    padding: 0 20px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 42px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 42px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 42px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 42px;
  }
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-bigger .e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-bigger.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  height: auto;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 16px;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 50px;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-bigger .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: 50px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 42px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 72px;
}

.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-bigger .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-bigger.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
    height: auto;
  }
}

.e-bigger .e-tab.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}

.e-bigger .e-tab.e-fill.e-vertical-icon .e-tab-header,
.e-tab.e-bigger.e-fill.e-vertical-icon .e-tab-header {
  height: 70px;
  min-height: 70px;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 2px 0 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  padding: 0 20px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap,
  .e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 20px;
  }
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-bigger .e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-bigger.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap,
.e-tab.e-bigger.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 16px;
}

.e-bigger .e-tab.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
  margin: 0 10px 0 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
    margin: 0 10px 0 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
  margin: 0 10px 0 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
    margin: 0 8px 0 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: 20px;
  right: auto;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-bigger .e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap,
.e-tab.e-bigger.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-bigger.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-left: 22px;
  margin-right: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  padding-left: 12px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
    padding-left: 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  left: 24px;
  right: auto;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 12px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-bigger .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-bigger.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 0;
    padding-right: 0;
  }
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-hor-nav,
.e-bigger .e-tab.e-rtl .e-tab-header .e-scroll-right-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-hor-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-scroll-right-nav {
  padding: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header .e-scroll-left-nav,
.e-tab.e-bigger.e-rtl .e-tab-header .e-scroll-left-nav {
  padding: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator),
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  left: -20px;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 16px;
}

.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-popup-up-icon::after,
.e-bigger .e-tab.e-rtl .e-tab-header.e-vertical .e-popup-down-icon::after,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-popup-up-icon::after,
.e-tab.e-bigger.e-rtl .e-tab-header.e-vertical .e-popup-down-icon::after {
  margin: 0 8px 0 0;
}

.e-tab {
  display: block;
  position: relative;
}

.e-tab.e-hidden {
  display: none;
}

.e-tab.e-fill-mode .e-content,
.e-tab.e-fill-mode .e-content .e-item,
.e-tab.e-fill-mode .e-content .e-item > div,
.e-tab.e-fill-mode .e-content .e-item > div > .e-blazor-template {
  height: 100%;
}

.e-tab .e-tab-header {
  height: 32px;
  min-height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header {
    height: 42px;
    min-height: 42px;
  }
}

.e-tab .e-tab-header::before {
  content: '';
  position: absolute;
  display: block;
}

.e-tab .e-tab-header:not(.e-vertical)::before {
  bottom: 0;
  top: 0;
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-items {
  height: auto;
  margin: 0;
  min-height: 32px;
  position: relative;
  height: 32px;
  min-height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items {
    min-height: 42px;
    height: 42px;
  }
}

.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-scroll-device {
  padding: 0;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll.e-scroll-device .e-scroll-right-nav {
  display: none;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll[style*='overflow: hidden'] .e-toolbar-item:not(.e-separator).e-active {
  margin-bottom: 0;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll .e-scroll-nav {
  width: 40px;
  border-radius: 4px;
  width: 32px;
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 2px 0 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}

.e-tab .e-tab-header .e-toolbar-items.e-hscroll:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0 2px 0 0;
}

.e-tab .e-tab-header .e-hscroll-bar {
  overflow: hidden;
}

.e-tab .e-tab-header .e-indicator {
  display: none;
  position: absolute;
}

.e-tab .e-tab-header .e-indicator.e-hidden {
  display: none;
}

.e-tab .e-tab-header:not(.e-vertical) .e-indicator {
  bottom: 0;
  height: 2px;
  left: 0;
  right: 0;
  transition: left 0.125s cubic-bezier(0.35, 0, 0.25, 1), right 0.25s cubic-bezier(0.35, 0, 0.25, 1);
}

.e-tab .e-tab-header .e-toolbar-item.e-hidden {
  display: none;
}

.e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 32px;
  margin: 0 2px 0 0;
  min-height: 32px;
  min-width: auto;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 32px;
  padding: 0 12px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 42px;
    padding: 0 15px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 42px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  display: inherit;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-text {
    font-size: 16px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
  margin: 0 2px 0 0;
  padding-bottom: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
  margin-top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover {
  border: 0;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 400;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon::before {
  top: -1px;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 32px;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 42px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
  display: none;
  margin: 0 0 0 6px;
  min-width: 12px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icons.e-close-icon {
    margin: 0 0 0 8px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon,
.e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
  font-size: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-close-icon,
  .e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
    font-size: 12px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon::before {
  top: 0;
}

.e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 20px;
  min-width: 20px;
  width: 20px;
  line-height: 20px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icons.e-tab-icon {
    height: 20px;
    width: 20px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-icon,
  .e-tab .e-tab-header .e-toolbar-item .e-tab-icon::before {
    font-size: 20px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 15px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-icon .e-tab-wrap {
    padding: 0 16px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
  margin: 0 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-item.e-ibottom {
  height: 62px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-item.e-ibottom {
    height: 72px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  position: relative;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 72px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-text-wrap {
  display: block;
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap .e-text-wrap, .e-tab .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap .e-text-wrap {
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-close-icon {
    right: 12px;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
  margin: 8px 0 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-itop .e-tab-text {
    margin: 10px 0 0;
  }
}

.e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0 0 10px;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-icon.e-icon-top,
.e-tab .e-tab-header .e-toolbar-item .e-tab-icon.e-icon-bottom {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  margin: auto;
}

.e-tab .e-tab-header .e-toolbar-pop {
  overflow-y: auto;
  padding: 5px 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item {
  height: 26px;
  min-height: 26px;
  min-width: auto;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item {
    height: 50px;
    min-height: 50px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
  height: 26px;
  padding: 0 20px;
  text-align: initial;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap {
    height: 50px;
    padding: 0 24px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-text-wrap {
  height: 26px;
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  display: -ms-inline-flexbox;
  display: inline-flex;
  width: 100%;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  margin: 0 0 0 8px;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon::before {
  top: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
  padding-right: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text + .e-close-icon[style='display:block'] {
    padding-right: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom {
  height: 40px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom {
    height: 50px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  min-height: 40px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator), .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
    min-height: 50px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 40px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap {
    height: 50px;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-text-wrap {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: auto;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  display: block;
  -ms-flex-pack: center;
      justify-content: center;
  margin: 0 0 0 10px;
  padding-left: 0;
  padding-right: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    -ms-flex-pack: center;
        justify-content: center;
    margin: 0 0 0 12px;
    padding-left: 0;
    padding-right: 0;
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  right: 20px;
  top: initial;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-icon.e-icon-top,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-icon.e-icon-bottom {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-tab .e-tab-header.e-close-show .e-icons.e-close-icon {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-close-show .e-icons.e-close-icon {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-close-show .e-toolbar-item.e-active .e-close-icon {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.e-tab .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 20px;
}

.e-tab .e-tab-header .e-scroll-nav {
  height: 32px;
  min-height: 32px;
  min-width: auto;
  width: auto;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav {
    height: 42px;
    min-height: 42px;
  }
}

.e-tab .e-tab-header .e-scroll-nav.e-scroll-left-nav {
  padding: 0;
}

.e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav.e-scroll-right-nav {
    padding: 0;
  }
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  font-size: 10px;
  line-height: 32px;
  position: relative;
  top: 0;
  vertical-align: initial;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
  height: 32px;
  line-height: 32px;
  width: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-scroll-nav .e-nav-arrow {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}

.e-tab .e-tab-header .e-hor-nav {
  height: 32px;
  min-height: 32px;
  min-width: auto;
  padding: 0;
  width: auto;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav {
    height: 42px;
    min-height: 42px;
    padding: 0;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
  font-size: 10px;
  height: 32px;
  line-height: 32px;
  width: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
    font-size: 12px;
    height: 38px;
    line-height: 38px;
    width: 38px;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before {
  font-size: 10px;
  line-height: 32px;
  position: relative;
  top: 0;
  vertical-align: initial;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 16px;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  line-height: 32px;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
  top: 0;
  line-height: 32px;
  top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover::before,
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover::before {
    line-height: 43px;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-up-icon {
    transform: none;
    transition: none;
  }
}

.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav .e-popup-down-icon {
    transform: none;
    transition: none;
  }
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  line-height: 32px;
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
  top: 0;
  line-height: 32px;
  top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon::before,
  .e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon::before {
    line-height: 43px;
  }
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover {
  line-height: 32px;
  top: -.5px;
}

.e-tab .e-tab-header.e-horizontal-bottom::before {
  bottom: auto;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-hscroll-bar {
  margin-top: -1px;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 2px 0 0;
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
    margin: 0 2px 0 0;
  }
}

.e-tab .e-tab-header.e-horizontal-bottom .e-indicator {
  bottom: auto;
  top: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0 2px 0 0;
  padding-bottom: 0;
  padding-top: 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
    margin: 0 2px 0 0;
  }
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
  padding: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: initial;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap {
  height: initial;
  padding: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-text-wrap::before, .e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-text-wrap::before {
  bottom: auto;
  top: 0;
}

.e-tab .e-tab-header.e-reorder-active-item .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap::before {
  display: none;
}

.e-tab .e-tab-header.e-vertical {
  max-width: 150px;
  z-index: 1;
}

.e-tab .e-tab-header.e-vertical::before {
  bottom: 0;
  height: 100%;
  left: 0;
  top: 0;
}

.e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before {
  bottom: 15px;
  height: auto;
  top: 15px;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-vertical[style*='overflow: hidden']::before {
    bottom: 0;
    top: 0;
  }
}

.e-tab .e-tab-header.e-vertical .e-indicator {
  display: none;
  transition: top 0.125s cubic-bezier(0.35, 0, 0.25, 1), bottom 0.25s cubic-bezier(0.35, 0, 0.25, 1);
  width: 2px;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-items.e-vscroll:not(.e-scroll-device) {
  padding: 16px 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-wrap {
  padding: 0 12px;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-text-wrap {
  position: relative;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-text,
.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-icon::before {
  text-align: center;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item .e-tab-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-active .e-text-wrap::before {
  display: none;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-itop .e-close-icon, .e-tab .e-tab-header.e-vertical .e-toolbar-item.e-ibottom .e-close-icon {
  right: -15px;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-pop {
  top: initial !important;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left {
  float: left;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left::before {
  right: 0;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left .e-indicator {
  left: auto;
  right: 0;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right {
  float: right;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right::before {
  right: auto;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-indicator {
  left: 0;
  right: auto;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-tab-wrap {
  text-align: right;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-pop .e-tab-text {
  width: auto;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav {
  height: 16px;
  -ms-flex-pack: center;
      justify-content: center;
  min-height: 16px;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
  height: 16px;
  line-height: 16px;
  margin: 0 auto;
  width: 16px;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 12px;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  height: 100%;
  transform: none;
  transition: none;
  width: 100%;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  float: left;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  text-align: left;
  text-transform: capitalize;
  transform: none;
  transition: none;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  float: left;
  font-size: 12px;
  line-height: 26px;
  margin: 0 0 0 8px;
  vertical-align: initial;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
  .e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
    font-size: 16px;
  }
}

.e-tab .e-tab-header.e-vertical .e-scroll-device .e-scroll-nav,
.e-tab .e-tab-header.e-vertical .e-scroll-device .e-scroll-overlay {
  display: none;
}

.e-tab .e-tab-header.e-vertical.e-toolpop .e-toolbar-items {
  height: auto;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-rtl .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: -15px;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav {
  padding: 0 12px;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  float: right;
}

.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-rtl .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  float: right;
  margin: 0 8px 0 0;
}

.e-tab .e-content {
  position: relative;
}

.e-tab .e-content .e-item.e-view {
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
}

.e-tab .e-content > .e-item {
  display: none;
}

.e-tab .e-content > .e-item.e-active {
  background: inherit;
  display: block;
}

.e-tab .e-content.e-progress {
  overflow-x: hidden;
}

.e-tab.e-vertical-tab .e-content {
  display: -ms-flexbox;
  display: flex;
}

.e-tab.e-vertical-tab .e-content .e-item.e-active {
  display: -ms-flexbox;
  display: flex;
  -ms-flex: none;
      flex: none;
}

.e-tab.e-vertical-tab .e-content .e-item,
.e-tab.e-vertical-tab .e-content .e-item > :first-child {
  width: 100%;
}

.e-tab.e-vertical-icon .e-tab-header {
  height: 62px;
  min-height: 62px;
}

.e-tab.e-vertical-icon .e-tab-header.e-reorder-active-item .e-toolbar-pop .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon .e-tab-header.e-reorder-active-item .e-toolbar-pop .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  display: none;
}

.e-tab.e-vertical-icon .e-tab-header .e-toolbar-items {
  height: 62px;
}

.e-tab.e-vertical-icon .e-tab-header .e-scroll-nav {
  height: 62px;
}

.e-tab.e-vertical-icon .e-tab-header .e-hor-nav {
  height: 62px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-up-icon::before,
  .e-tab.e-vertical-icon .e-tab-header .e-hor-nav .e-popup-down-icon::before {
    font-size: 12px;
  }
}

.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item.e-active .e-tab-wrap::before,
.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator):last-child.e-active .e-tab-wrap::before {
  display: none;
}

.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item.e-active .e-text-wrap,
.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-toolbar-item:not(.e-separator):last-child.e-active .e-text-wrap {
  position: relative;
}

.e-tab.e-vertical-icon .e-tab-header.e-vertical .e-scroll-nav {
  height: 16px;
}

.e-tab.e-vertical-icon.e-rtl .e-tab-header .e-toolbar-item.e-active {
  margin: 0 0 0 2px;
}

.e-tab.e-vertical-icon.e-vertical-tab .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 32px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 62px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-focused .e-tab-header .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus {
  height: 26px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 26px;
}

.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-focused .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 32px;
}

.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
  padding: 0;
}

.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-tab.e-focused .e-tab-header .e-horizontal-bottom .e-toolbar-item.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab.e-template .e-content > .e-item {
  display: none;
}

.e-tab.e-template .e-content > .e-item.e-active {
  background: inherit;
  display: block;
}

.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align {
  display: -ms-flexbox;
  display: flex;
}

.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  display: block;
  line-height: 15px;
}

.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-ie .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-ie .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  display: block;
  line-height: 14px;
}

.e-tab.e-ie .e-tab-header .e-popup-up-icon,
.e-tab.e-ie .e-tab-header .e-popup-down-icon {
  transform: none;
  transition: none;
}

.e-tab.e-ie .e-tab-header .e-popup-up-icon::before {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab.e-ie .e-tab-header .e-popup-up-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-ie .e-tab-header .e-popup-down-icon::before {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab.e-ie .e-tab-header .e-popup-down-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align {
  display: -ms-flexbox;
  display: flex;
}

.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-popup-down-icon,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-up-icon,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-popup-down-icon {
  display: block;
  line-height: 14px;
  position: relative;
}

.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-edge .e-tab-header .e-hor-nav.e-ie-align .e-nav-right-arrow,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-left-arrow,
.e-tab.e-edge .e-tab-header .e-scroll-nav.e-ie-align .e-nav-right-arrow {
  display: block;
  line-height: 11px;
  position: relative;
}

.e-tab.e-edge .e-tab-header .e-popup-up-icon {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-up-icon {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-popup-down-icon {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon {
    transform: none;
    transition: none;
  }
}

@media screen and (max-width: 480px) and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-popup-up-icon::before {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-up-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-edge .e-tab-header .e-popup-down-icon::before {
  transform: rotate(0deg);
  transition: none;
}

@media screen and (max-width: 480px) {
  .e-tab.e-edge .e-tab-header .e-popup-down-icon::before {
    transform: none;
    transition: none;
  }
}

.e-tab.e-safari .e-tab-header .e-close-icon::before {
  top: -1px;
}

.e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-safari .e-tab-header .e-hor-nav .e-popup-down-icon::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-up-icon::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-popup-down-icon::before {
  top: 0;
}

.e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-left-arrow::before,
.e-tab.e-safari .e-tab-header .e-hor-nav .e-nav-right-arrow::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-left-arrow::before,
.e-tab.e-safari .e-tab-header .e-scroll-nav .e-nav-right-arrow::before {
  top: 0;
}

.e-tab.e-disable {
  pointer-events: none;
}

.e-tab.e-fill .e-tab-header {
  height: 32px;
  min-height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header {
    height: 42px;
    min-height: 42px;
  }
}

.e-tab.e-fill .e-tab-header::before {
  display: none;
}

.e-tab.e-fill .e-tab-header .e-indicator {
  display: none;
}

.e-tab.e-fill .e-tab-header .e-toolbar-items {
  height: auto;
  min-height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-items {
    min-height: 42px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-fill .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
  height: 32px;
  margin: 0 2px 0 0;
  min-height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-itop, .e-tab.e-fill .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom {
  height: 62px;
  min-height: 62px;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  height: 32px;
  padding: 0 15px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
    height: 42px;
    padding: 0 15px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
    height: 42px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 400;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
  height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-ileft .e-text-wrap,
  .e-tab.e-fill .e-tab-header .e-toolbar-item.e-active.e-iright .e-text-wrap {
    height: 42px;
  }
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap {
  height: 62px;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}

.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop:not(.e-separator),
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom:not(.e-separator) {
  height: auto;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  padding: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-itop, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-itop, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-itop {
  padding-top: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin-right: 0;
  padding: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
    height: 42px;
  }
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: 44px;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-itop .e-text-wrap, .e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-ibottom .e-text-wrap {
  height: auto;
}

.e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items {
  height: inherit;
}

.e-tab.e-fill .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 12px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  height: 32px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-text-wrap {
  height: 32px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus {
  height: 62px;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

@media screen and (max-width: 480px) {
  .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-itop .e-tab-wrap:focus .e-text-wrap,
  .e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-ibottom .e-tab-wrap:focus .e-text-wrap {
    height: auto;
  }
}

.e-tab.e-fill.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}

.e-tab.e-fill.e-vertical-icon .e-tab-header {
  height: 60px;
  min-height: 60px;
}

.e-tab.e-background .e-tab-header::before {
  display: none;
}

.e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-background .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
  padding: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 2px 0 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  padding: 0 15px;
}

@media screen and (max-width: 480px) {
  .e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
    padding: 0 20px;
  }
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active {
  padding: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  margin-bottom: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  height: 32px;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text {
  font-weight: 400;
}

.e-tab.e-background .e-tab-header.e-vertical .e-toolbar-items .e-toolbar-item .e-tab-wrap {
  padding: 0 12px;
}

.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child.e-active,
.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  padding: 0;
}

.e-tab.e-background .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  margin: 0;
  padding: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-text-wrap {
  height: 26px;
}

.e-tab.e-background.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child.e-active {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icon-left + .e-tab-text {
  margin: 0 8px 0 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator) .e-icons.e-close-icon {
  margin: 0 8px 0 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-itop .e-close-icon,
.e-tab.e-rtl .e-tab-header .e-toolbar-item:not(.e-separator).e-ibottom .e-close-icon {
  left: 15px;
  right: auto;
}

.e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab.e-rtl .e-tab-header.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-left: 20px;
  margin-right: 0;
}

.e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
  margin: 0 0 0 2px;
}

.e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-active {
  margin: 0 0 0 2px;
}

.e-tab.e-rtl .e-tab-header.e-horizontal-bottom .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-active .e-tab-text {
  margin-bottom: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
  padding-left: 10px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-text {
    padding-left: 0;
  }
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-icons.e-close-icon {
  margin: 0;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item .e-close-icon {
  left: 16px;
  right: auto;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-close-icon,
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-close-icon {
  left: 24px;
  right: auto;
}

.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
.e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
  margin: 0;
  padding-left: 10px;
  padding-right: 0;
}

@media screen and (max-width: 480px) {
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-itop .e-tab-text,
  .e-tab.e-rtl .e-tab-header .e-toolbar-pop .e-toolbar-item.e-ibottom .e-tab-text {
    margin: 0;
    padding-left: 10px;
    padding-right: 0;
  }
}

.e-tab.e-rtl .e-tab-header .e-hor-nav,
.e-tab.e-rtl .e-tab-header .e-scroll-right-nav {
  padding: 0;
}

.e-tab.e-rtl .e-tab-header .e-scroll-left-nav {
  padding: 0;
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::before,
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::before {
  display: none;
}

.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon::after,
.e-tab.e-vertical-tab.e-icon-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon::after {
  margin: 0;
  width: 100%;
}

.e-tab-clone-element {
  overflow: visible;
  z-index: 10000;
}

.e-tab-clone-element:not(.e-separator) {
  height: 32px;
  margin: 0 2px 0 0;
  min-height: 32px;
  min-width: auto;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element:not(.e-separator) {
    height: 42px;
    min-height: 42px;
  }
}

.e-tab-clone-element .e-tab-wrap {
  height: 32px;
  padding: 0 12px;
  width: 100%;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-tab-wrap {
    height: 42px;
    padding: 0 15px;
  }
}

.e-tab-clone-element .e-text-wrap {
  -ms-flex-line-pack: center;
      align-content: center;
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 32px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-text-wrap {
    height: 42px;
  }
}

.e-tab-clone-element .e-tab-text {
  display: inherit;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-tab-text {
    font-size: 16px;
  }
}

.e-tab-clone-element .e-icons.e-close-icon {
  display: none;
}

.e-tab-clone-element .e-close-icon,
.e-tab-clone-element .e-close-icon::before {
  font-size: 10px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-close-icon,
  .e-tab-clone-element .e-close-icon::before {
    font-size: 12px;
  }
}

.e-tab-clone-element .e-close-icon::before {
  top: 0;
}

.e-tab-clone-element .e-icons.e-tab-icon {
  display: -ms-inline-flexbox;
  display: inline-flex;
  height: 20px;
  min-width: 20px;
  width: 20px;
  line-height: 20px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-icons.e-tab-icon {
    height: 20px;
    width: 20px;
  }
}

.e-tab-clone-element .e-tab-icon,
.e-tab-clone-element .e-tab-icon::before {
  font-size: 14px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-tab-icon,
  .e-tab-clone-element .e-tab-icon::before {
    font-size: 20px;
  }
}

.e-tab-clone-element.e-icon .e-tab-wrap {
  -ms-flex-pack: center;
      justify-content: center;
  padding: 0 15px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-icon .e-tab-wrap {
    padding: 0 16px;
  }
}

.e-tab-clone-element .e-icon-left + .e-tab-text {
  margin: 0 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}

.e-tab-clone-element.e-itop, .e-tab-clone-element.e-ibottom {
  height: 62px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop, .e-tab-clone-element.e-ibottom {
    height: 72px;
  }
}

.e-tab-clone-element.e-itop .e-tab-wrap, .e-tab-clone-element.e-ibottom .e-tab-wrap {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  height: 62px;
  position: relative;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop .e-tab-wrap, .e-tab-clone-element.e-ibottom .e-tab-wrap {
    height: 72px;
  }
}

.e-tab-clone-element.e-itop .e-tab-wrap:focus .e-text-wrap, .e-tab-clone-element.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-tab-clone-element.e-itop .e-text-wrap, .e-tab-clone-element.e-ibottom .e-text-wrap {
  display: block;
  height: auto;
}

.e-tab-clone-element.e-itop .e-close-icon, .e-tab-clone-element.e-ibottom .e-close-icon {
  position: absolute;
  right: 15px;
  top: calc(50% - 6px);
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop .e-close-icon, .e-tab-clone-element.e-ibottom .e-close-icon {
    right: 12px;
  }
}

.e-tab-clone-element.e-itop .e-tab-text {
  margin: 8px 0 0;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-itop .e-tab-text {
    margin: 10px 0 0;
  }
}

.e-tab-clone-element.e-ibottom .e-tab-text {
  margin: 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-ibottom .e-tab-text {
    margin: 0 0 10px;
  }
}

.e-tab-clone-element .e-tab-icon.e-icon-top,
.e-tab-clone-element .e-tab-icon.e-icon-bottom {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  margin: auto;
}

.e-tab-clone-element.e-close-show .e-icons.e-close-icon {
  -ms-flex-align: center;
      align-items: center;
  -ms-flex-item-align: center;
      align-self: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

@media screen and (max-width: 480px) {
  .e-tab-clone-element.e-close-show .e-icons.e-close-icon {
    display: none;
  }
}

.e-tab-clone-element.e-close-show .e-toolbar-item.e-itop .e-text-wrap,
.e-tab-clone-element.e-close-show .e-toolbar-item.e-ibottom .e-text-wrap {
  margin-right: 20px;
}

.e-bigger .e-tab-clone-element:not(.e-separator) {
  height: 42px;
  margin: 0 2px 0 0;
  min-height: 42px;
  min-width: auto;
  padding: 0;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element:not(.e-separator) {
    margin: 0 2px 0 0;
  }
}

.e-bigger .e-tab-clone-element:not(.e-separator).e-itop, .e-bigger .e-tab-clone-element:not(.e-separator).e-ibottom {
  height: 72px;
  min-height: 72px;
}

.e-bigger .e-tab-clone-element .e-tab-wrap {
  height: 42px;
  padding: 0 16px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-tab-wrap {
    padding: 0 15px;
  }
}

.e-bigger .e-tab-clone-element .e-text-wrap {
  height: 42px;
}

.e-bigger .e-tab-clone-element .e-tab-text {
  font-size: 16px;
}

.e-bigger .e-tab-clone-element .e-icons.e-close-icon {
  cursor: pointer;
  margin: 0 0 0 8px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icons.e-close-icon {
    margin: 0 0 0 8px;
  }
}

.e-bigger .e-tab-clone-element .e-icons.e-close-icon,
.e-bigger .e-tab-clone-element .e-close-icon::before {
  font-size: 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icons.e-close-icon,
  .e-bigger .e-tab-clone-element .e-close-icon::before {
    font-size: 12px;
  }
}

.e-bigger .e-tab-clone-element .e-icons.e-tab-icon {
  height: 24px;
  min-width: 24px;
  width: 24px;
  line-height: 24px;
}

.e-bigger .e-tab-clone-element .e-tab-icon,
.e-bigger .e-tab-clone-element .e-tab-icon::before {
  font-size: 16px;
}

.e-bigger .e-tab-clone-element.e-icon .e-tab-wrap {
  padding: 0 20px;
}

.e-bigger .e-tab-clone-element .e-icon-left + .e-tab-text {
  margin: 0 0 0 12px;
}

@media screen and (max-width: 480px) {
  .e-bigger .e-tab-clone-element .e-icon-left + .e-tab-text {
    margin: 0 0 0 10px;
  }
}

.e-bigger .e-tab-clone-element.e-itop, .e-bigger .e-tab-clone-element.e-ibottom {
  height: 72px;
}

.e-bigger .e-tab-clone-element.e-itop .e-tab-wrap, .e-bigger .e-tab-clone-element.e-ibottom .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab-clone-element.e-itop .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab-clone-element.e-ibottom .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab-clone-element.e-itop.e-active .e-tab-wrap, .e-bigger .e-tab-clone-element.e-ibottom.e-active .e-tab-wrap {
  height: 72px;
}

.e-bigger .e-tab-clone-element.e-itop.e-active .e-tab-wrap:focus .e-text-wrap, .e-bigger .e-tab-clone-element.e-ibottom.e-active .e-tab-wrap:focus .e-text-wrap {
  height: auto;
}

.e-bigger .e-tab-clone-element.e-itop .e-close-icon, .e-bigger .e-tab-clone-element.e-ibottom .e-close-icon {
  right: 20px;
}

.e-bigger .e-tab-clone-element.e-itop .e-tab-text {
  margin: 10px 0 0;
}

.e-bigger .e-tab-clone-element.e-ibottom .e-tab-text {
  margin: 0 0 10px;
}

/*! tab theme */
.e-tab {
  background: transparent;
  border: none;
}

.e-tab .e-tab-header {
  background: inherit;
  border: 0;
  border-radius: 0;
}

.e-tab .e-tab-header::before {
  border-color: #dee2e6;
  border-style: solid;
}

.e-tab .e-tab-header:not(.e-vertical)::before {
  border-width: 0 0 1px;
}

.e-tab .e-tab-header[style*='overflow: hidden'] {
  border: 0;
}

.e-tab .e-tab-header:not(.e-vertical) .e-toolbar-item.e-active {
  border-bottom: 1px solid #fff;
}

.e-tab .e-tab-header .e-toolbar-items {
  background: inherit;
}

.e-tab .e-tab-header .e-indicator {
  background: #dee2e6;
}

.e-tab .e-tab-header .e-toolbar-item {
  background: inherit;
  border: 1px solid transparent;
}

.e-tab .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(222, 226, 230, 0);
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
  border-radius: 4px;
  color: #007bff;
  border: 1px solid transparent;
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #007bff;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap {
    color: #317ab9;
  }
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
    color: #317ab9;
  }
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap .e-close-icon {
    color: #777;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
  background: transparent;
  border: 1px solid #e9ecef;
  border-radius: 4px 4px 0 0;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
    background: initial;
  }
}

.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #0056b3;
}

.e-tab .e-tab-header .e-toolbar-item.e-active {
  border: 1px solid #dee2e6;
  border-radius: 4px 4px 0 0;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  border: 0;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #495057;
}

.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #495057;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-icon, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-icon {
  color: #495057;
}

.e-tab .e-tab-header .e-toolbar-item.e-active.e-ileft .e-tab-text, .e-tab .e-tab-header .e-toolbar-item.e-active.e-iright .e-tab-text {
  color: #777;
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon {
  color: rgba(0, 0, 0, 0.5);
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon:hover {
  color: rgba(0, 0, 0, 0.75);
}

.e-tab .e-tab-header .e-toolbar-item .e-close-icon:active {
  color: rgba(0, 0, 0, 0.75);
}

.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay {
  background: inherit;
  opacity: 1;
  pointer-events: none;
}

.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay .e-tab-text,
.e-tab .e-tab-header .e-toolbar-item.e-disable.e-overlay .e-tab-icon {
  color: #6c757d;
}

.e-tab .e-tab-header.e-reorder-active-item:not(.e-vertical) .e-toolbar-pop .e-toolbar-item.e-active {
  border: 1px solid #fff;
}

.e-tab .e-tab-header .e-toolbar-pop {
  background: #fff;
  border: 1px #000;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  overflow-y: auto;
}

.e-tab .e-tab-header .e-toolbar-pop[e-animate='true'] {
  overflow-y: hidden;
}

@media screen and (max-width: 480px) {
  .e-tab .e-tab-header .e-toolbar-pop {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  }
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #212529;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: #f2f4f6;
  border: 1px solid transparent;
  border-radius: 0;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-icon,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: #0056b3;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active {
  background: #007bff;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-icon {
  color: #fff;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon {
  color: #495057;
}

.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-disable.e-overlay .e-tab-text,
.e-tab .e-tab-header .e-toolbar-pop .e-toolbar-item.e-disable.e-overlay .e-tab-icon {
  color: #6c757d;
}

.e-tab .e-tab-header .e-scroll-nav,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  background: inherit;
  border: 0;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow {
  color: #212529;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:hover {
  background: #5a6268;
  color: #fff;
}

.e-tab .e-tab-header .e-scroll-nav .e-nav-arrow:active,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:active {
  box-shadow: none;
}

.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow {
  background: #6c757d;
  color: #fff;
}

.e-tab .e-tab-header .e-scroll-nav:focus .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:hover {
  background: #5a6268;
  color: #fff;
}

.e-tab .e-tab-header .e-scroll-nav:active,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:active {
  border: 3px solid #adb5bd;
}

.e-tab .e-tab-header .e-scroll-nav.e-overlay .e-nav-arrow,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-overlay .e-nav-arrow {
  color: rgba(108, 117, 125, 0.65);
}

.e-tab .e-tab-header .e-scroll-nav.e-overlay .e-nav-arrow:hover,
.e-tab .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-overlay .e-nav-arrow:hover {
  color: rgba(108, 117, 125, 0.65);
}

.e-tab .e-tab-header .e-hor-nav,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) {
  background: transparent;
  border: 0;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon {
  color: #212529;
  border: 0;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover {
  background: #5a6268;
  border: 0;
  border-radius: 4px;
}

.e-tab .e-tab-header .e-hor-nav .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover {
  color: #fff;
  border: 0;
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon {
  background: #6c757d;
  border: 0;
  border-radius: 4px;
  color: #fff;
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:hover {
  background: #5a6268;
  border: 0;
  border-radius: 4px;
}

.e-tab .e-tab-header .e-hor-nav:focus .e-popup-up-icon:active,
.e-tab .e-tab-header .e-hor-nav:focus .e-popup-down-icon:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon:active {
  background: #5a6268;
  border: 0;
  border-radius: 4px;
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.e-tab .e-tab-header .e-hor-nav:hover,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):hover {
  border: 0;
}

.e-tab .e-tab-header .e-hor-nav:hover:active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav):hover:active {
  background: transparent;
}

.e-tab .e-tab-header .e-hor-nav.e-nav-active,
.e-tab .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active {
  background: inherit;
  border: 0;
  box-shadow: none;
  background: #6c757d;
  border: 3px solid #adb5bd;
  border-radius: 4px;
}

.e-tab .e-tab-header.e-horizontal-bottom[style*='overflow: hidden'] {
  border: 0;
}

.e-tab .e-tab-header.e-horizontal-bottom[style*='overflow: hidden'] .e-toolbar-items {
  border-bottom: 0;
  border-top: 1px solid #ddd;
}

.e-tab .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active {
  border-bottom: 1px solid #ddd;
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #fff;
  border-top-color: #fff;
}

.e-tab .e-tab-header.e-vertical::before {
  border-width: 0 1px 0 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-item.e-active {
  border-bottom: 1px solid #ddd;
  border-top: 1px solid #ddd;
}

.e-tab .e-tab-header.e-vertical.e-vertical-left .e-toolbar-item.e-active {
  border-radius: 4px 0 0 4px;
  border-right: 1px solid #fff;
}

.e-tab .e-tab-header.e-vertical.e-vertical-right .e-toolbar-item.e-active {
  border-left: 1px solid #fff;
  border-radius: 0 4px 4px 0;
}

.e-tab .e-tab-header.e-vertical .e-toolbar-pop {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav {
  background: inherit;
  border: 1px solid transparent;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav.e-scroll-up-nav {
  border-bottom-color: #ddd;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav.e-scroll-down-nav {
  border-top-color: #ddd;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow:hover {
  background: none;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover {
  background: #eee;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow {
  color: #fff;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow:hover {
  background: none;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus {
  background: #eee;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  background: none;
}

.e-tab .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow:hover {
  background: none;
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  border: 0;
  color: #212529;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon {
  background: inherit;
  border: 0;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:hover, .e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active, .e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:active:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:hover,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active,
.e-tab .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:active:hover {
  background: inherit;
  border: 0;
  box-shadow: none;
}

.e-tab .e-tab-header.e-vertical .e-hor-nav:hover .e-popup-up-icon,
.e-tab .e-tab-header.e-vertical .e-hor-nav:hover .e-popup-down-icon {
  border: 0;
}

.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: #eee;
}

.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-focused > .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text {
  color: #0056b3;
}

.e-tab.e-focused > .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #fff;
}

.e-tab.e-focused > .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-focused > .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #fff;
}

.e-tab.e-focused > .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  border: 0;
}

.e-tab.e-focused > .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon,
.e-tab.e-focused > .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon {
  border: 0;
}

.e-tab.e-focused > .e-tab-header.e-vertical .e-hor-nav:focus {
  outline: 0;
}

.e-tab.e-disable {
  pointer-events: none;
}

.e-tab.e-disable .e-tab-header .e-indicator {
  background: rgba(222, 226, 230, 0.38);
}

.e-tab.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #6c757d;
}

.e-tab.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #6c757d;
}

.e-tab.e-disable .e-content {
  opacity: 0.38;
}

.e-tab.e-fill .e-tab-header {
  border-bottom: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-items.e-hscroll {
  border: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item {
  border: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(222, 226, 230, 0);
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #007bff;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #0056b3;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item .e-text-wrap {
  margin-top: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active {
  border: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  background: #007bff;
  border-radius: 4px;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #fff;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-text-wrap {
  margin-top: 0;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #fff;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: #fff;
}

.e-tab.e-fill .e-tab-header .e-toolbar-item.e-active .e-close-icon:hover {
  color: #fff;
}

.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover {
  background: #007bff;
}

.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover .e-tab-text,
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover .e-tab-icon,
.e-tab.e-fill .e-tab-header .e-toolbar-pop .e-tab-wrap:hover .e-close-icon {
  color: #fff;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 0;
}

.e-tab.e-fill .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-text-wrap {
  margin-top: 0;
}

.e-tab.e-fill .e-tab-header.e-vertical {
  border-bottom: 0;
}

.e-tab.e-fill .e-tab-header.e-vertical.e-vertical-left {
  border-right: 0;
}

.e-tab.e-fill .e-tab-header.e-vertical.e-vertical-right {
  border-left: 0;
}

.e-tab.e-fill.e-corner .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  border-radius: 3px 3px 0 0;
}

.e-tab.e-fill.e-corner .e-tab-header.e-horizontal-bottom .e-toolbar-item.e-active .e-tab-wrap {
  border-radius: 0 0 3px 3px;
}

.e-tab.e-fill.e-disable .e-tab-header {
  border-bottom: 0;
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #6c757d;
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active {
  opacity: 0.65;
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #317ab9;
}

.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #6c757d;
}

.e-tab.e-fill.e-disable .e-tab-header.e-horizontal-bottom {
  border-bottom: 0;
  border-top: 0;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-close-icon {
  color: #0056b3;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #eee;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-close-icon {
  color: #0056b3;
}

.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-fill.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #212529;
}

.e-tab.e-background .e-tab-header {
  background: inherit;
  border: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-items {
  background: inherit;
}

.e-tab.e-background .e-tab-header .e-toolbar-items.e-hscroll {
  border: 0;
}

.e-tab.e-background .e-tab-header .e-indicator {
  background: #dee2e6;
}

.e-tab.e-background .e-tab-header .e-toolbar-item {
  border: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-ripple-element {
  background: rgba(222, 226, 230, 0);
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap {
  background: inherit;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-close-icon {
  color: #007bff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap .e-close-icon:hover {
  color: rgba(0, 0, 0, 0.5);
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover {
  background: transparent;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-tab-text {
  color: #0056b3;
}

.e-tab.e-background .e-tab-header .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: rgba(0, 0, 0, 0.5);
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active {
  border: 0;
  margin: 0;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap {
  background: #007bff;
  border-radius: 4px;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-close-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #007bff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #fff;
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-close-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-tab-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-close-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active .e-close-icon:hover {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap {
  background: #317ab9;
}

.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-item.e-disable .e-close-icon {
  color: #6c757d;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item {
  background: inherit;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #007bff;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover {
  background: #eee;
  background: #007bff;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-tab-icon,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:hover .e-close-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-text,
.e-tab.e-background .e-tab-header .e-toolbar-pop .e-toolbar-item .e-tab-wrap:active .e-tab-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow,
.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow {
  color: #212529;
}

.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav .e-nav-arrow:hover,
.e-tab.e-background .e-tab-header .e-scroll-nav .e-nav-arrow:hover {
  background: inherit;
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow,
.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus .e-nav-arrow:hover,
.e-tab.e-background .e-tab-header .e-scroll-nav:focus .e-nav-arrow:hover {
  background: inherit;
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hscroll:not(.e-scroll-device) .e-scroll-nav:active::after,
.e-tab.e-background .e-tab-header .e-scroll-nav:active::after {
  animation: none;
}

.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon {
  color: #212529;
}

.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav) .e-popup-down-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  color: #fff;
  background: #e6e6e6;
  background: #5a6268;
}

.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):focus .e-popup-down-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav):active::after,
.e-tab.e-background .e-tab-header .e-hor-nav:active::after {
  animation: none;
}

.e-tab.e-background .e-tab-header .e-hor-nav:not(.e-expended-nav).e-nav-active .e-popup-up-icon,
.e-tab.e-background .e-tab-header .e-hor-nav.e-nav-active .e-popup-up-icon {
  color: #fff;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav .e-nav-arrow {
  border: 0;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow {
  border: 0;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:focus .e-nav-arrow:hover {
  background: none;
}

.e-tab.e-background .e-tab-header.e-vertical .e-scroll-nav:hover .e-nav-arrow {
  color: #fff;
}

.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon {
  background: inherit;
  border-color: transparent;
}

.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav:focus .e-popup-down-icon:hover {
  background: inherit;
  border-color: transparent;
}

.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav .e-popup-up-icon:hover,
.e-tab.e-background .e-tab-header.e-vertical .e-hor-nav .e-popup-down-icon:hover {
  background: inherit;
  border-color: transparent;
}

.e-tab.e-background.e-disable .e-tab-header .e-indicator {
  background: rgba(222, 226, 230, 0.38);
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap {
  color: #6c757d;
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap .e-tab-icon {
  color: #6c757d;
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon {
  color: #6c757d;
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active {
  opacity: 0.65;
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #317ab9;
  color: #6c757d;
}

.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap .e-tab-icon,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-disable .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #6c757d;
}

.e-tab.e-background.e-accent .e-tab-header .e-indicator {
  background: #dee2e6;
}

.e-tab.e-background.e-accent .e-tab-header.e-disable .e-indicator {
  background: rgba(222, 226, 230, 0.38);
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus {
  background: #007bff;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-icon,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item .e-tab-wrap:focus .e-close-icon {
  color: #007bff;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus {
  background: #eee;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-icon {
  color: #fff;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover {
  border: 1px solid #e9ecef;
  border-radius: 4px 4px 0 0;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus .e-close-icon,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:hover .e-close-icon {
  color: #0056b3;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-tab-text,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active .e-tab-wrap:focus:hover .e-close-icon {
  color: #0056b3;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap {
  background: #317ab9;
}

.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-itop .e-tab-wrap:focus,
.e-tab.e-background.e-focused .e-tab-header .e-toolbar-item.e-active.e-ibottom .e-tab-wrap:focus {
  background: #eee;
}

.e-tab.e-background.e-focused .e-tab-header .e-scroll-nav:focus .e-nav-arrow {
  color: #fff;
}

.e-tab.e-background.e-focused .e-tab-header .e-hor-nav:focus .e-popup-up-icon,
.e-tab.e-background.e-focused .e-tab-header .e-hor-nav:focus .e-popup-down-icon {
  color: #fff;
}

.e-tab.e-rtl .e-tab-header .e-hscroll.e-rtl .e-scroll-nav {
  border: 0;
}

.e-tab .e-content {
  background: inherit;
}

.e-tab .e-content .e-item {
  background: inherit;
  color: #000;
}

.e-tab-clone-element {
  background: rgba(108, 117, 125, 0.87);
  color: #fff;
}

.e-tab-clone-element .e-tab-wrap {
  border-radius: 4px;
  color: #fff;
  border: 1px solid transparent;
}

.e-tab-clone-element .e-tab-wrap .e-tab-icon {
  color: #fff;
}

/*! TreeView icons */
.e-treeview .e-list-item div.e-icons::before {
  content: '\e70b';
}

.e-treeview .e-sibling::before {
  content: '';
}

.e-treeview .e-popup .e-icons::before {
  content: '\e930';
}

.e-treeview.e-drag-item .e-icons.e-drop-in::before {
  content: '\e755';
}

.e-treeview.e-drag-item .e-icons.e-drop-out::before {
  content: '\e742';
}

.e-treeview.e-drag-item .e-icons.e-drop-next::before {
  content: '\e7e8';
}

.e-treeview.e-drag-item .e-icons.e-no-drop::before {
  content: '\e797';
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.e-bigger .e-treeview .e-ul,
.e-bigger .e-treeview .e-list-item .e-ul,
.e-treeview.e-bigger .e-ul,
.e-treeview.e-bigger .e-list-item .e-ul {
  padding-left: 16px;
}

.e-bigger .e-treeview .e-navigable .e-text-content,
.e-treeview.e-bigger .e-navigable .e-text-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.e-bigger .e-treeview .e-navigable .e-list-text,
.e-treeview.e-bigger .e-navigable .e-list-text {
  padding: 0;
  width: 100%;
}

.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-text {
  padding: 0;
}

.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 0 0 12px;
}

.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-bigger .e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-bigger .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 12px 0 4px;
}

.e-bigger .e-treeview .e-navigable .e-anchor-wrap,
.e-treeview.e-bigger .e-navigable .e-anchor-wrap {
  padding: 0 0 0 10px;
}

.e-bigger .e-treeview .e-navigable .e-list-icon,
.e-bigger .e-treeview .e-navigable .e-list-img,
.e-bigger .e-treeview .e-navigable .e-list-icon + .e-list-img,
.e-treeview.e-bigger .e-navigable .e-list-icon,
.e-treeview.e-bigger .e-navigable .e-list-img,
.e-treeview.e-bigger .e-navigable .e-list-icon + .e-list-img {
  margin: 5px 12px 0 0;
}

.e-bigger .e-treeview .e-fullrow,
.e-treeview.e-bigger .e-fullrow {
  height: 40px;
}

.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-text {
  max-width: calc(100% - 24px);
}

.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-text,
.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-img + .e-list-text {
  max-width: calc(100% - 65px);
}

.e-bigger .e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 93px);
}

.e-bigger .e-treeview.e-text-wrap .e-list-icon + .e-list-text,
.e-bigger .e-treeview.e-text-wrap .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-list-icon + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-list-img + .e-list-text {
  max-width: calc(100% - 34px);
}

.e-bigger .e-treeview.e-text-wrap .e-list-icon + .e-list-img + .e-list-text,
.e-treeview.e-bigger.e-text-wrap .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 62px);
}

.e-bigger .e-treeview .e-list-text,
.e-treeview.e-bigger .e-list-text {
  line-height: 36px;
  min-height: 38px;
  padding: 0 12px 0 8px;
}

.e-bigger .e-treeview .e-list-text .e-input-group,
.e-treeview.e-bigger .e-list-text .e-input-group {
  height: 38px;
}

.e-bigger .e-treeview .e-list-text .e-input-group .e-input,
.e-treeview.e-bigger .e-list-text .e-input-group .e-input {
  height: 36px;
}

.e-bigger .e-treeview .e-checkbox-wrapper,
.e-treeview.e-bigger .e-checkbox-wrapper {
  margin: 0 0 0 10px;
}

.e-bigger .e-treeview .e-checkbox-wrapper + .e-list-text,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-text {
  padding: 0 12px;
}

.e-bigger .e-treeview .e-checkbox-wrapper + .e-list-icon,
.e-bigger .e-treeview .e-checkbox-wrapper + .e-list-img,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-bigger .e-checkbox-wrapper + .e-list-img {
  margin: 0 0 0 16px;
}

.e-bigger .e-treeview .e-list-icon,
.e-bigger .e-treeview .e-list-img,
.e-treeview.e-bigger .e-list-icon,
.e-treeview.e-bigger .e-list-img {
  margin: 4px 0 0 10px;
  font-size: 16px;
}

.e-bigger .e-treeview .e-list-icon + .e-list-text,
.e-bigger .e-treeview .e-list-img + .e-list-text,
.e-treeview.e-bigger .e-list-icon + .e-list-text,
.e-treeview.e-bigger .e-list-img + .e-list-text {
  padding: 0 12px;
}

.e-bigger .e-treeview .e-list-icon + .e-list-icon,
.e-bigger .e-treeview .e-list-icon + .e-list-img,
.e-bigger .e-treeview .e-list-img + .e-list-icon,
.e-bigger .e-treeview .e-list-img + .e-list-img,
.e-treeview.e-bigger .e-list-icon + .e-list-icon,
.e-treeview.e-bigger .e-list-icon + .e-list-img,
.e-treeview.e-bigger .e-list-img + .e-list-icon,
.e-treeview.e-bigger .e-list-img + .e-list-img {
  margin: 0 0 0 10px;
}

.e-bigger .e-treeview .e-icon-collapsible,
.e-bigger .e-treeview .e-icon-expandable,
.e-treeview.e-bigger .e-icon-collapsible,
.e-treeview.e-bigger .e-icon-expandable {
  height: 24px;
  margin: 0 0 0 -24px;
  width: 24px;
}

.e-bigger .e-treeview .e-icon-collapsible::before,
.e-bigger .e-treeview .e-icon-expandable::before,
.e-treeview.e-bigger .e-icon-collapsible::before,
.e-treeview.e-bigger .e-icon-expandable::before {
  padding: 7px;
}

.e-bigger .e-treeview.e-drag-item .e-text-content,
.e-treeview.e-bigger.e-drag-item .e-text-content {
  padding-left: 12px;
}

.e-bigger .e-treeview.e-drag-item .e-icon-collapsible,
.e-bigger .e-treeview.e-drag-item .e-icon-expandable,
.e-treeview.e-bigger.e-drag-item .e-icon-collapsible,
.e-treeview.e-bigger.e-drag-item .e-icon-expandable {
  font-size: 16px;
  margin: -6px 0px 5px -14px;
}

.e-bigger .e-treeview.e-drag-item .e-icon-collapsible::before,
.e-bigger .e-treeview.e-drag-item .e-icon-expandable::before,
.e-treeview.e-bigger.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-bigger.e-drag-item .e-icon-expandable::before {
  padding: 12px;
}

.e-bigger .e-treeview.e-drag-item .e-drop-count,
.e-treeview.e-bigger.e-drag-item .e-drop-count {
  border: 1px solid;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 12px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 4px 0 12px;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-anchor-wrap,
.e-treeview.e-bigger.e-rtl .e-navigable .e-anchor-wrap {
  padding: 0 10px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-navigable .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-img,
.e-bigger .e-treeview.e-rtl .e-navigable .e-list-icon + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-img,
.e-treeview.e-bigger.e-rtl .e-navigable .e-list-icon + .e-list-img {
  margin: 0 0 0 12px;
}

.e-bigger .e-treeview.e-rtl .e-icon-collapsible,
.e-bigger .e-treeview.e-rtl .e-icon-expandable,
.e-treeview.e-bigger.e-rtl .e-icon-collapsible,
.e-treeview.e-bigger.e-rtl .e-icon-expandable {
  margin: 0 -24px 1px 0;
}

.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper {
  margin: 0 10px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-checkbox-wrapper + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-checkbox-wrapper + .e-list-img {
  margin: 4px 16px 2px 0;
}

.e-bigger .e-treeview.e-rtl .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-img {
  margin: 2px 10px 0 0;
}

.e-bigger .e-treeview.e-rtl .e-list-icon + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-icon + .e-list-img,
.e-bigger .e-treeview.e-rtl .e-list-img + .e-list-icon,
.e-bigger .e-treeview.e-rtl .e-list-img + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-icon + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-icon + .e-list-img,
.e-treeview.e-bigger.e-rtl .e-list-img + .e-list-icon,
.e-treeview.e-bigger.e-rtl .e-list-img + .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview {
  display: block;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
}

.e-treeview > .e-ul {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.e-treeview.e-text-wrap .e-list-text {
  white-space: normal;
  word-break: break-word;
}

.e-treeview.e-text-wrap.e-ie-wrap .e-list-text {
  word-break: break-all;
}

.e-treeview.e-text-wrap .e-editing .e-list-text,
.e-treeview.e-text-wrap .e-editing .e-list-text .e-input-group {
  max-width: calc(100% - 2px);
}

.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-text {
  max-width: calc(100% - 18px);
}

.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-text,
.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-img + .e-list-text {
  max-width: calc(100% - 55px);
}

.e-treeview.e-text-wrap .e-checkbox-wrapper + .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 83px);
}

.e-treeview.e-text-wrap .e-list-icon + .e-list-text,
.e-treeview.e-text-wrap .e-list-img + .e-list-text {
  max-width: calc(100% - 29px);
}

.e-treeview.e-text-wrap .e-list-icon + .e-list-img + .e-list-text {
  max-width: calc(100% - 57px);
}

.e-treeview .e-ul {
  margin: 0;
  padding: 0 0 0 14px;
}

.e-treeview li.e-node-collapsed .e-list-item .e-fullrow {
  display: none;
}

.e-treeview .e-display-none {
  display: none;
}

.e-treeview .e-list-item div.e-icons.interaction {
  -webkit-transition: -webkit-transform .3s ease-in-out;
  border-radius: 15px;
  transition: transform .3s ease-in-out;
}

.e-treeview .e-list-item .e-icons.e-icon-collapsible {
  transform: rotate(90deg);
}

.e-treeview.e-drag-item.e-rtl .e-icons.e-drop-next {
  transform: rotate(180deg);
}

.e-treeview.e-rtl div.e-icons {
  transform: rotate(180deg);
}

.e-treeview .e-list-item {
  list-style: none;
  padding: 2px 0;
}

.e-treeview .e-list-item .e-ul {
  margin: 2px 0 -2px;
  padding: 0 0 0 14px;
}

.e-treeview .e-list-item.e-disable > .e-text-content,
.e-treeview .e-list-item.e-disable > .e-fullrow {
  -ms-touch-action: none;
  filter: alpha(opacity=50);
  opacity: .5;
  pointer-events: none;
  touch-action: none;
}

.e-treeview .e-list-item .e-icons.e-icons-spinner::before {
  content: none;
}

.e-treeview .e-icons .e-spinner-pane {
  position: relative;
}

.e-treeview .e-icons .e-treeview-spinner {
  position: absolute;
}

.e-treeview .e-icons-spinner {
  position: relative;
}

.e-treeview .e-text-content {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  cursor: pointer;
  margin: 0;
  padding: 0 0 0 24px;
}

.e-treeview .e-fullrow {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  box-sizing: border-box;
  cursor: pointer;
  height: 32px;
  left: 0;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.e-treeview .e-checkbox-wrapper {
  margin: 0 0 0 5px;
  pointer-events: all;
  position: relative;
}

.e-treeview .e-checkbox-wrapper + .e-list-icon,
.e-treeview .e-checkbox-wrapper + .e-list-img {
  margin: 0 0 0 12px;
}

.e-treeview .e-checkbox-wrapper + .e-list-text {
  padding: 0 8px;
}

.e-treeview .e-checkbox-wrapper .e-ripple-container {
  bottom: -7px;
  height: 32px;
  left: -7px;
  right: -7px;
  top: -7px;
  width: 32px;
}

.e-treeview .e-list-text {
  box-sizing: border-box;
  display: inline-block;
  line-height: 30px;
  margin: 0;
  min-height: 30px;
  padding: 0 8px 0 6px;
  text-decoration: none;
  vertical-align: middle;
}

.e-treeview .e-list-text .e-input-group {
  height: 30px;
  margin-bottom: 0;
  min-width: 150px;
  vertical-align: bottom;
}

.e-treeview .e-list-text .e-input-group .e-input {
  height: 28px;
}

.e-treeview .e-list-icon,
.e-treeview .e-list-img {
  display: inline-block;
  height: 18px;
  margin: 2.5px 0 0 5px;
  vertical-align: middle;
  width: 18px;
}

.e-treeview .e-list-icon + .e-list-icon,
.e-treeview .e-list-icon + .e-list-img,
.e-treeview .e-list-img + .e-list-icon,
.e-treeview .e-list-img + .e-list-img {
  margin: 0 0 0 10px;
}

.e-treeview .e-list-icon + .e-list-text,
.e-treeview .e-list-img + .e-list-text {
  padding: 0 8px;
}

.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  display: inline-block;
  height: 20px;
  margin: 0 2px 0 -24px;
  vertical-align: middle;
  width: 20px;
}

.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  display: inline-block;
  padding: 6px;
}

.e-treeview .e-load {
  animation: rotation .5s infinite linear;
}

.e-treeview .e-sibling {
  border: 4px solid transparent;
  height: 6px;
  margin-top: -5px;
  width: 6px;
  position: absolute;
  z-index: 2;
}

.e-treeview .e-text-content + .e-sibling {
  margin-top: -1px;
}

.e-treeview .e-sibling::before {
  left: 0;
  height: 1px;
  width: 144px;
  position: absolute;
  z-index: 2;
}

.e-treeview .e-popup {
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  font-weight: normal;
  position: absolute;
  z-index: 99999;
}

.e-treeview .e-popup .e-content {
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  padding: 4px;
}

.e-treeview .e-popup .e-icons {
  border: 1px solid transparent;
  cursor: pointer;
  display: inline-block;
  height: 26px;
  line-height: 18px;
  padding: 4px;
  width: 26px;
}

.e-treeview .e-popup .e-downtail::before,
.e-treeview .e-popup .e-downtail::after {
  border: 10px solid transparent;
  content: '';
  height: 0;
  left: 8px;
  position: absolute;
  width: 0;
}

.e-treeview .e-popup .e-downtail::after {
  bottom: -18px;
}

.e-treeview.e-fullrow-wrap .e-text-content {
  pointer-events: none;
  position: relative;
}

.e-treeview.e-fullrow-wrap .e-icon-collapsible,
.e-treeview.e-fullrow-wrap .e-icon-expandable,
.e-treeview.e-fullrow-wrap .e-input,
.e-treeview.e-fullrow-wrap .e-list-url {
  pointer-events: auto;
}

.e-treeview .e-navigable .e-text-content {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.e-treeview .e-navigable .e-list-url {
  padding: 0;
  width: 100%;
}

.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 0 0 8px;
}

.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 8px 0 4px;
}

.e-treeview .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon + .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview .e-navigable .e-anchor-wrap {
  padding: 0 0 0 5px;
}

.e-treeview .e-navigable .e-list-icon,
.e-treeview .e-navigable .e-list-img {
  margin: 0 8px 0 0;
}

.e-treeview.e-drag-item {
  overflow: visible;
  z-index: 10000;
}

.e-treeview.e-drag-item .e-text-content {
  float: left;
}

.e-treeview.e-drag-item .e-icon-collapsible,
.e-treeview.e-drag-item .e-icon-expandable {
  margin: 0 8px 8px -24px;
}

.e-treeview.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-drag-item .e-icon-expandable::before {
  font-size: 14px;
  padding: 8px;
  padding-right: 4px;
}

.e-treeview.e-drag-item .e-drop-count {
  border: 0px solid;
  border-radius: 15px;
  box-sizing: content-box;
  font-size: 14px;
  line-height: 1.5;
  min-width: 10px;
  padding: 0 5px;
  margin-left: -12px;
  position: absolute;
  text-align: center;
  top: -10px;
}

.e-treeview.e-dragging .e-text-content,
.e-treeview.e-dragging .e-fullrow {
  cursor: default;
}

.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap {
  padding: 0 8px 0 0;
}

.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon,
.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-img {
  margin: 0 4px 0 8px;
}

.e-treeview.e-rtl .e-navigable .e-checkbox-wrapper + .e-list-url .e-anchor-wrap .e-list-icon + .e-list-img {
  margin: 0 0 0 10px;
}

.e-treeview.e-rtl .e-navigable .e-anchor-wrap {
  padding: 0 5px 0 0;
}

.e-treeview.e-rtl .e-navigable .e-list-icon,
.e-treeview.e-rtl .e-navigable .e-list-img,
.e-treeview.e-rtl .e-navigable .e-list-icon + .e-list-img {
  margin: 0 0 0 8px;
}

.e-treeview.e-rtl .e-ul {
  padding: 0 14px 0 0;
}

.e-treeview.e-rtl .e-list-item .e-ul {
  padding: 0 14px 0 0;
}

.e-treeview.e-rtl .e-text-content {
  padding: 0 24px 0 0;
}

.e-treeview.e-rtl .e-checkbox-wrapper {
  margin: 0 5px 0 0;
}

.e-treeview.e-rtl .e-checkbox-wrapper + .e-list-icon,
.e-treeview.e-rtl .e-checkbox-wrapper + .e-list-img {
  margin: 5px 12px 0 0;
}

.e-treeview.e-rtl .e-list-icon,
.e-treeview.e-rtl .e-list-img {
  margin: 2px 4px 0 0;
}

.e-treeview.e-rtl .e-list-icon + .e-list-icon,
.e-treeview.e-rtl .e-list-icon + .e-list-img,
.e-treeview.e-rtl .e-list-img + .e-list-icon,
.e-treeview.e-rtl .e-list-img + .e-list-img {
  margin: 0 10px 0 0;
}

.e-treeview.e-rtl .e-icon-collapsible,
.e-treeview.e-rtl .e-icon-expandable {
  margin: -0.5px -24px 0 0;
}

.e-treeview.e-rtl .e-sibling::before {
  right: 0;
}

.e-treeview.e-rtl.e-drag-item .e-text-content {
  float: right;
}

.e-treeview.e-rtl.e-drag-item .e-icon-collapsible,
.e-treeview.e-rtl.e-drag-item .e-icon-expandable {
  margin: 10px -13px -1px 0;
}

.e-treeview.e-rtl.e-drag-item .e-drop-count {
  margin-right: -12px;
}

.e-treeview.e-disabled .e-fullrow,
.e-treeview.e-disabled .e-icons,
.e-treeview.e-disabled .e-text-content,
.e-treeview.e-disabled .e-list-img,
.e-treeview.e-disabled .e-list-icon {
  cursor: auto;
}

.e-treeview.e-disabled .e-list-url {
  cursor: default;
  pointer-events: none;
}

.e-treeview.e-interaction.e-fullrow-wrap .e-text-content {
  pointer-events: auto;
}

.e-bigger .e-treeview .e-list-text,
.e-treeview.e-bigger .e-list-text {
  font-size: 16px;
  color: #212529;
}

.e-bigger .e-treeview .e-icon-collapsible::before,
.e-bigger .e-treeview .e-icon-expandable::before,
.e-treeview.e-bigger .e-icon-collapsible::before,
.e-treeview.e-bigger .e-icon-expandable::before {
  font-size: 10px;
}

.e-bigger .e-treeview.e-drag-item .e-icon-collapsible,
.e-bigger .e-treeview.e-drag-item .e-icon-expandable,
.e-treeview.e-bigger.e-drag-item .e-icon-collapsible,
.e-treeview.e-bigger.e-drag-item .e-icon-expandable {
  padding: 0px 12px 0px 4px;
}

.e-bigger .e-treeview.e-drag-item .e-list-text,
.e-treeview.e-bigger.e-drag-item .e-list-text {
  padding: 2px 12px 0 4px;
}

.e-treeview {
  -webkit-tap-highlight-color: transparent;
}

.e-treeview .e-text-content,
.e-treeview .e-fullrow {
  border-color: transparent;
}

.e-treeview .e-list-text {
  color: #212529;
  font-size: 14px;
}

.e-treeview .e-list-icon,
.e-treeview .e-list-img {
  font-size: 14px;
}

.e-treeview .e-icon-collapsible,
.e-treeview .e-icon-expandable {
  color: #495057;
}

.e-treeview .e-icon-collapsible::before,
.e-treeview .e-icon-expandable::before {
  font-size: 8px;
}

.e-treeview .e-list-item.e-active,
.e-treeview .e-list-item.e-hover {
  background: transparent;
}

.e-treeview .e-list-item.e-hover > .e-text-content {
  color: #212529;
}

.e-treeview .e-list-item.e-hover > .e-text-content .e-list-text {
  color: #212529;
}

.e-treeview .e-list-item.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-hover > .e-text-content .e-icon-expandable {
  color: #495057;
}

.e-treeview .e-list-item.e-active > .e-text-content {
  color: #fff;
}

.e-treeview .e-list-item.e-active > .e-text-content .e-list-text {
  color: #fff;
}

.e-treeview .e-list-item.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active > .e-text-content .e-icon-expandable {
  color: #fff;
}

.e-treeview .e-list-item.e-active > .e-text-content .e-check {
  background-color: #fff;
  border-color: #fff;
  color: #007bff;
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content {
  color: #fff;
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-list-text {
  color: #fff;
}

.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-active.e-hover > .e-text-content .e-icon-expandable {
  color: #fff;
}

.e-treeview .e-list-item.e-editing.e-active > .e-text-content,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content {
  color: #212529;
}

.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-list-text,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-list-text {
  color: #212529;
}

.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-active > .e-text-content .e-icon-expandable,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-icon-collapsible,
.e-treeview .e-list-item.e-editing.e-hover > .e-text-content .e-icon-expandable {
  color: #495057;
}

.e-treeview .e-list-item.e-hover > .e-fullrow {
  background-color: #f2f4f6;
  border-color: #f2f4f6;
}

.e-treeview .e-list-item.e-active > .e-fullrow {
  background-color: #007bff;
  border-color: #007bff;
}

.e-treeview .e-list-item.e-active.e-animation-active > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview .e-list-item.e-active.e-animation-active > .e-text-content {
  color: #212529;
}

.e-treeview .e-list-item.e-active.e-animation-active > .e-text-content .e-list-text {
  color: #212529;
}

.e-treeview .e-list-item.e-active.e-hover > .e-fullrow {
  background-color: #0069d9;
  border-color: #0069d9;
}

.e-treeview .e-list-item.e-editing.e-active > .e-fullrow,
.e-treeview .e-list-item.e-editing.e-hover > .e-fullrow {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview .e-list-item.e-disable > .e-text-content,
.e-treeview .e-list-item.e-disable > .e-fullrow {
  color: #6c757d;
}

.e-treeview .e-list-item.e-disable > .e-text-content > .e-icon-collapsible,
.e-treeview .e-list-item.e-disable > .e-text-content > .e-icon-expandable {
  color: #6c757d;
}

.e-treeview .e-sibling {
  border-left-color: #007bff;
  border-right-color: transparent;
}

.e-treeview .e-sibling::before {
  background: #adb5bd;
  border: 1px;
}

.e-treeview .e-popup .e-content {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview .e-popup.e-select .e-icons {
  border-color: transparent;
}

.e-treeview .e-popup .e-downtail::before {
  border-top-color: transparent;
}

.e-treeview .e-popup .e-downtail::after {
  border-top-color: transparent;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-hover > .e-text-content {
  background-color: #f2f4f6;
  border-color: #f2f4f6;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active > .e-text-content {
  background-color: #007bff;
  border-color: #007bff;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-active.e-hover > .e-text-content {
  background-color: #0069d9;
  border-color: #0069d9;
}

.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-active > .e-text-content,
.e-treeview:not(.e-fullrow-wrap) .e-list-item.e-editing.e-hover > .e-text-content {
  background-color: transparent;
  border-color: transparent;
}

.e-treeview.e-fullrow-wrap .e-text-content {
  border-color: transparent;
}

.e-treeview.e-drag-item {
  background-color: #e9ecef;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
}

.e-treeview.e-drag-item .e-icon-collapsible::before,
.e-treeview.e-drag-item .e-icon-expandable::before {
  font-size: 14px;
}

.e-treeview.e-drag-item .e-list-text {
  color: #212529;
  padding: 0px 8px 0px 4px;
}

.e-treeview.e-drag-item .e-text-content {
  -webkit-box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.25);
  border-radius: .5em;
  box-shadow: 0 0 3px 3px rgba(0, 0, 0, 0.25);
}

.e-treeview.e-drag-item .e-icons {
  color: #495057;
}

.e-treeview.e-drag-item .e-drop-count {
  background-color: #007bff;
  border-color: #007bff;
  color: #fff;
}

.e-treeview.e-drag-item.e-rtl .e-sibling {
  border-right-color: #007bff;
  border-left-color: transparent;
}

ejs-sidebar {
  display: none;
}

.e-sidebar-display {
  display: block;
}

.e-sidebar {
  -webkit-tap-highlight-color: transparent;
  background: #fff;
  height: 100%;
  overflow: auto;
  position: fixed;
  top: 0;
  transition: none;
  vertical-align: middle;
  visibility: hidden;
  will-change: transform;
}

.e-sidebar.e-right.e-open {
  transform: translateX(0%);
  transition: transform .5s ease;
  visibility: visible;
}

.e-sidebar.e-right.e-close {
  box-shadow: none;
  transform: translateX(100%);
  transition: transform .5s ease, visibility 500ms;
  visibility: hidden;
}

.e-sidebar.e-left.e-open {
  transform: translateX(0%);
  transition: transform .5s ease;
  visibility: visible;
}

.e-sidebar.e-left.e-transition.e-close, .e-sidebar.e-right.e-transition.e-close {
  transition: transform .5s ease, visibility 500ms;
}

.e-sidebar.e-left.e-close {
  box-shadow: none;
  transform: translateX(-100%);
  visibility: hidden;
}

.e-sidebar.e-right.e-close {
  box-shadow: none;
  transform: translateX(100%);
  transition: transform .5s ease, visibility 500ms;
  visibility: hidden;
}

.e-sidebar.e-right {
  border-left: 1px solid #dee2e6;
  left: auto;
  right: 0;
  top: 0;
}

.e-sidebar.e-left {
  border-right: 1px solid #dee2e6;
  left: 0;
  right: auto;
  top: 0;
}

.e-sidebar.e-left.e-close.e-dock {
  transform: translateX(0%);
  transition: width .5s ease, visibility 500ms;
  visibility: visible;
}

.e-sidebar.e-right.e-close.e-dock {
  transform: translateX(0%);
  transition: width .5s ease, visibility 500ms;
  visibility: visible;
}

.e-sidebar.e-left.e-open.e-disable-animation, .e-sidebar.e-right.e-open.e-disable-animation, .e-sidebar.e-right.e-close.e-disable-animation, .e-sidebar.e-left.e-close.e-disable-animation {
  transition: none;
}

.e-sidebar.e-visibility {
  visibility: hidden;
}

.e-sidebar.e-over {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
}

.e-sidebar-absolute {
  position: absolute;
}

.e-sidebar-context {
  overflow-x: hidden;
  position: relative;
}

.e-backdrop {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  top: 0;
  width: auto;
  z-index: 999;
}

.e-content-animation {
  transition: margin .5s ease, transform .5s ease;
}

.e-content-animation.e-overlay {
  box-sizing: border-box;
  overflow: auto;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.e-disable-interaction {
  pointer-events: none;
  -ms-touch-action: none;
      touch-action: none;
}

.e-sidebar-overflow {
  overflow-x: hidden !important;
}

.e-sidebar-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  left: 0;
  opacity: .5;
  pointer-events: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.e-content-placeholder.e-sidebar.e-placeholder-sidebar {
  left: 0;
  position: fixed;
  right: auto;
  top: 0;
  visibility: visible;
}

/*! component's theme wise override definitions and variables */
/*! component's theme wise override definitions and variables */
/*! menu layout */
.e-menu-wrapper ul.e-vertical .e-menu-item .e-caret::before,
.e-menu-container ul.e-vertical .e-menu-item .e-caret::before {
  content: '\e70b';
}

.e-menu-wrapper ul .e-menu-item .e-caret::before,
.e-menu-container ul .e-menu-item .e-caret::before {
  content: '\e744';
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret::before,
.e-menu-container .e-ul .e-menu-item .e-caret::before {
  content: '\e70b';
}

.e-menu-wrapper.e-hamburger .e-menu-header .e-menu-icon::before,
.e-menu-container.e-hamburger .e-menu-header .e-menu-icon::before {
  content: '\e7cd';
}

.e-menu-wrapper.e-hamburger.e-close-icon .e-menu-header .e-menu-icon::before,
.e-menu-container.e-hamburger.e-close-icon .e-menu-header .e-menu-icon::before {
  content: '\e60a';
}

.e-menu-wrapper.e-hamburger .e-vertical .e-menu-item .e-caret::before,
.e-menu-wrapper.e-hamburger .e-menu-item .e-caret::before,
.e-menu-container.e-hamburger .e-vertical .e-menu-item .e-caret::before,
.e-menu-container.e-hamburger .e-menu-item .e-caret::before {
  content: '\e744';
}

.e-menu-wrapper.e-rtl.e-hamburger ul.e-ul .e-caret::before,
.e-menu-container.e-rtl.e-hamburger ul.e-ul .e-caret::before {
  content: '\e744';
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before,
.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before,
.e-menu-container .e-menu-hscroll.e-hscroll .e-nav-right-arrow::before,
.e-menu-container .e-menu-hscroll.e-hscroll .e-nav-left-arrow::before {
  content: '\e70b';
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before,
.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before,
.e-menu-container .e-menu-vscroll.e-vscroll .e-nav-up-arrow::before,
.e-menu-container .e-menu-vscroll.e-vscroll .e-nav-down-arrow::before {
  content: '\e744';
}

.e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before,
.e-rtl.e-menu-container ul.e-vertical .e-caret::before {
  content: '\e71f';
}

.e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-rtl.e-menu-container ul.e-ul .e-caret::before {
  content: '\e71f';
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper ul.e-vertical .e-caret::before,
.e-bigger .e-rtl.e-menu-container ul.e-vertical .e-caret::before,
.e-bigger.e-rtl.e-menu-container ul.e-vertical .e-caret::before {
  content: '\e71f';
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-caret::before,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-caret::before {
  content: '\e71f';
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-ul .e-caret::before,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-ul .e-caret::before,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-ul .e-caret::before {
  content: '\e744';
}

/*! menu layout */
.e-menu-wrapper,
.e-menu-container {
  border: none;
  border-radius: 4px;
  display: inline-block;
  line-height: 0;
}

.e-menu-wrapper .e-menu-vscroll,
.e-menu-container .e-menu-vscroll {
  height: inherit;
}

.e-menu-wrapper ul,
.e-menu-container ul {
  font-weight: normal;
  list-style-image: none;
  list-style-position: outside;
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-size: 0;
  padding: 0;
  text-align: left;
  white-space: nowrap;
}

.e-menu-wrapper ul.e-ul,
.e-menu-wrapper ul.e-ul *,
.e-menu-container ul.e-ul,
.e-menu-container ul.e-ul * {
  box-sizing: border-box;
}

.e-menu-wrapper ul.e-ul:focus,
.e-menu-wrapper ul.e-ul *:focus,
.e-menu-container ul.e-ul:focus,
.e-menu-container ul.e-ul *:focus {
  outline: none;
}

.e-menu-wrapper ul.e-vertical,
.e-menu-container ul.e-vertical {
  min-width: 120px;
}

.e-menu-wrapper ul.e-vertical .e-menu-item,
.e-menu-container ul.e-vertical .e-menu-item {
  display: list-item;
}

.e-menu-wrapper ul.e-vertical .e-separator,
.e-menu-container ul.e-vertical .e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
}

.e-menu-wrapper ul.e-menu,
.e-menu-container ul.e-menu {
  display: inline-block;
}

.e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-menu-container ul.e-menu:not(.e-vertical) {
  padding: 0;
}

.e-menu-wrapper ul.e-menu:not(.e-vertical) .e-separator,
.e-menu-container ul.e-menu:not(.e-vertical) .e-separator {
  border-right-style: solid;
  border-right-width: 1px;
  padding: 0;
}

.e-menu-wrapper ul.e-menu .e-menu-item .e-menu-icon,
.e-menu-container ul.e-menu .e-menu-item .e-menu-icon {
  width: 1em;
}

.e-menu-wrapper ul .e-menu-item,
.e-menu-container ul .e-menu-item {
  cursor: pointer;
  position: relative;
  display: -ms-inline-flexbox;
  display: inline-flex;
  font-size: 14px;
  height: 30px;
  line-height: 30px;
  padding: 0 12px;
  vertical-align: top;
  white-space: nowrap;
}

.e-menu-wrapper ul .e-menu-item.e-menu-hide,
.e-menu-container ul .e-menu-item.e-menu-hide {
  display: none;
}

.e-menu-wrapper ul .e-menu-item.e-menu-header,
.e-menu-container ul .e-menu-item.e-menu-header {
  border-bottom-style: solid;
  border-bottom-width: 1px;
}

.e-menu-wrapper ul .e-menu-item .e-menu-url,
.e-menu-container ul .e-menu-item .e-menu-url {
  text-decoration: none;
}

.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-menu-container ul .e-menu-item .e-menu-icon {
  display: inline-block;
  vertical-align: middle;
}

.e-menu-wrapper ul .e-menu-item.e-separator,
.e-menu-container ul .e-menu-item.e-separator {
  cursor: auto;
  line-height: normal;
  pointer-events: none;
}

.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-menu-container ul .e-menu-item .e-menu-icon {
  font-size: 14px;
  height: auto;
  line-height: 30px;
  margin-right: 8px;
  text-align: center;
}

.e-menu-wrapper ul .e-menu-item .e-caret,
.e-menu-container ul .e-menu-item .e-caret {
  font-size: 9px;
  height: auto;
  line-height: 30px;
  position: absolute;
  right: 12px;
  top: 0;
  width: auto;
}

.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-right: 30px;
}

.e-menu-wrapper ul .e-menu-item.e-disabled,
.e-menu-container ul .e-menu-item.e-disabled {
  cursor: auto;
  opacity: 0.65;
  pointer-events: none;
}

.e-menu-wrapper .e-ul,
.e-menu-container .e-ul {
  font-size: 14px;
  padding: 0;
  min-width: 120px;
}

.e-menu-wrapper .e-ul .e-menu-item,
.e-menu-container .e-ul .e-menu-item {
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  display: list-item;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-url,
.e-menu-container .e-ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-icon,
.e-menu-container .e-ul .e-menu-item .e-menu-icon {
  font-size: 14px;
  line-height: 26px;
  margin-right: 8px;
  width: 1em;
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret,
.e-menu-container .e-ul .e-menu-item .e-caret {
  line-height: 26px;
  margin-left: 16px;
  margin-right: 0;
  position: absolute;
  right: 14px;
}

.e-menu-wrapper .e-ul .e-menu-item.e-menu-caret-icon,
.e-menu-container .e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-menu-wrapper .e-ul .e-menu-item.e-separator,
.e-menu-container .e-ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
  margin: 6px 0;
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret,
.e-menu-container .e-ul .e-menu-item .e-caret {
  font-size: 9px;
}

.e-menu-wrapper.e-menu-icon-right .e-menu-header .e-menu-icon,
.e-menu-container.e-menu-icon-right .e-menu-header .e-menu-icon {
  float: right;
}

.e-menu-wrapper.e-menu-icon-right .e-menu-header .e-menu-title,
.e-menu-container.e-menu-icon-right .e-menu-header .e-menu-title {
  padding: 0 16px;
}

.e-menu-wrapper .e-menu-header,
.e-menu-container .e-menu-header {
  display: none;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  font-weight: normal;
  height: 30px;
  line-height: 30px;
  text-align: left;
  white-space: nowrap;
}

.e-menu-wrapper .e-menu-header .e-menu-title,
.e-menu-wrapper .e-menu-header .e-menu-icon,
.e-menu-container .e-menu-header .e-menu-title,
.e-menu-container .e-menu-header .e-menu-icon {
  display: inline-block;
  line-height: inherit;
}

.e-menu-wrapper .e-menu-header .e-menu-icon,
.e-menu-container .e-menu-header .e-menu-icon {
  cursor: pointer;
  float: left;
  outline: none;
  padding: 0 16px;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
  padding: 4px 32px;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  width: 32px;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus, .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:focus,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav:hover {
  border: 0;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-right-nav,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-right-nav {
  border-left: none;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-left-nav,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav.e-scroll-left-nav {
  border-right: none;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-hscroll-bar,
.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-hscroll-bar {
  overflow-y: hidden;
}

.e-menu-wrapper .e-scroll-nav .e-nav-arrow,
.e-menu-container .e-scroll-nav .e-nav-arrow {
  font-size: 10px;
}

.e-menu-wrapper .e-scroll-nav .e-nav-arrow.e-nav-left-arrow,
.e-menu-container .e-scroll-nav .e-nav-arrow.e-nav-left-arrow {
  transform: rotate(180deg);
}

.e-menu-wrapper .e-scroll-nav .e-nav-arrow.e-nav-up-arrow,
.e-menu-container .e-scroll-nav .e-nav-arrow.e-nav-up-arrow {
  transform: rotate(180deg);
}

.e-menu-wrapper.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-left-arrow,
.e-menu-container.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-left-arrow {
  transform: rotate(0deg);
}

.e-menu-wrapper.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-right-arrow,
.e-menu-container.e-rtl .e-scroll-nav .e-nav-arrow.e-nav-right-arrow {
  transform: rotate(180deg);
}

.e-menu-wrapper.e-popup.e-menu-popup,
.e-menu-container.e-popup.e-menu-popup {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
  overflow: hidden;
  position: absolute;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-menu-container .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 16px 0;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav,
.e-menu-container .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav {
  transform: none;
  width: 100%;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav .e-nav-down-arrow,
.e-menu-container .e-menu-vscroll.e-vscroll.e-scroll-device .e-scroll-nav.e-scroll-down-nav .e-nav-down-arrow {
  transform: none;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar,
.e-menu-container .e-menu-vscroll.e-vscroll .e-vscroll-bar {
  height: 100%;
  width: inherit;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-vscroll-bar .e-vscroll-content,
.e-menu-container .e-menu-vscroll.e-vscroll .e-vscroll-bar .e-vscroll-content {
  width: inherit;
}

.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-menu-container .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 16px;
}

.e-menu-wrapper.e-scrollable,
.e-menu-container.e-scrollable {
  display: block;
}

.e-menu-wrapper.e-scrollable .e-menu,
.e-menu-container.e-scrollable .e-menu {
  display: block;
  overflow: auto;
}

.e-menu-wrapper.e-contextmenu-container,
.e-menu-container.e-contextmenu-container {
  height: 1px;
}

.e-menu-wrapper.e-contextmenu-container .e-menu-vscroll ul.e-ul,
.e-menu-container.e-contextmenu-container .e-menu-vscroll ul.e-ul {
  width: inherit;
}

.e-menu-wrapper.e-contextmenu-container .e-menu-vscroll,
.e-menu-container.e-contextmenu-container .e-menu-vscroll {
  border: 1px solid rgba(0, 0, 0, 0.15);
  box-shadow: none;
}

.e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu.e-vertical .e-blankicon {
  padding-left: 34px;
}

.e-rtl.e-menu-wrapper ul .e-menu-item,
.e-rtl.e-menu-container ul .e-menu-item {
  text-align: right;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container ul .e-menu-item .e-menu-icon {
  margin-right: 0;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-rtl.e-menu-container ul .e-menu-item .e-caret {
  margin-left: 0;
  margin-right: 16px;
  right: auto;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}

.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-rtl.e-menu-container ul .e-menu-item .e-caret {
  left: 12px;
}

.e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-rtl.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-left: 30px;
  padding-right: 12px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-caret {
  left: 14px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 20px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 14px;
}

.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-rtl.e-menu-container:not(.e-hamburger) .e-menu.e-vertical .e-blankicon {
  padding-right: 34px;
}

.e-bigger .e-menu-wrapper ul,
.e-bigger.e-menu-wrapper ul,
.e-bigger .e-menu-container ul,
.e-bigger.e-menu-container ul {
  font-size: 16px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item,
.e-bigger.e-menu-wrapper ul .e-menu-item,
.e-bigger .e-menu-container ul .e-menu-item,
.e-bigger.e-menu-container ul .e-menu-item {
  height: 40px;
  line-height: 40px;
  padding: 0 16px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger .e-menu-container ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-container ul .e-menu-item .e-menu-icon {
  font-size: 16px;
  line-height: 40px;
  margin-right: 12px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-menu-container ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-right: 35px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-menu-container ul .e-menu-item .e-caret,
.e-bigger.e-menu-container ul .e-menu-item .e-caret {
  font-size: 12px;
  line-height: 40px;
  right: 15px;
}

.e-bigger .e-menu-wrapper ul .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul .e-menu-item.e-separator,
.e-bigger .e-menu-container ul .e-menu-item.e-separator,
.e-bigger.e-menu-container ul .e-menu-item.e-separator {
  padding: 0;
}

.e-bigger .e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-bigger.e-menu-wrapper ul.e-menu:not(.e-vertical),
.e-bigger .e-menu-container ul.e-menu:not(.e-vertical),
.e-bigger.e-menu-container ul.e-menu:not(.e-vertical) {
  padding: 0;
}

.e-bigger .e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger .e-menu-container ul.e-menu.e-vertical .e-menu-item.e-separator,
.e-bigger.e-menu-container ul.e-menu.e-vertical .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-bigger .e-menu-wrapper ul.e-ul,
.e-bigger.e-menu-wrapper ul.e-ul,
.e-bigger .e-menu-container ul.e-ul,
.e-bigger.e-menu-container ul.e-ul {
  font-size: 16px;
  padding: 8px 0;
  white-space: nowrap;
  max-width: 280px;
  min-width: 112px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item,
.e-bigger .e-menu-container ul.e-ul .e-menu-item,
.e-bigger.e-menu-container ul.e-ul .e-menu-item {
  height: 32px;
  line-height: 32px;
  padding: 0 20px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  font-size: 16px;
  line-height: 32px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-caret {
  line-height: 32px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-separator,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-separator,
.e-bigger .e-menu-container ul.e-ul .e-menu-item.e-separator,
.e-bigger.e-menu-container ul.e-ul .e-menu-item.e-separator {
  height: auto;
  line-height: normal;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-caret {
  font-size: 9px;
  right: 14px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-right: 36px;
}

.e-bigger .e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger .e-menu-container ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  margin-right: 8px;
}

.e-bigger .e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu.e-vertical .e-blankicon,
.e-bigger.e-menu-container:not(.e-hamburger):not(.e-rtl) .e-menu.e-vertical .e-blankicon {
  padding-left: 44px;
}

.e-bigger .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger .e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device),
.e-bigger.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) {
  padding: 0 36px;
}

.e-bigger .e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger.e-menu-wrapper .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger .e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav,
.e-bigger.e-menu-container .e-menu-hscroll.e-hscroll:not(.e-scroll-device) .e-scroll-nav {
  width: 36px;
}

.e-bigger .e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger.e-menu-wrapper .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger .e-menu-container .e-menu-vscroll.e-vscroll:not(.e-scroll-device),
.e-bigger.e-menu-container .e-menu-vscroll.e-vscroll:not(.e-scroll-device) {
  padding: 24px 0;
}

.e-bigger .e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger.e-menu-wrapper .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger .e-menu-container .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-bigger.e-menu-container .e-menu-vscroll.e-vscroll .e-scroll-nav {
  height: 24px;
}

.e-bigger .e-menu-wrapper.e-menu-popup,
.e-bigger.e-menu-wrapper.e-menu-popup,
.e-bigger .e-menu-container.e-menu-popup,
.e-bigger.e-menu-container.e-menu-popup {
  box-shadow: none;
}

.e-bigger .e-menu-wrapper .e-scroll-nav .e-icons,
.e-bigger.e-menu-wrapper .e-scroll-nav .e-icons,
.e-bigger .e-menu-container .e-scroll-nav .e-icons,
.e-bigger.e-menu-container .e-scroll-nav .e-icons {
  font-size: 10px;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item {
  text-align: right;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item .e-menu-icon,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item .e-menu-icon {
  margin-left: 12px;
  margin-right: 0;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item .e-caret,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item .e-caret {
  left: 15px;
  margin-left: 0;
  right: auto;
}

.e-bigger .e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container ul .e-menu-item.e-menu-caret-icon {
  padding-left: 35px;
  padding-right: 16px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-menu-icon {
  margin-left: 8px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item .e-caret,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item .e-caret {
  left: 14px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-menu-caret-icon {
  padding-left: 36px;
  padding-right: 20px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon {
  padding-left: 14px;
}

.e-bigger .e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container ul.e-ul .e-menu-item.e-blankicon.e-menu-caret-icon {
  padding-left: 36px;
}

.e-bigger .e-rtl.e-menu-container:not(.e-hamburger) .e-menu.e-vertical .e-blankicon,
.e-bigger.e-rtl.e-menu-container:not(.e-hamburger) .e-menu.e-vertical .e-blankicon {
  padding-right: 44px;
}

.e-menu-wrapper.e-hamburger,
.e-menu-container.e-hamburger {
  border: 0;
  display: block;
  position: relative;
}

.e-menu-wrapper.e-hamburger .e-menu-header:not(.e-vertical),
.e-menu-container.e-hamburger .e-menu-header:not(.e-vertical) {
  border: none;
  display: block;
}

.e-menu-wrapper.e-hamburger .e-popup.e-menu-popup,
.e-menu-container.e-hamburger .e-popup.e-menu-popup {
  border: 0;
  border-radius: 0;
  box-shadow: none;
  display: block;
  position: relative;
  width: 100%;
}

.e-menu-wrapper.e-hamburger ul.e-menu,
.e-menu-container.e-hamburger ul.e-menu {
  border: none;
  overflow-y: auto;
  width: 100%;
}

.e-menu-wrapper.e-hamburger ul.e-menu.e-menu-parent.e-hide-menu,
.e-menu-container.e-hamburger ul.e-menu.e-menu-parent.e-hide-menu {
  display: none;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  text-indent: 12px;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 34px;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-menu-icon {
  display: inline;
  text-indent: 0;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-hide,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-menu-hide {
  display: none;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item[aria-expanded="true"] .e-caret,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item[aria-expanded="true"] .e-caret {
  transition: transform .3s ease-in-out;
  transform: rotate(-180deg);
  text-indent: 0;
}

.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item[aria-expanded="false"] .e-caret,
.e-menu-container.e-hamburger ul.e-menu .e-menu-item[aria-expanded="false"] .e-caret {
  transition: transform .3s ease-in-out;
  transform: rotate(0deg);
  text-indent: 0;
}

.e-menu-wrapper.e-hamburger ul.e-menu:not(.e-vertical),
.e-menu-container.e-hamburger ul.e-menu:not(.e-vertical) {
  border-top: 0;
  display: block;
  padding: 0;
  position: absolute;
}

.e-menu-wrapper.e-hamburger ul.e-ul,
.e-menu-container.e-hamburger ul.e-ul {
  font-size: 14px;
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item,
.e-menu-container.e-hamburger ul.e-ul .e-menu-item {
  line-height: 30px;
  text-indent: inherit;
}

.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item.e-blankicon,
.e-menu-container.e-hamburger ul.e-ul .e-menu-item.e-blankicon {
  padding: 0;
  text-indent: inherit;
}

.e-menu-wrapper.e-hamburger ul.e-ul .e-menu-item .e-caret,
.e-menu-container.e-hamburger ul.e-ul .e-menu-item .e-caret {
  font-size: 9px;
  right: 12px;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item,
.e-menu-container.e-hamburger ul .e-menu-item {
  display: list-item;
  height: auto;
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item.e-menu-caret-icon,
.e-menu-container.e-hamburger ul .e-menu-item.e-menu-caret-icon {
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-url,
.e-menu-container.e-hamburger ul .e-menu-item .e-menu-url {
  display: inline-block;
  min-width: 120px;
  text-indent: 0;
  width: 100%;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item.e-blankicon,
.e-menu-container.e-hamburger ul .e-menu-item.e-blankicon {
  padding: 0;
}

.e-menu-wrapper.e-hamburger ul .e-menu-item.e-separator,
.e-menu-container.e-hamburger ul .e-menu-item.e-separator {
  border-bottom-style: solid;
  border-bottom-width: 1px;
  height: auto;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-caret-icon,
.e-rtl.e-menu-container.e-hamburger ul .e-menu-item .e-menu-caret-icon {
  padding-left: 0;
  padding-right: 0;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-menu-icon,
.e-rtl.e-menu-container.e-hamburger ul .e-menu-item .e-menu-icon {
  margin-left: 0;
  text-indent: inherit;
}

.e-rtl.e-menu-wrapper.e-hamburger ul .e-menu-item .e-caret,
.e-rtl.e-menu-container.e-hamburger ul .e-menu-item .e-caret {
  left: 12px;
  right: auto;
}

.e-bigger .e-menu-wrapper.e-hamburger ul .e-ul,
.e-bigger.e-menu-wrapper.e-hamburger ul .e-ul,
.e-bigger .e-menu-container.e-hamburger ul .e-ul,
.e-bigger.e-menu-container.e-hamburger ul .e-ul {
  max-width: 100%;
}

.e-bigger .e-menu-wrapper.e-hamburger ul .e-ul .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul .e-ul .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul .e-ul .e-menu-item,
.e-bigger.e-menu-container.e-hamburger ul .e-ul .e-menu-item {
  height: auto;
  line-height: 40px;
  padding: 0;
}

.e-bigger .e-menu-wrapper.e-hamburger .e-menu-header,
.e-bigger.e-menu-wrapper.e-hamburger .e-menu-header,
.e-bigger .e-menu-container.e-hamburger .e-menu-header,
.e-bigger.e-menu-container.e-hamburger .e-menu-header {
  font-size: 16px;
  height: 40px;
  line-height: 40px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  text-indent: 16px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 44px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul {
  font-size: 16px;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item {
  text-indent: inherit;
}

.e-bigger .e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger .e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret,
.e-bigger.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item .e-caret {
  font-size: 12px;
  right: 15px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item {
  padding: 0;
  text-indent: 16px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-blankicon {
  text-indent: 44px;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-menu-icon,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-menu-icon {
  margin-left: 0;
  text-indent: inherit;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item.e-menu-caret-icon {
  padding-left: 0;
  padding-right: 0;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-caret,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-menu-item .e-caret {
  left: 12px;
  right: auto;
}

.e-bigger .e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-rtl.e-menu-wrapper.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger .e-rtl.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item,
.e-bigger.e-rtl.e-menu-container.e-hamburger ul.e-menu .e-ul .e-menu-item {
  text-indent: inherit;
}

/*! menu theme */
.e-menu-wrapper,
.e-menu-container {
  background-color: transparent;
}

.e-menu-wrapper .e-menu,
.e-menu-container .e-menu {
  background-color: #fff;
  color: #007bff;
}

.e-menu-wrapper .e-menu.e-menu:not(.e-vertical) .e-separator,
.e-menu-container .e-menu.e-menu:not(.e-vertical) .e-separator {
  border-right-color: #e9ecef;
}

.e-menu-wrapper .e-menu .e-menu-item .e-caret,
.e-menu-container .e-menu .e-menu-item .e-caret {
  color: #007bff;
}

.e-menu-wrapper .e-menu .e-menu-item .e-menu-icon,
.e-menu-container .e-menu .e-menu-item .e-menu-icon {
  color: #007bff;
}

.e-menu-wrapper .e-menu .e-menu-item.e-menu-header,
.e-menu-container .e-menu .e-menu-item.e-menu-header {
  border-bottom-color: #e9ecef;
}

.e-menu-wrapper .e-menu .e-menu-item .e-menu-url,
.e-menu-container .e-menu .e-menu-item .e-menu-url {
  color: #007bff;
}

.e-menu-wrapper .e-menu .e-menu-item.e-focused,
.e-menu-container .e-menu .e-menu-item.e-focused {
  color: #0056b3;
  outline: 0 solid #f8f9fa;
  outline-offset: 0;
  background-color: transparent;
}

.e-menu-wrapper .e-menu .e-menu-item.e-focused .e-caret,
.e-menu-container .e-menu .e-menu-item.e-focused .e-caret {
  color: #007bff;
}

.e-menu-wrapper .e-menu .e-menu-item.e-focused .e-menu-icon,
.e-menu-container .e-menu .e-menu-item.e-focused .e-menu-icon {
  color: #0056b3;
}

.e-menu-wrapper .e-menu .e-menu-item.e-selected,
.e-menu-container .e-menu .e-menu-item.e-selected {
  color: #0056b3;
  outline: 0 solid #f2f4f6;
  outline-offset: 0;
  background-color: transparent;
}

.e-menu-wrapper .e-menu .e-menu-item.e-selected .e-caret,
.e-menu-container .e-menu .e-menu-item.e-selected .e-caret {
  color: #007bff;
}

.e-menu-wrapper .e-menu .e-menu-item.e-selected .e-menu-icon,
.e-menu-container .e-menu .e-menu-item.e-selected .e-menu-icon {
  color: #007bff;
}

.e-menu-wrapper .e-menu .e-menu-item.e-separator,
.e-menu-container .e-menu .e-menu-item.e-separator {
  border-bottom-color: #e9ecef;
}

.e-menu-wrapper .e-menu .e-disabled,
.e-menu-container .e-menu .e-disabled {
  color: #6c757d;
  opacity: 1;
}

.e-menu-wrapper .e-menu .e-disabled .e-menu-icon,
.e-menu-container .e-menu .e-disabled .e-menu-icon {
  color: #6c757d;
}

.e-menu-wrapper .e-menu .e-disabled .e-caret,
.e-menu-container .e-menu .e-disabled .e-caret {
  color: #6c757d;
}

.e-menu-wrapper .e-menu .e-disabled .e-menu-url,
.e-menu-container .e-menu .e-disabled .e-menu-url {
  color: #6c757d;
}

.e-menu-wrapper .e-ul,
.e-menu-container .e-ul {
  background-color: inherit;
  color: #212529;
}

.e-menu-wrapper .e-ul .e-menu-item .e-caret,
.e-menu-container .e-ul .e-menu-item .e-caret {
  color: #212529;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-icon,
.e-menu-container .e-ul .e-menu-item .e-menu-icon {
  color: #212529;
}

.e-menu-wrapper .e-ul .e-menu-item.e-focused .e-caret,
.e-menu-container .e-ul .e-menu-item.e-focused .e-caret {
  color: #007bff;
}

.e-menu-wrapper .e-ul .e-menu-item.e-focused .e-menu-icon,
.e-menu-container .e-ul .e-menu-item.e-focused .e-menu-icon {
  color: #212529;
}

.e-menu-wrapper .e-ul .e-menu-item.e-selected .e-caret,
.e-menu-container .e-ul .e-menu-item.e-selected .e-caret {
  color: #fff;
}

.e-menu-wrapper .e-ul .e-menu-item.e-selected .e-menu-icon,
.e-menu-container .e-ul .e-menu-item.e-selected .e-menu-icon {
  color: #fff;
}

.e-menu-wrapper .e-ul .e-menu-item .e-menu-url,
.e-menu-container .e-ul .e-menu-item .e-menu-url {
  color: #212529;
}

.e-menu-wrapper .e-ul .e-menu-item.e-focused,
.e-menu-container .e-ul .e-menu-item.e-focused {
  background-color: #f2f4f6;
  color: #212529;
  outline: 0 solid #e9ecef;
  outline-offset: 0;
}

.e-menu-wrapper .e-ul .e-menu-item.e-selected,
.e-menu-container .e-ul .e-menu-item.e-selected {
  background-color: #007bff;
  color: #fff;
  outline: 0 solid #007bff;
  outline-offset: 0;
}

.e-menu-wrapper .e-ul .e-menu-item.e-separator,
.e-menu-container .e-ul .e-menu-item.e-separator {
  border-bottom-color: #e9ecef;
}

.e-menu-wrapper.e-menu-popup,
.e-menu-container.e-menu-popup {
  background-color: #fff;
}

.e-menu-wrapper.e-menu-popup.e-sfcontextmenu .e-menu-vscroll,
.e-menu-container.e-menu-popup.e-sfcontextmenu .e-menu-vscroll {
  background-color: inherit;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav {
  background: transparent;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav .e-nav-arrow.e-icons,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav .e-nav-arrow.e-icons {
  color: #212529;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:hover,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:hover {
  background-color: #5a6268;
  border: 1px inset #545b62;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:hover .e-icons,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:hover .e-icons {
  color: #fff;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:focus,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:focus {
  background-color: #5a6268;
  border-color: #545b62;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:focus .e-icons,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:focus .e-icons {
  color: #fff;
}

.e-menu-wrapper .e-menu-hscroll.e-hscroll .e-scroll-nav:active,
.e-menu-container .e-menu-hscroll.e-hscroll .e-scroll-nav:active {
  background-color: #5a6268;
  border: 1px inset #545b62;
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav {
  background: #fff;
  border-color: #e9ecef;
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav .e-icons,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav .e-icons {
  color: #212529;
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:hover, .e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:focus,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:hover,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:focus {
  background: #f2f4f6;
}

.e-menu-wrapper.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:active,
.e-menu-container.e-menu-popup .e-menu-vscroll.e-vscroll .e-scroll-nav:active {
  background: #007bff;
}

.e-menu-wrapper.e-hamburger .e-menu-header,
.e-menu-container.e-hamburger .e-menu-header {
  color: #212529;
}

.e-menu-wrapper.e-hamburger .e-menu-header .e-menu-icon,
.e-menu-container.e-hamburger .e-menu-header .e-menu-icon {
  color: #212529;
}

.e-menu-wrapper.e-hamburger ul,
.e-menu-container.e-hamburger ul {
  color: #212529;
}

.e-menu-wrapper.e-hamburger ul .e-selected .e-caret,
.e-menu-wrapper.e-hamburger ul .e-caret,
.e-menu-container.e-hamburger ul .e-selected .e-caret,
.e-menu-container.e-hamburger ul .e-caret {
  color: #212529;
}

/*! breadcrumb icons */
.e-breadcrumb .e-breadcrumb-collapsed::before,
.e-breadcrumb .e-breadcrumb-menu::before {
  content: '\e71b';
}

.e-breadcrumb .e-home::before {
  content: '\e92b';
}

/*! breadcrumb layout */
.e-breadcrumb {
  display: block;
  background-color: #e9ecef;
  border-radius: 4px;
}

.e-breadcrumb.e-breadcrumb-wrap-mode {
  display: -ms-flexbox;
  display: flex;
}

.e-breadcrumb .e-breadcrumb-first-ol {
  -ms-flex-align: start;
      align-items: flex-start;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  padding-right: 0;
}

.e-breadcrumb .e-breadcrumb-wrapped-ol {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  overflow: hidden;
  padding-left: 0;
}

.e-breadcrumb.e-breadcrumb-scroll-mode {
  line-height: normal;
  overflow: auto;
}

.e-breadcrumb ol {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-inline-flexbox;
  display: inline-flex;
  line-height: normal;
  margin: 0;
  padding: 0;
  padding-left: 16px;
  padding-right: 16px;
}

.e-breadcrumb li {
  list-style-type: none;
  white-space: nowrap;
}

.e-breadcrumb .e-breadcrumb-item {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  padding: 10px 0;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  -ms-flex-align: inherit;
      align-items: inherit;
  display: inherit;
}

.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text {
  cursor: pointer;
  text-decoration: none;
}

.e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:hover, .e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:focus, .e-breadcrumb .e-breadcrumb-item a.e-breadcrumb-text:active {
  text-decoration: underline;
}

.e-breadcrumb .e-breadcrumb-item span.e-breadcrumb-text {
  cursor: default;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon {
  font-size: 14px;
  padding-right: 8px;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon:hover {
  text-decoration: none;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon::before {
  display: inline-block;
  text-decoration: none;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon.e-icons.e-home {
  font-size: 12px;
  margin-top: -1px;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-icon {
  padding: 3px 0;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item {
  margin-left: -16px;
  margin-right: -6px;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text {
  padding: 10px 11px;
}

.e-breadcrumb .e-breadcrumb-item.e-disabled {
  opacity: 1;
  pointer-events: none;
}

.e-breadcrumb .e-breadcrumb-separator {
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  padding: 10px 8px;
}

.e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator {
  margin-left: -7px;
  padding-left: 0;
}

.e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
  margin-left: 0;
  margin-right: -7px;
  padding-left: 8px;
  padding-right: 0;
}

.e-breadcrumb .e-breadcrumb-collapsed,
.e-breadcrumb .e-breadcrumb-menu {
  cursor: pointer;
  font-size: 16px;
  padding: 8px 8px;
  vertical-align: bottom;
}

.e-breadcrumb .e-breadcrumb-menu {
  display: inline-block;
}

.e-breadcrumb .e-breadcrumb-item-wrapper {
  display: -ms-flexbox;
  display: flex;
}

.e-breadcrumb.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb .e-icon-right .e-breadcrumb-icon, .e-breadcrumb.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}

.e-breadcrumb.e-rtl.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-breadcrumb.e-disabled {
  opacity: 1;
}

.e-breadcrumb.e-disabled .e-breadcrumb-item,
.e-breadcrumb.e-disabled .e-breadcrumb-separator,
.e-breadcrumb.e-disabled .e-breadcrumb-collapsed,
.e-breadcrumb.e-disabled .e-breadcrumb-menu {
  pointer-events: none;
}

.e-breadcrumb-popup {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  box-shadow: none;
  position: absolute;
}

.e-breadcrumb-popup ul {
  margin: 0;
  padding: 0;
}

.e-breadcrumb-popup .e-breadcrumb-item {
  list-style-type: none;
  white-space: nowrap;
}

.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text {
  -ms-flex-align: center;
      align-items: center;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  height: 26px;
  line-height: 26px;
  padding: 0 20px;
  width: 100%;
}

.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text .e-anchor-wrap {
  -ms-flex-align: inherit;
      align-items: inherit;
  display: inherit;
  width: inherit;
}

.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-icon {
  font-size: 14px;
  padding-right: 8px;
}

.e-breadcrumb-popup .e-breadcrumb-item.e-icon-item .e-breadcrumb-icon {
  padding: 0;
}

.e-breadcrumb-popup .e-breadcrumb-item a.e-breadcrumb-text {
  text-decoration: none;
}

.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon, .e-breadcrumb-popup.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}

.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-positive: 1;
      flex-grow: 1;
  -ms-flex-pack: end;
      justify-content: flex-end;
}

.e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon,
.e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb-popup .e-breadcrumb-text,
.e-bigger .e-breadcrumb-popup .e-breadcrumb-text {
  font-size: 16px;
  height: 32px;
  line-height: 32px;
}

.e-bigger.e-breadcrumb-popup .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-breadcrumb-text .e-breadcrumb-icon {
  font-size: 16px;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb-popup .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
  padding: 0;
}

.e-bigger.e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-bigger.e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon, .e-bigger.e-breadcrumb-popup.e-rtl .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup .e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}

.e-bigger.e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon,
.e-bigger.e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl.e-icon-right .e-breadcrumb-icon,
.e-bigger .e-breadcrumb-popup.e-rtl .e-icon-right .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  padding: 9px 0;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text {
  padding: 10px 11px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  font-size: 16px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon {
  font-size: 16px;
  padding-right: 8px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon.e-icons.e-home,
.e-bigger .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon.e-icons.e-home {
  font-size: 13px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text .e-breadcrumb-icon {
  padding: 3px 0;
}

.e-bigger.e-breadcrumb .e-breadcrumb-separator,
.e-bigger .e-breadcrumb .e-breadcrumb-separator {
  font-size: 16px;
  padding: 9px 8px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator,
.e-bigger .e-breadcrumb .e-breadcrumb-separator + .e-breadcrumb-separator {
  padding-left: 0;
}

.e-bigger.e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator,
.e-bigger .e-breadcrumb.e-rtl .e-breadcrumb-separator + .e-breadcrumb-separator {
  padding-left: 8px;
}

.e-bigger.e-breadcrumb .e-breadcrumb-collapsed,
.e-bigger.e-breadcrumb .e-breadcrumb-menu,
.e-bigger .e-breadcrumb .e-breadcrumb-collapsed,
.e-bigger .e-breadcrumb .e-breadcrumb-menu {
  font-size: 18px;
  padding: 8px 8px;
}

.e-bigger.e-breadcrumb.e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger.e-breadcrumb .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon, .e-bigger.e-breadcrumb.e-rtl .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-rtl .e-breadcrumb-text .e-breadcrumb-icon {
  padding-left: 8px;
  padding-right: 0;
}

.e-bigger.e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon,
.e-bigger .e-breadcrumb.e-rtl .e-icon-right .e-breadcrumb-text .e-breadcrumb-icon {
  padding-left: 0;
  padding-right: 8px;
}

/*! breadcrumb theme */
.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text {
  color: #007bff;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:hover, .e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:focus {
  color: #0056b3;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-text:active {
  color: #004a99;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item:hover {
  background-color: #dee2e6;
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item:active {
  background-color: #ced4da;
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus {
  background-color: #dee2e6;
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-item[data-active-item] .e-breadcrumb-text {
  color: #6c757d;
  font-weight: normal;
}

.e-breadcrumb .e-breadcrumb-item .e-breadcrumb-icon {
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-item.e-icon-item:hover .e-breadcrumb-icon, .e-breadcrumb .e-breadcrumb-item.e-icon-item:active .e-breadcrumb-icon,
.e-breadcrumb .e-breadcrumb-item.e-icon-item .e-breadcrumb-text:focus .e-breadcrumb-icon {
  color: #6610f2;
}

.e-breadcrumb .e-breadcrumb-separator {
  color: #6c757d;
}

.e-breadcrumb .e-breadcrumb-collapsed,
.e-breadcrumb .e-breadcrumb-menu {
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-collapsed:hover,
.e-breadcrumb .e-breadcrumb-menu:hover {
  background-color: #dee2e6;
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-collapsed:active,
.e-breadcrumb .e-breadcrumb-menu:active {
  background-color: #ced4da;
  color: #495057;
}

.e-breadcrumb .e-breadcrumb-collapsed:focus,
.e-breadcrumb .e-breadcrumb-menu:focus {
  background-color: #dee2e6;
  color: #495057;
}

.e-breadcrumb.e-disabled .e-breadcrumb-item .e-breadcrumb-text,
.e-breadcrumb .e-disabled.e-breadcrumb-item .e-breadcrumb-text,
.e-breadcrumb.e-disabled .e-breadcrumb-separator {
  color: #adb5bd;
}

.e-breadcrumb.e-disabled .e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-breadcrumb .e-disabled.e-breadcrumb-item .e-breadcrumb-text .e-breadcrumb-icon,
.e-breadcrumb.e-disabled .e-breadcrumb-separator .e-breadcrumb-icon {
  color: #adb5bd;
}

.e-breadcrumb-popup {
  background-color: #fff;
}

.e-breadcrumb-popup .e-breadcrumb-text {
  color: #6c757d;
}

.e-breadcrumb-popup .e-breadcrumb-text:hover {
  background-color: #f2f4f6;
  color: #212529;
}

.e-breadcrumb-popup .e-breadcrumb-item .e-breadcrumb-text:focus {
  background-color: #f2f4f6;
  color: #212529;
}
