.e-richtexteditor {
  border-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:focus .e-icons,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:hover .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn {
  border-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn:active,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-control.e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn:active {
  border: 1px solid #4e555b;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn:hover,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-btn:hover {
  border: 1px solid #545b62;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-btn:focus {
  border: 1px solid #6c757d;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-dropdown-btn {
  border: 1px solid transparent;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-dropdown-btn.e-active {
  border-radius: 4px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-control.e-tbar-btn.e-dropdown-btn.e-active .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn.e-icon-btn {
  padding: 0 2px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:active,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn:hover {
  border: 1px solid transparent;
  padding: 0 2px;
}

.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-inline-dropdown.e-control.e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-inline-dropdown.e-control.e-tbar-btn.e-btn {
  border: 1px solid transparent;
  padding-bottom: 1px;
  padding-top: 1px;
}

.e-richtexteditor .e-rte-content {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.e-richtexteditor .e-rte-table-popup.e-popup.e-popup-open {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

.e-richtexteditor .e-rte-table-popup.e-popup.e-popup-open .e-insert-table-btn .e-btn-icon.e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
  font-size: 14px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-extended.e-toolbar-pop .e-toolbar-item.e-toolbar-popup .e-tbar-btn {
  padding: 0 5px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn.e-rte-dropdown-items.e-active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-items.e-rte-dropdown-btn:hover {
  padding-left: 5px;
  padding-right: 5px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn.e-rte-dropdown-items {
  border: 1px solid transparent;
  padding-left: 5px;
  padding-right: 5px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-control.e-tbar-btn.e-btn.e-icon-btn {
  padding: 0 5px;
}

.e-bigger .e-richtexteditor .e-rte-content .e-content,
.e-bigger .e-richtexteditor .e-source-content .e-content {
  font-size: 16px;
}

.e-rte-quick-popup.e-popup {
  border-radius: 4px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-rte-color-content {
  line-height: 26px;
  min-height: 26px;
  min-width: 26px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-btn-icon.e-caret {
  font-size: 8px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-rte-color-content {
  line-height: 32px;
  min-height: 26px;
  min-width: 32px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn.e-rte-inline-dropdown .e-btn-icon.e-caret {
  font-size: 10px;
}

.e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown {
  padding-left: 1px;
  padding-right: 1px;
}

.e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown.e-active:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown.e-active:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown.e-active:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown:hover, .e-bigger .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-backgroundcolor-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-fontcolor-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-numberformatlist-dropdown.e-active:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown:hover,
.e-bigger .e-rte-toolbar.e-extended-toolbar .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-bulletformatlist-dropdown.e-active:hover {
  padding-left: 1px;
  padding-right: 1px;
}

.e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown {
  padding-bottom: 0;
  padding-top: 0;
}

.e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover, .e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active, .e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn:hover,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-tbar-btn.e-btn.e-active:hover {
  padding-bottom: 0;
  padding-top: 0;
}

.e-rte-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-items .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-popup .e-rte-bulletformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-backgroundcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-fontcolor-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-numberformatlist-dropdown.e-active,
.e-rte-toolbar.e-extended-toolbar .e-toolbar-extended .e-rte-bulletformatlist-dropdown.e-active {
  border-radius: 4px;
}

/*! component's theme wise override definitions and variables */
/*! richtexteditor icons */
.e-rte-toolbar .e-alignments::before,
.e-rte-dropdown-popup .e-alignments::before {
  content: '\e76f';
}

.e-rte-toolbar .e-justify-left::before,
.e-rte-dropdown-popup .e-justify-left::before {
  content: '\e76f';
}

.e-rte-toolbar .e-justify-center::before,
.e-rte-dropdown-popup .e-justify-center::before {
  content: '\e732';
}

.e-rte-toolbar .e-justify-right::before,
.e-rte-dropdown-popup .e-justify-right::before {
  content: '\e746';
}

.e-rte-toolbar .e-justify-full::before,
.e-rte-dropdown-popup .e-justify-full::before {
  content: '\e79b';
}

.e-rte-toolbar .e-font-name::before,
.e-rte-dropdown-popup .e-font-name::before {
  content: '\e74b';
}

.e-rte-toolbar .e-font-color::before,
.e-rte-dropdown-popup .e-font-color::before {
  content: '\e80e';
}

.e-rte-toolbar .e-background-color::before,
.e-rte-dropdown-popup .e-background-color::before {
  content: '\e754';
}

.e-rte-toolbar .e-bold::before,
.e-rte-dropdown-popup .e-bold::before {
  content: '\e78b';
}

.e-rte-toolbar .e-italic::before,
.e-rte-dropdown-popup .e-italic::before {
  content: '\e78e';
}

.e-rte-toolbar .e-underline::before,
.e-rte-dropdown-popup .e-underline::before {
  content: '\e792';
}

.e-rte-toolbar .e-strike-through::before,
.e-rte-dropdown-popup .e-strike-through::before {
  content: '\e786';
}

.e-rte-toolbar .e-clear-format::before,
.e-rte-dropdown-popup .e-clear-format::before {
  content: '\e80c';
}

.e-rte-toolbar .e-clear-all::before,
.e-rte-dropdown-popup .e-clear-all::before {
  content: '\e703';
}

.e-rte-toolbar .e-cut::before,
.e-rte-dropdown-popup .e-cut::before {
  content: '\e73f';
}

.e-rte-toolbar .e-copy::before,
.e-rte-dropdown-popup .e-copy::before {
  content: '\e77b';
}

.e-rte-toolbar .e-paste::before,
.e-rte-dropdown-popup .e-paste::before {
  content: '\e739';
}

.e-rte-toolbar .e-unorder-list::before,
.e-rte-dropdown-popup .e-unorder-list::before {
  content: '\e72a';
}

.e-rte-toolbar .e-order-list::before,
.e-rte-dropdown-popup .e-order-list::before {
  content: '\e72c';
}

.e-rte-toolbar .e-indent::before,
.e-rte-dropdown-popup .e-indent::before {
  content: '\e702';
}

.e-rte-toolbar .e-outdent::before,
.e-rte-dropdown-popup .e-outdent::before {
  content: '\e722';
}

.e-rte-toolbar .e-undo::before,
.e-rte-dropdown-popup .e-undo::before {
  content: '\e752';
}

.e-rte-toolbar .e-redo::before,
.e-rte-dropdown-popup .e-redo::before {
  content: '\e778';
}

.e-rte-toolbar .e-super-script::before,
.e-rte-dropdown-popup .e-super-script::before {
  content: '\e779';
}

.e-rte-toolbar .e-sub-script::before,
.e-rte-dropdown-popup .e-sub-script::before {
  content: '\e707';
}

.e-rte-toolbar .e-create-link::before,
.e-rte-dropdown-popup .e-create-link::before {
  content: '\e72e';
}

.e-rte-toolbar .e-open-link::before,
.e-rte-dropdown-popup .e-open-link::before {
  content: '\e785';
}

.e-rte-toolbar .e-edit-link::before,
.e-rte-dropdown-popup .e-edit-link::before {
  content: '\e78f';
}

.e-rte-toolbar .e-remove-link::before,
.e-rte-dropdown-popup .e-remove-link::before {
  content: '\e794';
}

.e-rte-toolbar .e-image::before,
.e-rte-dropdown-popup .e-image::before {
  content: '\e776';
}

.e-rte-toolbar .e-replace::before,
.e-rte-dropdown-popup .e-replace::before {
  content: '\e80d';
}

.e-rte-toolbar .e-align::before,
.e-rte-dropdown-popup .e-align::before {
  content: '\e76f';
}

.e-rte-toolbar .e-caption::before,
.e-rte-dropdown-popup .e-caption::before {
  content: '\e795';
}

.e-rte-toolbar .e-remove::before,
.e-rte-dropdown-popup .e-remove::before {
  content: '\e773';
}

.e-rte-toolbar .e-insert-link::before,
.e-rte-dropdown-popup .e-insert-link::before {
  content: '\e72e';
}

.e-rte-toolbar .e-display::before,
.e-rte-dropdown-popup .e-display::before {
  content: '\e788';
}

.e-rte-toolbar .e-alt-text::before,
.e-rte-dropdown-popup .e-alt-text::before {
  content: '\e80b';
}

.e-rte-toolbar .e-img-dimension::before,
.e-rte-dropdown-popup .e-img-dimension::before {
  content: '\e791';
}

.e-rte-toolbar .e-maximize::before,
.e-rte-dropdown-popup .e-maximize::before {
  content: '\e771';
}

.e-rte-toolbar .e-minimize::before,
.e-rte-dropdown-popup .e-minimize::before {
  content: '\e723';
}

.e-rte-toolbar .e-zoom-in::before,
.e-rte-dropdown-popup .e-zoom-in::before {
  content: '\e70a';
}

.e-rte-toolbar .e-zoom-out::before,
.e-rte-dropdown-popup .e-zoom-out::before {
  content: '\e74a';
}

.e-rte-toolbar .e-lower-case::before,
.e-rte-dropdown-popup .e-lower-case::before {
  content: '\e789';
}

.e-rte-toolbar .e-upper-case::before,
.e-rte-dropdown-popup .e-upper-case::before {
  content: '\e78a';
}

.e-rte-toolbar .e-print::before,
.e-rte-dropdown-popup .e-print::before {
  content: '\e743';
}

.e-rte-toolbar .e-formats::before,
.e-rte-dropdown-popup .e-formats::before {
  content: '\e720';
}

.e-rte-toolbar .e-source-code::before,
.e-rte-dropdown-popup .e-source-code::before {
  content: '\e790';
}

.e-rte-toolbar .e-preview::before,
.e-rte-dropdown-popup .e-preview::before {
  content: '\e787';
}

.e-rte-toolbar .e-view-side::before,
.e-rte-dropdown-popup .e-view-side::before {
  content: '\e796';
}

.e-rte-toolbar .e-insert-code::before,
.e-rte-dropdown-popup .e-insert-code::before {
  content: '\e793';
}

.e-rte-toolbar .e-table-header::before,
.e-rte-dropdown-popup .e-table-header::before {
  content: '\e80a';
}

.e-rte-toolbar .e-table-remove::before,
.e-rte-dropdown-popup .e-table-remove::before {
  content: '\e773';
}

.e-rte-toolbar .e-table-rows::before,
.e-rte-dropdown-popup .e-table-rows::before {
  content: '\e75c';
}

.e-rte-toolbar .e-table-columns::before,
.e-rte-dropdown-popup .e-table-columns::before {
  content: '\e782';
}

.e-rte-toolbar .e-table-cell-ver-align::before,
.e-rte-dropdown-popup .e-table-cell-ver-align::before {
  content: '\e706';
}

.e-rte-toolbar .e-table-edit-properties::before,
.e-rte-dropdown-popup .e-table-edit-properties::before {
  content: '\e78f';
}

.e-rte-toolbar .e-create-table::before,
.e-rte-dropdown-popup .e-create-table::before {
  content: '\e763';
}

.e-rte-toolbar .e-align-bottom::before,
.e-rte-dropdown-popup .e-align-bottom::before {
  content: '\e726';
}

.e-rte-toolbar .e-align-middle::before,
.e-rte-dropdown-popup .e-align-middle::before {
  content: '\e706';
}

.e-rte-toolbar .e-align-top::before,
.e-rte-dropdown-popup .e-align-top::before {
  content: '\e709';
}

.e-rte-toolbar .e-insert-column-left::before,
.e-rte-dropdown-popup .e-insert-column-left::before {
  content: '\e737';
}

.e-rte-toolbar .e-insert-column-right::before,
.e-rte-dropdown-popup .e-insert-column-right::before {
  content: '\e70e';
}

.e-rte-toolbar .e-delete-column::before,
.e-rte-dropdown-popup .e-delete-column::before {
  content: '\e719';
}

.e-rte-toolbar .e-insert-row-before::before,
.e-rte-dropdown-popup .e-insert-row-before::before {
  content: '\e783';
}

.e-rte-toolbar .e-insert-row-after::before,
.e-rte-dropdown-popup .e-insert-row-after::before {
  content: '\e736';
}

.e-rte-toolbar .e-delete-row::before,
.e-rte-dropdown-popup .e-delete-row::before {
  content: '\e738';
}

.e-rte-toolbar .e-style::before,
.e-rte-dropdown-popup .e-style::before {
  content: '\e71a';
}

.e-rte-toolbar .e-rte-file-manager::before,
.e-rte-dropdown-popup .e-rte-file-manager::before {
  content: '\e70f';
}

.e-richtexteditor .e-south-east::before,
.e-richtexteditor .e-south-west::before {
  content: '\e7cf';
}

.e-cell-merge::before {
  content: '\e89a';
}

.e-cell-horizontal-split::before {
  content: '\e89b';
}

.e-cell-vertical-split::before {
  content: '\e89d';
}

.e-table-cell::before {
  content: '\e89c';
}

/*! tab layout */
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 3px;
}

.e-bigger .e-richtexteditor.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-richtexteditor.e-bigger.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 3px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  padding-left: 3px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 18px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-background-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color .e-selected-color.e-icons::before {
  top: -20px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-dropdown-btn.e-rte-dropdown-btn {
  padding-left: 6px;
  padding-right: 6px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:hover,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:focus,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn.e-btn.e-control.e-rte-dropdown-btn.e-dropdown-btn:active {
  padding-left: 6px;
  padding-right: 6px;
}

.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-bigger .e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn {
  padding: 0 5px;
}

.e-bigger .e-richtexteditor.e-rte-full-screen iframe,
.e-richtexteditor.e-bigger.e-rte-full-screen iframe {
  height: calc(100% - 56px);
}

.e-richtexteditor {
  color: #212529;
  display: block;
  position: relative;
}

.e-richtexteditor textarea.e-content {
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  outline: 0;
  padding: 8px;
  resize: none;
  width: 100%;
}

.e-richtexteditor .e-rte-hidden {
  display: none;
}

.e-richtexteditor.e-disabled {
  pointer-events: none;
}

.e-richtexteditor.e-rte-full-screen {
  bottom: 0;
  height: 100% !important;
  left: 0;
  overflow: auto;
  position: fixed;
  right: 0;
  top: 0;
  width: 100% !important;
  z-index: 999;
}

.e-richtexteditor.e-rte-full-screen .e-resize-handle {
  display: none;
}

.e-richtexteditor.e-rte-full-screen iframe {
  height: calc(100% - 42px);
}

.e-richtexteditor.e-rtl .e-rte-character-count {
  left: 0;
  padding-left: 30px;
  padding-right: unset;
  right: unset;
}

.e-richtexteditor.e-rtl .e-rte-content .e-content blockquote {
  padding-left: 0;
  padding-right: 5px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
  margin-left: 6px;
}

.e-richtexteditor .e-rte-toolbar .e-hor-nav.e-expended-nav {
  height: auto;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-multirow {
  margin-left: 7px;
  margin-right: 7px;
}

.e-richtexteditor.e-rtl .e-rte-toolbar .e-toolbar-items:not(.e-tbar-pos):not(.e-toolbar-multirow) .e-toolbar-item:first-child {
  margin-left: 0;
  margin-right: 6px;
}

.e-richtexteditor.e-rtl .e-rte-toolbar .e-toolbar-multirow:not(.e-tbar-pos) .e-toolbar-item:first-child {
  margin-right: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-mobile .e-toolbar-multirow {
  margin-left: 0;
  margin-right: 0;
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.e-richtexteditor .e-rte-toolbar,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar {
  position: inherit;
  z-index: 101;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended {
  margin-left: 0;
  padding-left: 6px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-formats-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-formats-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-formats-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-formats-tbar-btn > :first-child {
  min-width: 75px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-name-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-name-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-name-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-name-tbar-btn > :first-child {
  min-width: 67px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-size-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-size-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-size-tbar-btn > :first-child,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-size-tbar-btn > :first-child {
  min-width: 36px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn {
  padding-left: 8px;
  padding-right: 2px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-rte-dropdown-btn-text {
  font-weight: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn:active,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:hover,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:focus,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn:active {
  padding-left: 8px;
  padding-right: 2px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color .e-selected-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color .e-selected-color.e-icons::before {
  font-size: 12px;
  position: relative;
  top: -18px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-font-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-font-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-font-color.e-icons::before {
  font-size: 13px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown .e-rte-list-primary-content,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown .e-rte-list-primary-content {
  line-height: 1;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-background-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-background-color.e-icons::before,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-background-color.e-icons::before {
  display: inline;
  font-size: 14px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret),
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 16px;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn.e-btn,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn.e-btn {
  padding: 0 2px;
}

.e-richtexteditor .e-rte-toolbar.e-control[class*='e-toolbar'],
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-control[class*='e-toolbar'] {
  box-sizing: border-box;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended.e-visible,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended.e-visible {
  display: block;
  visibility: hidden;
}

.e-richtexteditor .e-rte-toolbar.e-tbar-ios-fixed.e-rte-tb-fixed,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-tbar-ios-fixed.e-rte-tb-fixed {
  top: 0;
  transform: translate3d(0, 0, 0);
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed:not(.e-tbar-ios-fixed),
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed:not(.e-tbar-ios-fixed) {
  bottom: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed {
  display: none;
  left: 0;
  position: fixed;
  right: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-fixed.e-show,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-fixed.e-show {
  display: block;
  z-index: 101;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-float,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-float {
  position: fixed;
  top: 0;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-float.e-rte-tb-abs-float,
.e-richtexteditor .e-rte-toolbar.e-toolbar.e-extended-toolbar.e-rte-tb-float.e-rte-tb-abs-float {
  position: absolute;
}

.e-richtexteditor .rte-placeholder {
  color: #212529;
  line-height: 18px;
  opacity: 00.54;
  overflow: hidden;
  position: absolute;
  text-align: start;
  top: 0;
  z-index: 1;
}

.e-richtexteditor .e-rte-content {
  position: relative;
  z-index: 1;
}

.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  transition: 100ms ease-out;
  width: 100%;
}

.e-richtexteditor .e-rte-content .e-content,
.e-richtexteditor .e-source-content .e-content {
  background: unset;
  box-sizing: border-box;
  height: 100%;
  min-height: 100px;
  outline: 0 solid transparent;
  overflow-x: auto;
  padding: 16px;
  position: relative;
  text-align: inherit;
  z-index: 2;
}

@media screen and (min-width: 992px) {
  .e-richtexteditor .e-rte-content .e-content,
  .e-richtexteditor .e-source-content .e-content {
    font-size: 14px;
  }
}

.e-richtexteditor .e-rte-content .e-content p,
.e-richtexteditor .e-source-content .e-content p {
  margin: 0 0 10px;
  margin-bottom: 10px;
}

.e-richtexteditor .e-rte-content .e-content li,
.e-richtexteditor .e-source-content .e-content li {
  margin-bottom: 10px;
}

.e-richtexteditor .e-rte-content .e-content h1,
.e-richtexteditor .e-source-content .e-content h1 {
  font-size: 2.17em;
  font-weight: 400;
  line-height: 1;
  margin: 10px 0;
}

.e-richtexteditor .e-rte-content .e-content h2,
.e-richtexteditor .e-source-content .e-content h2 {
  font-size: 1.74em;
  font-weight: 400;
  margin: 10px 0;
}

.e-richtexteditor .e-rte-content .e-content h3,
.e-richtexteditor .e-source-content .e-content h3 {
  font-size: 1.31em;
  font-weight: 400;
  margin: 10px 0;
}

.e-richtexteditor .e-rte-content .e-content h4,
.e-richtexteditor .e-source-content .e-content h4 {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
}

.e-richtexteditor .e-rte-content .e-content h5,
.e-richtexteditor .e-source-content .e-content h5 {
  font-size: 00.8em;
  font-weight: 400;
  margin: 0;
}

.e-richtexteditor .e-rte-content .e-content h6,
.e-richtexteditor .e-source-content .e-content h6 {
  font-size: 00.65em;
  font-weight: 400;
  margin: 0;
}

.e-richtexteditor .e-rte-content .e-content blockquote,
.e-richtexteditor .e-source-content .e-content blockquote {
  margin: 10px 0;
  margin-left: 0;
  padding-left: 5px;
}

.e-richtexteditor .e-rte-content .e-content pre,
.e-richtexteditor .e-source-content .e-content pre {
  background-color: inherit;
  border: 0;
  border-radius: 0;
  color: #212529;
  font-size: inherit;
  line-height: inherit;
  margin: 0 0 10px;
  overflow: visible;
  padding: 0;
  white-space: pre-wrap;
  word-break: inherit;
  word-wrap: break-word;
}

.e-richtexteditor .e-rte-content .e-content strong,
.e-richtexteditor .e-rte-content .e-content b,
.e-richtexteditor .e-source-content .e-content strong,
.e-richtexteditor .e-source-content .e-content b {
  font-weight: 700;
}

.e-richtexteditor .e-rte-content .e-content a,
.e-richtexteditor .e-source-content .e-content a {
  text-decoration: none;
  -webkit-user-select: auto;
      -ms-user-select: auto;
          user-select: auto;
}

.e-richtexteditor .e-rte-content .e-content a:hover,
.e-richtexteditor .e-source-content .e-content a:hover {
  text-decoration: underline;
}

.e-richtexteditor .e-rte-content .e-content p:last-child,
.e-richtexteditor .e-rte-content .e-content pre:last-child,
.e-richtexteditor .e-rte-content .e-content blockquote:last-child,
.e-richtexteditor .e-source-content .e-content p:last-child,
.e-richtexteditor .e-source-content .e-content pre:last-child,
.e-richtexteditor .e-source-content .e-content blockquote:last-child {
  margin-bottom: 0;
}

.e-richtexteditor .e-rte-content .e-content h3 + h4,
.e-richtexteditor .e-rte-content .e-content h4 + h5,
.e-richtexteditor .e-rte-content .e-content h5 + h6,
.e-richtexteditor .e-source-content .e-content h3 + h4,
.e-richtexteditor .e-source-content .e-content h4 + h5,
.e-richtexteditor .e-source-content .e-content h5 + h6 {
  margin-top: 00.6em;
}

.e-richtexteditor .e-rte-content .e-content ul:last-child,
.e-richtexteditor .e-source-content .e-content ul:last-child {
  margin-bottom: 0;
}

.e-richtexteditor .e-rte-character-count {
  bottom: 0;
  color: #6c757d;
  font-family: 'Times New Roman', Georgia, Serif;
  font-size: 14px;
  margin-right: 30px;
  opacity: 00.54;
  padding-bottom: 2px;
  position: absolute;
  right: 0;
  z-index: 100;
}

.e-richtexteditor .e-rte-character-count.e-warning {
  color: #ffc107;
  opacity: unset;
}

.e-richtexteditor .e-rte-character-count.e-error {
  color: #dc3545;
  opacity: unset;
}

.e-richtexteditor .e-rte-srctextarea {
  background-color: transparent;
  border: 0;
  color: #212529;
  display: block;
  height: 100%;
  line-height: 22px;
  overflow: auto;
  padding: 16px;
  resize: none;
  transition: 100ms ease-out;
  width: 100%;
}

.e-richtexteditor .e-resize-handle {
  height: 15px;
  position: absolute;
  width: 15px;
}

.e-richtexteditor .e-resize-handle.e-south-east {
  bottom: 0;
  cursor: nwse-resize;
  right: 0;
  z-index: 100;
}

.e-richtexteditor .e-resize-handle.e-south-west {
  bottom: 0;
  cursor: sw-resize;
  left: 0;
  transform: rotate(90deg);
  z-index: 100;
}

.e-richtexteditor.e-rtl.e-rte-resize {
  float: right;
}

.e-richtexteditor.e-rtl .e-resize-handle.e-south-west {
  direction: ltr;
  text-align: initial;
}

.e-richtexteditor.e-rte-tb-expand.e-rte-fixed-tb-expand {
  transition: none;
}

.e-rte-linkcontent .e-rte-label {
  padding-top: 14px;
}

.e-rte-label label {
  font-weight: normal;
}

.e-rte-linkcontent .e-rte-label:first-child {
  padding-top: 0;
}

.e-bigger .e-rte-dropdown-popup ul {
  min-width: 84px;
}

@media screen and (max-width: 768px) {
  .e-rte-dropdown-popup.e-rte-inline-dropdown ul {
    padding: 7px 0;
  }
  .e-rte-dropdown-popup.e-rte-inline-dropdown ul li.e-item {
    height: 34px;
    line-height: 34px;
    padding: 0 6.5px;
  }
  .e-rte-dropdown-popup.e-rte-inline-dropdown ul .e-item .e-menu-icon {
    float: none;
  }
}

@media screen and (max-width: 768px) {
  .e-rte-dropdown-popup.e-rte-inline-dropdown.e-rte-dropdown-icons.e-dropdown-popup {
    max-height: 280px;
  }
  .e-rte-dropdown-popup.e-rte-inline-dropdown.e-rte-dropdown-icons.e-dropdown-popup ul {
    display: -ms-inline-flexbox;
    display: inline-flex;
  }
}

.e-rte-dropdown-popup ul {
  min-width: 82px;
}

.e-rte-dropdown-popup ul .e-item .e-menu-icon {
  margin: 0 6px;
  width: auto;
}

.e-rte-dropdown-popup ul .e-item.e-h1 {
  font-size: 2em;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
}

.e-rte-dropdown-popup ul .e-item.e-h2 {
  font-size: 1.5em;
  font-weight: bold;
  height: 40px;
  line-height: 40px;
}

.e-rte-dropdown-popup ul .e-item.e-h3 {
  font-size: 1.16em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-h4 {
  font-size: 1em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-h5 {
  font-size: .83em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-h6 {
  font-size: .7em;
  font-weight: bold;
}

.e-rte-dropdown-popup ul .e-item.e-segoe-ui {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-arial {
  font-family: Arial, Helvetica, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-courier-new {
  font-family: Courier New, Courier, monospace;
}

.e-rte-dropdown-popup ul .e-item.e-georgia {
  font-family: Georgia, 'Times New Roman', Times, serif;
}

.e-rte-dropdown-popup ul .e-item.e-helvetica-neue {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-impact {
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-lucida-console {
  font-family: Lucida Console, Monaco, monospace;
}

.e-rte-dropdown-popup ul .e-item.e-tahoma {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-times-new-roman {
  font-family: 'Times New Roman', Times, serif;
}

.e-rte-dropdown-popup ul .e-item.e-trebuchet-ms {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-verdana {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.e-rte-dropdown-popup ul .e-item.e-roboto {
  font-family: 'Roboto', 'Segoe UI', 'GeezaPro', 'DejaVu Serif', sans-serif;
}

.e-rte-dropdown-popup.e-rte-dropdown-icons ul {
  min-width: auto;
}

@media screen and (max-width: 768px) {
  .e-rte-dropdown-popup.e-rte-dropdown-icons ul {
    min-width: 30px;
  }
}

.e-rte-dropdown-popup.e-rtl ul .e-item .e-menu-icon {
  margin: 0 6px;
}

.e-rte-image.e-imgbreak {
  border: 0;
  cursor: pointer;
  display: block;
  float: none;
  max-width: 100%;
  padding: 1px;
  position: relative;
}

.e-rte-image {
  border: 0;
  cursor: pointer;
  display: block;
  float: none;
  margin: auto;
  max-width: 100%;
  position: relative;
}

.e-rte-image.e-imginline {
  display: inline-block;
  float: none;
  max-width: calc(100% - (2 * 5px));
  padding: 1px;
  vertical-align: bottom;
}

.e-rte-image.e-imgcenter {
  cursor: pointer;
  display: block;
  float: none;
  margin: 5px auto;
  max-width: 100%;
  position: relative;
}

.e-rte-image.e-imgleft {
  float: left;
  margin: 0 auto;
  margin-right: 5px;
  text-align: left;
}

.e-rte-image.e-imgright {
  float: right;
  margin: 0 auto;
  margin-left: 5px;
  text-align: right;
}

.e-rte-img-popup .e-item {
  height: 106px;
  width: 300px;
}

.e-rte-img-caption {
  display: inline-block;
  margin: 5px auto;
  max-width: 100%;
  position: relative;
}

.e-rte-img-caption.e-caption-inline {
  display: inline-block;
  margin: 5px auto;
  margin-left: 5px;
  margin-right: 5px;
  max-width: calc(100% - (2 * 5px));
  position: relative;
  text-align: center;
  vertical-align: bottom;
}

.e-rte-img-caption.e-imgcenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.e-rte-img-caption.e-imgright {
  display: block;
  margin-left: auto;
  margin-right: 0;
}

.e-rte-img-caption.e-imgleft {
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.e-rte-img-caption .e-rte-image.e-imgright {
  float: none;
  margin-left: auto;
  margin-right: 0;
}

.e-rte-img-caption .e-rte-image.e-imgleft {
  float: none;
  margin: 0;
}

.e-img-caption.e-rte-img-caption.e-imgbreak {
  display: block;
}

.e-rte-table {
  border-collapse: collapse;
  empty-cells: show;
}

.e-rte-table td,
.e-rte-table th {
  border: 1px solid #adb5bd;
  height: 20px;
  min-width: 20px;
  padding: 2px 5px;
  vertical-align: middle;
}

.e-rte-table.e-dashed-border td,
.e-rte-table.e-dashed-border th {
  border-style: dashed;
}

.e-rte-img-caption .e-img-inner {
  box-sizing: border-box;
  display: block;
  font-size: 16px;
  font-weight: initial;
  margin: auto;
  opacity: .9;
  position: relative;
  text-align: center;
  width: 100%;
}

.e-rte-img-caption.e-imgleft .e-img-inner {
  text-align: left;
}

.e-rte-img-caption.e-imgright .e-img-inner {
  text-align: right;
}

.e-rte-img-caption .e-img-wrap {
  display: inline-block;
  margin: auto;
  padding: 0;
  width: 100%;
}

.e-rte-img-dialog .e-rte-label {
  padding-top: 14px;
}

.e-rte-img-dialog .e-rte-label:first-child {
  padding-top: 0;
}

.e-rte-table-resize.e-row-resize,
.e-rte-table-resize.e-column-resize {
  background-color: transparent;
  background-repeat: repeat;
  bottom: 0;
  cursor: col-resize;
  height: 1px;
  overflow: visible;
  position: absolute;
  width: 1px;
}

.e-rte-table-resize.e-row-resize {
  cursor: row-resize;
  height: 1px;
}

.e-richtexteditor .e-linkheader {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  opacity: .87;
  padding-bottom: 14px;
  padding-top: 14px;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-delete-btn.e-icons,
.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons {
  margin-top: 0;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-file-abort-btn.e-icons {
  margin-right: 30px;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list {
  border-bottom: 0 solid transparent;
  min-height: initial;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files .e-upload-file-list .e-file-container {
  margin-left: 0;
  margin-right: 0;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload .e-upload-files {
  border-top: 0 solid transparent;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-file-select-wrap {
  display: none;
}

.e-richtexteditor .e-img-uploadwrap.e-droparea .e-upload {
  border: 0 solid transparent;
  float: none;
}

.e-richtexteditor .e-dialog .e-img-uploadwrap.e-droparea .e-browsebtn {
  display: block;
  height: 36px;
  margin: 0 auto;
  padding: 0 18px;
  position: relative;
  top: -50px;
}

.e-richtexteditor .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-browsebtn {
  display: none;
}

.e-richtexteditor .e-dialog .e-img-uploadwrap.e-droparea {
  line-height: 10;
  min-height: 50px;
  position: relative;
}

.e-richtexteditor .e-dialog .e-img-uploadwrap.e-droparea .e-rte-upload-text {
  display: inline-block;
  line-height: normal;
}

.e-richtexteditor .e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea {
  line-height: 4;
}

.e-richtexteditor .e-rte-inline-dropdown ul {
  max-height: 200px;
  overflow-y: auto;
}

.e-richtexteditor .e-rte-dropdown-popup.e-rte-dropdown-items ul {
  max-height: 280px;
  overflow-y: auto;
}

.e-richtexteditor .e-bigger .e-rte-dropdown-popup.e-rte-dropdown-items ul {
  max-height: 360px;
  overflow-y: auto;
}

.e-richtexteditor .e-rte-inline-dropdown.e-rte-backgroundcolor-dropdown,
.e-richtexteditor .e-rte-inline-dropdown.e-rte-fontcolor-dropdown,
.e-richtexteditor .e-rte-inline-dropdown.e-rte-numberformatlist-dropdown,
.e-richtexteditor .e-rte-inline-dropdown.e-rte-bulletformatlist-dropdown {
  line-height: 0;
}

.e-richtexteditor .e-rte-table-popup.e-popup.e-popup-open {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  font-weight: normal;
  min-width: 120px;
  overflow: hidden;
  padding: 10px;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open .e-rte-tablecell {
  border: 1px solid #dee2e6;
  display: inline-block;
  height: 14px;
  margin: 1px;
  overflow: hidden;
  vertical-align: top;
  width: 14px;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open .e-rte-table-row {
  height: 16px;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open .e-insert-table-btn {
  width: 100%;
}

.e-richtexteditor .e-rte-edit-table .e-rte-field {
  padding-top: 14px;
}

.e-richtexteditor .e-rte-edit-table .e-rte-field:first-child {
  padding-top: 0;
}

.e-richtexteditor .e-rte-content .e-content table td.e-cell-select,
.e-richtexteditor .e-rte-content .e-content table th.e-cell-select {
  border: 1px double #007bff;
  height: 24.67px;
}

.e-richtexteditor span.e-table-box {
  cursor: nwse-resize;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
}

.e-richtexteditor span.e-table-box.e-hide {
  display: none;
}

.e-richtexteditor span.e-table-box.e-rmob {
  height: 14px;
  width: 14px;
}

.e-richtexteditor .e-upload .e-upload-files .e-upload-file-list .e-file-container {
  margin-right: 50px;
}

.e-richtexteditor .e-rte-upload-popup {
  width: 250px;
}

.e-richtexteditor .e-rte-dialog-upload .e-upload-files {
  border-top: 0;
}

.e-richtexteditor .e-rte-dialog-upload .e-upload-files .e-upload-file-list {
  border-bottom: 0;
}

.e-rte-backgroundcolor-colorpicker .e-color-palette.e-container .e-custom-palette .e-palette,
.e-rte-fontcolor-colorpicker .e-color-palette.e-container .e-custom-palette .e-palette {
  padding: 0;
}

.e-rte-backgroundcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn,
.e-rte-fontcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn {
  padding: 5px;
}

.e-bigger .e-rte-backgroundcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn, .e-bigger
.e-rte-fontcolor-colorpicker .e-color-palette.e-container .e-switch-ctrl-btn {
  padding: 5px;
}

.e-rte-quick-popup {
  border-radius: 2px;
  overflow: hidden;
}

.e-rte-quick-popup .e-rte-quick-toolbar {
  border-radius: 2px;
  min-height: 42px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos).e-toolbar-multirow {
  white-space: nowrap;
}

.e-rte-quick-popup .e-rte-quick-toolbar.e-remove-white-space .e-toolbar-items:not(.e-tbar-pos).e-toolbar-multirow {
  white-space: nowrap;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) {
  border-radius: 2px;
  margin: 0 6px;
  min-height: 42px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-rte-horizontal-separator,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item.e-separator.e-multirow-separator.e-rte-horizontal-separator {
  display: block;
  height: 1px;
  margin: 0;
  min-height: 1px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item {
  margin: 0 6px;
  min-height: 42px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child, .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 6px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn:not(.e-rte-dropdown-btn) {
  line-height: 20px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn:not(.e-rte-dropdown-btn) .e-icons {
  min-width: 26px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn:hover {
  min-height: 32px;
  min-width: 32px;
  padding: 0;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 16px;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-background-color.e-icons::before {
  display: inline;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-rte-fontcolor-dropdown .e-btn-icon.e-caret,
.e-rte-quick-popup .e-rte-quick-toolbar .e-rte-backgroundcolor-dropdown .e-btn-icon.e-caret,
.e-rte-quick-popup .e-rte-quick-toolbar .e-rte-numberformatlist-dropdown .e-btn-icon.e-caret,
.e-rte-quick-popup .e-rte-quick-toolbar .e-rte-bulletformatlist-dropdown .e-btn-icon.e-caret {
  font-size: 10px;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn {
  line-height: 24px;
  margin: 4px 0;
  padding: 0;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-rte-dropdown-btn-text {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-order-list.e-icons,
.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-unorder-list.e-icons,
.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-icons:not(.e-caret) {
  font-size: 14px;
  margin-left: -3px;
}

.e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-caret {
  font-size: 8px;
  width: 12px;
}

.e-rte-quick-popup.e-hide {
  display: block;
  visibility: hidden;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar {
  min-height: 48px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) {
  margin: 0 6px;
  min-height: 48px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator) {
  margin: 0 6px;
  min-height: 48px;
  min-width: 36px;
  padding: 0;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn:not(.e-rte-dropdown-btn) {
  line-height: normal;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn,
.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-btn:hover {
  min-height: 38px;
  min-width: 38px;
  padding: 0;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:first-child,
.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:last-child {
  margin: 0 6px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn.e-btn.e-control {
  height: 38px;
  line-height: normal;
  margin: 0;
  min-height: 38px;
  min-width: 38px;
}

.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn .e-order-list.e-icons,
.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn .e-unorder-list.e-icons,
.e-bigger .e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item .e-tbar-btn .e-icons.e-btn-icon:not(.e-caret) {
  font-size: 18px;
}

.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn {
  line-height: 33px;
  margin: 4px 0;
  padding: 0;
}

.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-order-list.e-icons,
.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-unorder-list.e-icons,
.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-icons:not(.e-caret) {
  font-size: 16px;
}

.e-bigger .e-rte-quick-popup .e-rte-dropdown-btn.e-tbar-btn .e-caret {
  font-size: 10px;
  width: 20px;
}

.e-bigger .e-rte-quick-popup.e-rte-inline-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-rte-inline-template {
  min-width: 54px;
}

.e-bigger .e-rte-quick-popup.e-rte-inline-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-rte-inline-size-template {
  min-width: 63px;
}

.e-bigger .e-rte-quick-popup.e-rte-inline-popup .e-rte-quick-toolbar .e-toolbar-items:not(.e-tbar-pos) .e-toolbar-item:not(.e-separator).e-rte-inline-color-template {
  min-width: 56px;
}

.e-rte-quick-popup.e-rte-inline-popup .e-rte-quick-toolbar .e-toolbar-item.e-rte-inline-template {
  min-width: 62px;
}

.e-rte-quick-popup.e-rte-inline-popup .e-rte-quick-toolbar .e-toolbar-item.e-rte-inline-size-template {
  min-width: 71px;
}

.e-rte-quick-popup.e-rte-inline-popup .e-rte-quick-toolbar .e-toolbar-item.e-rte-inline-color-template {
  min-width: 60px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control {
  display: -ms-flexbox;
  display: flex;
  padding-left: 1px;
  padding-right: 1px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover, .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active, .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active, .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active:hover {
  padding-left: 1px;
  padding-right: 1px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content {
  position: relative;
  vertical-align: middle;
  width: 30px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content .e-rte-elements {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  padding-bottom: 1px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list {
  line-height: 28px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content::after {
  content: '';
  height: 100%;
  position: absolute;
  right: 0;
  width: 1px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
      flex-direction: column;
  -ms-flex-pack: center;
      justify-content: center;
  line-height: unset;
  min-width: 20px;
  text-align: center;
  width: 20px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content {
  line-height: 33px;
}

.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-btn-icon.e-caret,
.e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-btn-icon.e-caret,
.e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-btn-icon.e-caret,
.e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-btn-icon.e-caret {
  font-size: 8px;
}

.e-rte-inline-dropdown .e-rte-color-content .e-rte-elements {
  border-bottom-style: solid;
  border-bottom-width: 3px;
  line-height: 1;
  padding-bottom: 1px;
}

.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon {
  min-width: 25px;
  padding-left: 0;
  padding-right: 0;
  width: 25px;
}

.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover, .e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus, .e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:hover,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:focus,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control:active {
  padding-left: 1px;
  padding-right: 1px;
}

.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-order-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content .e-unorder-list,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control.e-active .e-rte-list-primary-content .e-unorder-list {
  line-height: 36px;
}

.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-fontcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-backgroundcolor-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-numberformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-icons.e-btn-icon,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-color-content,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item button.e-rte-bulletformatlist-dropdown.e-dropdown-btn.e-tbar-btn.e-btn.e-control .e-rte-list-primary-content {
  line-height: 40px;
}

.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-caret,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-items .e-toolbar-item .e-dropdown-btn .e-caret,
.e-bigger .e-rte-toolbar.e-toolbar.e-extended-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-caret,
.e-bigger .e-rte-toolbar.e-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn .e-caret {
  font-size: 10px;
}

.e-popup-modal.e-popup.e-popup-open {
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.e-rte-modal-popup.e-popup-container.e-center {
  -ms-flex-pack: center;
      justify-content: center;
}

.e-rte-modal-popup.e-popup-container {
  -ms-flex-align: center;
      align-items: center;
  display: none;
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10000;
}

.e-popup-overlay {
  height: 100%;
  left: 0;
  opacity: .5;
  position: absolute;
  top: 0;
  width: 100%;
}

.e-bigger .e-rte-table-popup.e-popup-open .e-rte-tablecell {
  height: 16px;
  width: 16px;
}

.e-bigger .e-rte-table-popup.e-popup-open .e-rte-table-row {
  height: 18px;
}

.e-table-rhelper {
  cursor: col-resize;
  opacity: .87;
  position: absolute;
}

.e-table-rhelper.e-column-helper {
  width: 1px;
}

.e-table-rhelper.e-row-helper {
  height: 1px;
}

.e-reicon::before {
  border-bottom: 6px solid transparent;
  border-right: 6px solid;
  border-top: 6px solid transparent;
  content: '';
  display: block;
  height: 0;
  position: absolute;
  right: 4px;
  top: 4px;
  width: 20px;
}

.e-reicon::after {
  border-bottom: 6px solid transparent;
  border-left: 6px solid;
  border-top: 6px solid transparent;
  content: '';
  display: block;
  height: 0;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 20px;
  z-index: 3;
}

.e-row-helper.e-reicon::after {
  top: 10px;
  transform: rotate(90deg);
}

.e-row-helper.e-reicon::before {
  left: 4px;
  top: -20px;
  transform: rotate(90deg);
}

.e-rte-overflow {
  overflow: hidden;
}

.e-rte-dialog-minheight {
  min-height: 296px;
}

.e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor {
  background-size: 700px 190px;
  min-height: 190px;
}

.e-bigger .e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor,
.e-bigger.e-content-placeholder.e-richtexteditor.e-placeholder-richtexteditor {
  background-size: 700px 190px;
  min-height: 190px;
}

.e-rte-table.e-rte-table-border {
  border: 1px solid #adb5bd;
  border-collapse: separate;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea {
  line-height: 10;
  min-height: 50px;
  position: relative;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-browsebtn {
  display: block;
  height: 36px;
  margin: 0 auto;
  padding: 0 18px;
  position: relative;
  top: -50px;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-upload {
  border: 0 solid transparent;
  float: none;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap.e-droparea .e-file-select-wrap {
  display: none;
}

.e-rte-img-dialog.e-dialog.e-device.e-dlg-modal .e-linkheader {
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 15px;
  opacity: .87;
  padding-bottom: 14px;
  padding-top: 14px;
}

.e-rte-file-manager-dialog .e-rte-label {
  padding-bottom: 8px;
  padding-top: 15px;
}

.e-rte-file-manager-dialog .e-rte-label label {
  font-size: 15px;
  opacity: .87;
}

.e-rte-upload-popup.e-dialog .e-file-select-wrap {
  display: none;
}

.e-rte-upload-popup.e-dialog .e-dlg-content {
  overflow: hidden;
  padding: 0;
}

.e-hide.e-rte-quick-popup-hide {
  border: 0;
  position: absolute;
}

.e-rte-popup-hide {
  display: none;
}

.e-rte-hide-visible {
  visibility: hidden;
}

.e-rte-table-popup.e-dialog .e-dlg-content {
  padding: 0;
}

.e-richtexteditor .e-rte-table-popup.e-popup-open.e-dialog .e-rte-tablecell {
  margin: 1px;
}

/*! tab layout */
.e-richtexteditor .e-rte-toolbar {
  border: 1px solid #dee2e6;
  border-bottom: 0;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-overlay .e-tbar-btn .e-icons {
  color: rgba(108, 117, 125, 0.3);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn:focus {
  background: #6c757d;
  border: 1px solid #4e555b;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item.e-active .e-tbar-btn:focus .e-icons {
  color: #212529;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-icons {
  color: #212529;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-active {
  background: #545b62;
  border-color: #4e555b;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-active .e-rte-dropdown-btn-text {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-color-content::after {
  background: #545b62;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:active:hover .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:active:hover .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-caret:active.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-rte-color-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:active:hover .e-rte-list-primary-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-caret:active.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-rte-color-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:active:hover .e-rte-list-primary-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:active:hover .e-caret:active.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:active:hover .e-rte-color-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:active:hover .e-rte-list-primary-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:active:hover .e-caret:active.e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:active:hover .e-rte-color-content:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:active:hover .e-rte-list-primary-content:active .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover {
  background: #6c757d;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-list-primary-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-list-primary-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-list-primary-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-list-primary-content:hover {
  background: #5a6268;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-list-primary-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-list-primary-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-color-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-list-primary-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-color-content:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-list-primary-content:focus {
  background: #6c757d;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-color-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-rte-list-primary-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-color-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-rte-list-primary-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-color-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-rte-list-primary-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-color-content:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-rte-list-primary-content:active {
  background: #545b62;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-caret:hover {
  background: #5a6268;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-caret:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-caret:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-caret:focus,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-caret:focus {
  background: #6c757d;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown:hover .e-caret:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown:hover .e-caret:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown:hover .e-caret:active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown:hover .e-caret:active {
  background: #545b62;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active {
  background: #545b62;
  border: 1px solid #6c757d;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active .e-rte-color-content::after,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active .e-rte-color-content::after {
  background: #4e555b;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:hover .e-rte-list-primary-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:hover .e-rte-list-primary-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active:hover .e-rte-list-primary-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active:hover .e-caret:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active:hover .e-rte-color-content:hover,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active:hover .e-rte-list-primary-content:hover {
  background: transparent;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-backgroundcolor-dropdown.e-active:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-fontcolor-dropdown.e-active:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-numberformatlist-dropdown.e-active:active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active .e-icons,
.e-richtexteditor .e-rte-toolbar .e-toolbar-item .e-rte-bulletformatlist-dropdown.e-active:active .e-icons {
  color: #fff;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
  color: #212529;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-active .e-tbar-btn .e-icons {
  color: #212529;
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item.e-overlay .e-tbar-btn .e-icons {
  color: rgba(108, 117, 125, 0.3);
}

.e-richtexteditor .e-rte-toolbar .e-toolbar-extended .e-toolbar-item .e-dropdown-btn.e-active {
  background: #545b62;
}

.e-richtexteditor .e-rte-toolbar.e-rte-tb-float {
  border: 1px solid #dee2e6;
}

.e-richtexteditor .e-rte-toolbar.e-tb-static {
  border-bottom: 1px solid #dee2e6;
}

.e-richtexteditor .e-toolbar-wrapper + .e-rte-toolbar:not(.e-rte-tb-float) {
  border: 0;
}

.e-richtexteditor img.e-img-focus::selection {
  background: transparent;
  color: transparent;
}

.e-richtexteditor .e-rte-content,
.e-richtexteditor .e-source-content {
  background: #fff;
  border: 1px solid #dee2e6;
  color: #212529;
}

.e-richtexteditor .e-rte-content .e-content blockquote,
.e-richtexteditor .e-source-content .e-content blockquote {
  border-left: solid 2px #212529;
}

.e-richtexteditor .e-rte-content .e-content .e-img-focus:not(.e-resize),
.e-richtexteditor .e-source-content .e-content .e-img-focus:not(.e-resize) {
  border: solid 2px #007bff;
}

.e-richtexteditor .e-rte-content .e-content a,
.e-richtexteditor .e-source-content .e-content a {
  color: #007bff;
}

.e-richtexteditor.e-rtl .e-rte-content .e-content blockquote {
  border-left: 0;
  border-right: solid 2px #212529;
}

.e-richtexteditor.e-rte-resize {
  background: #fff;
}

.e-richtexteditor .e-input.e-rte-linkurl.e-error,
.e-richtexteditor .e-input.e-img-link.e-error {
  color: #dc3545;
}

.e-richtexteditor.e-rte-full-screen {
  background: #fff;
}

.e-richtexteditor.e-rte-inline .e-rte-content {
  border: 0;
}

.e-richtexteditor:not(.e-rte-toolbar-enabled) {
  border: 1px solid #dee2e6;
}

.e-richtexteditor:not(.e-rte-toolbar-enabled) .e-rte-content {
  border: 0;
  border-bottom: 0;
  border-top: 0;
}

.e-richtexteditor.e-rte-tb-expand {
  border: 1px solid #dee2e6;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar {
  border: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-extended-toolbar,
.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended {
  border: 0;
  border-bottom: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar .e-toolbar-extended.e-popup-open {
  border-bottom: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-expand-open + .e-rte-content {
  border-top: 0;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-rte-tb-float {
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-rte-tb-float.e-expand-open {
  border-bottom: 1px solid #dee2e6;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-toolbar.e-rte-tb-float.e-expand-open .e-toolbar-extended {
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-content,
.e-richtexteditor.e-rte-tb-expand .e-source-content {
  border: 0;
  border-bottom: 1px solid #dee2e6;
  border-top: 1px solid #dee2e6;
}

.e-richtexteditor.e-rte-tb-expand .e-toolbar-extended.e-popup-open {
  border-bottom: 1px solid #dee2e6;
}

.e-richtexteditor.e-rte-tb-expand.e-rte-resize .e-rte-content,
.e-richtexteditor.e-rte-tb-expand.e-rte-resize .e-source-content {
  border-bottom: 0;
}

.e-rte-quick-popup {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.e-rte-quick-popup .e-rte-quick-toolbar {
  background: #fff;
  border: 0;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items {
  background: #fff;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-separator {
  border: 0.5px solid #dee2e6;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn {
  background: #fff;
  border: 1px solid transparent;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:hover {
  background: #6c757d;
  border-color: #545b62;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:focus {
  background: #6c757d;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:active {
  background: #545b62;
  border-color: #4e555b;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-tbar-btn:active .e-icons {
  color: #fff;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn:focus {
  background: #6c757d;
  border: 1px solid #4e555b;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn:focus .e-icons {
  color: #212529;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-items .e-icons {
  color: #212529;
}

.e-rte-quick-popup .e-rte-quick-toolbar .e-toolbar-extended .e-toolbar-item .e-tbar-btn .e-icons {
  color: #212529;
}

.e-rte-quick-popup .e-rte-dropdown-btn {
  background: #fff;
  border-color: #fff;
}

.e-rte-quick-popup .e-rte-dropdown-btn:focus, .e-rte-quick-popup .e-rte-dropdown-btn:hover {
  background: #6c757d;
}

.e-rte-quick-popup .e-rte-dropdown-btn:hover {
  border-color: #545b62;
}

.e-dialog .e-img-uploadwrap .e-droptext {
  border: dashed 2px #adb5bd;
  border-radius: 4px;
  color: #212529;
  display: block;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  height: 185px;
  margin: 0 auto;
  text-align: center;
  width: 300px;
}

.e-dialog.e-device.e-dlg-modal .e-img-uploadwrap .e-droptext {
  margin: 0 auto;
  width: 250px;
}

.e-linkheader {
  color: #212529;
}

.e-rte-img-popup.e-popup {
  background: #fff;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: none;
  color: #212529;
  padding: 10px;
}

.e-rte-content .e-content img:not(.e-resize) {
  z-index: 1000;
}

span.e-rte-imageboxmark {
  background: #007bff;
  border: 1px solid #fff;
  display: block;
  height: 10px;
  position: absolute;
  width: 10px;
  z-index: 1000;
}

.e-mob-rte span.e-rte-imageboxmark {
  background: #fff;
  border: 1px solid #007bff;
  border-radius: 15px;
  height: 20px;
  width: 20px;
}

.e-mob-rte.e-mob-span span.e-rte-imageboxmark {
  background: #007bff;
  border: 1px solid #fff;
}

.e-rte-content .e-content img.e-resize {
  z-index: 1000;
}

*.e-disabled {
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

*.e-disabled .e-rte-toolbar .e-toolbar-item {
  cursor: default;
}

*.e-disabled .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-icon-btn:hover, *.e-disabled .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-icon-btn:active, *.e-disabled .e-rte-toolbar .e-toolbar-item .e-tbar-btn.e-icon-btn:focus,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-btn:hover,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-btn:active,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-dropdown-btn.e-btn:focus,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-colorpicker-wrapper .e-btn:hover,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-colorpicker-wrapper .e-btn:active,
*.e-disabled .e-rte-toolbar .e-toolbar-item .e-colorpicker-wrapper .e-btn:focus {
  background: transparent;
  cursor: default;
}

.e-dropdown-popup ul .e-item.e-active {
  background-color: #007bff;
  color: #fff;
}

.e-popup-overlay {
  background-color: rgba(0, 0, 0, 0.5);
}

.e-rte-toolbar.e-extended-toolbar .e-toolbar-item.e-overlay .e-dropdown-btn {
  color: rgba(108, 117, 125, 0.3);
}

.e-rte-table-popup.e-popup.e-popup-open {
  background-color: #fff;
  color: #212529;
}

.e-rte-table-popup .e-span-border {
  border: 1px solid #dee2e6;
  display: block;
  margin-bottom: 8px;
  margin-top: 12px;
}

.e-rte-table-popup .e-rte-popup-header {
  color: #212529;
  font-family: "Helvetica Neue", "Helvetica", "Arial", sans-serif, "-apple-system", "BlinkMacSystemFont";
  font-size: 14px;
  text-align: center;
}

.e-rte-table-popup.e-popup-open .e-rte-tablecell.e-default {
  background-color: #fff;
}

.e-rte-table-popup.e-popup-open .e-rte-tablecell.e-active {
  background-color: #fff;
  border: 1px solid #007bff;
}

.e-rte-table.e-alternate-border tbody tr:nth-child(2n+0) {
  background-color: #f8f9fa;
}

.e-rte-table th {
  background-color: #e9ecef;
}

.e-rte-table-popup.e-popup.e-popup-open {
  background-color: #fff;
  color: #212529;
}

span.e-table-box {
  background-color: #fff;
  border: 1px solid #adb5bd;
}

span.e-table-box.e-rbox-select {
  background-color: #adb5bd;
  border: 1px solid #adb5bd;
}

.e-table-rhelper {
  background-color: #007bff;
}

.e-rte-dialog-upload.e-upload.e-lib.e-keyboard {
  background: #fff;
}

.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item.e-active .e-tbar-btn .e-icons,
.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:active .e-icons,
.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:focus .e-icons,
.e-rte-inline-popup .e-rte-quick-toolbar.e-rte-toolbar .e-toolbar-items .e-toolbar-item .e-tbar-btn:hover .e-icons {
  color: #fff;
}

.e-richtexteditor.e-rte-tb-expand .e-rte-content-border {
  border-bottom: 0;
}
